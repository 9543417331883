import { useState } from "react";
import useInputHandler from "hooks/useInputHandler";
import RegionSelectBox from "components/RegionSelectBox";
import { url } from "variable/url";
import axios from "axios";
import { useSetRecoilState } from "recoil";
import { messageAtom } from "atoms/atoms";

export default function ContactUs({ currentData, closeModal }) {
  const setMessage = useSetRecoilState(messageAtom);
  const [regionData, setRegionData] = useState();
  const [dataForm, , attributes] = useInputHandler({
    academy_name: "",
    name: "",
    dataForm: "",
    email: "",
  });
  const [checkboxState, setCheckboxState] = useState({
    question1: true,
    question2: false,
    question3: [],
  });

  const submitData = async () => {
    const needs = checkboxState.question3.map(
      (el, idx) => idx + 1 + ". " + el + "\n"
    );
    const desc = `${
      checkboxState.question1
        ? "학원을 운영하고 있어요."
        : "학원 운영을 준비중이에요."
    }\n학원명 : ${dataForm.academy_name}\n학원 위치 : ${Object.values(
      regionData
    ).join(" ")}\n이름 : ${dataForm.name}\n연락처 : ${
      dataForm.customer_ph
    }\n이메일 주소 : ${
      dataForm.email
    }\n어떤 기능을 필요로 하고 계신가요? (중복가능)\n${needs.join(" ")}`;

    const body = {
      desc,
      is_agree: checkboxState.question2,
    };
    try {
      await axios.post(url.server + "academy/inquiry", body);
      setMessage({ text: "문의가 접수되었습니다.", confirm: closeModal });
    } catch (err) {
      setMessage("잠시 후 다시 시도해주세요.");
    }
  };

  const scrollEvent = (e) => {
    let currentElement = e.target;
    while (currentElement) {
      if (currentElement.classList.contains("scroll_target")) {
        return currentElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
      currentElement = currentElement.parentElement;
    }
    return null;
  };

  return (
    <aside id="contactUs" className="modal dim">
      <div className="modal_content_wrap">
        <header>
          <h2>문의하기</h2>
          <div className="btn_group">
            {currentData.mode !== "page" && (
              <button className="close btn_default" onClick={closeModal}>
                닫기
              </button>
            )}
          </div>
        </header>

        <section className="contact_wrap">
          <h3>
            에듀빌리 도입이 고민되시나요?
            <br />
            <span>
              간단한 설문에 응답해주시면 친절한 상담을 도와드리겠습니다.
            </span>
          </h3>

          <div className="question_wrap">
            <section className="question_item ">
              <dl>
                <dd>
                  <input
                    type="checkbox"
                    id="inOeration"
                    onChange={(e) => {
                      if (e.target.checked)
                        setCheckboxState({ ...checkboxState, question1: true });
                    }}
                    checked={checkboxState.question1}
                  />
                  <label htmlFor="inOeration">학원을 운영하고 있어요.</label>
                </dd>
                <dd>
                  <input
                    type="checkbox"
                    id="preparing"
                    onChange={(e) => {
                      if (e.target.checked)
                        setCheckboxState({
                          ...checkboxState,
                          question1: false,
                        });
                    }}
                    checked={!checkboxState.question1}
                  />
                  <label htmlFor="preparing">학원 운영을 준비중이에요.</label>
                </dd>
              </dl>
            </section>

            <section className="question_item">
              <dl className="scroll_target">
                <dt>학원명</dt>
                <dd>
                  <input
                    type="text"
                    placeholder="현재 학원 운영중이신 분만 기재해주세요."
                    {...attributes("academy_name")}
                    onClick={scrollEvent}
                  />
                </dd>
              </dl>
            </section>

            <section className="question_item">
              <dl className="scroll_target">
                <dt>학원위치</dt>
                <dd className="academy_location" onClick={scrollEvent}>
                  {/* <input type="text" /> */}
                  <RegionSelectBox setRegionData={setRegionData} />
                </dd>
              </dl>
            </section>

            <section className="question_item">
              <dl>
                <dt>신청자정보</dt>
                <dd className="scroll_target">
                  <dl>
                    <dt>이름</dt>
                    <dd>
                      <input
                        type="text"
                        {...attributes("name")}
                        onClick={scrollEvent}
                      />
                    </dd>
                  </dl>
                </dd>

                <dd className="scroll_target">
                  <dl>
                    <dt>핸드폰 번호</dt>
                    <dd>
                      <input
                        type="text"
                        {...attributes("customer_ph")}
                        data-format="number"
                        onClick={scrollEvent}
                      />
                    </dd>
                  </dl>
                </dd>

                <dd className="scroll_target">
                  <dl>
                    <dt>이메일 주소</dt>
                    <dd>
                      <input
                        type="text"
                        {...attributes("email")}
                        onClick={scrollEvent}
                      />
                    </dd>
                  </dl>
                </dd>

                <dd className="agree">
                  <input
                    type="checkbox"
                    id="agreement"
                    onChange={(e) => {
                      if (e.target.checked)
                        setCheckboxState({ ...checkboxState, question2: true });
                      else
                        setCheckboxState({
                          ...checkboxState,
                          question2: false,
                        });
                    }}
                    checked={checkboxState.question2}
                  />
                  <label htmlFor="agreement">
                    <strong>(필수)</strong> 개인정보처리방침 동의
                  </label>

                  <a
                    target="blank"
                    href="https://www.butterflying.kr/term.html#privacy"
                  >
                    <strong>내용보기</strong>
                  </a>
                </dd>
              </dl>
            </section>

            <section className="question_item">
              <dl>
                <dt>어떤 기능을 필요로 하고 계신가요? (중복가능)</dt>
                <dd className="check_list">
                  <ul>
                    {[
                      "소속 직원 관리 (재직자 및 퇴직자 관리 & 급여관리)",
                      "수업만족도평가",
                      "다가오는 수업 (스케줄관리)",
                      "알림공지 (숙제, 알림, 준비물 관리)",
                      "출결 및 학습현황",
                      "셔틀버스 현황",
                      "기타 ",
                    ].map((el, idx) => {
                      return (
                        <li key={idx}>
                          <input
                            type="checkbox"
                            id={"question3_" + idx}
                            onChange={(e) => {
                              if (checkboxState.question3.includes(el)) {
                                setCheckboxState({
                                  ...checkboxState,
                                  question3: checkboxState.question3.filter(
                                    (el2) => el2 !== el
                                  ),
                                });
                              } else {
                                setCheckboxState({
                                  ...checkboxState,
                                  question3: [...checkboxState.question3, el],
                                });
                              }
                            }}
                          />
                          <label htmlFor={"question3_" + idx}> {el}</label>
                        </li>
                      );
                    })}
                  </ul>
                </dd>
              </dl>
            </section>

            <p className="desc">
              작성해주신 메일주소로 1~2일 이내에
              <br className="mobile" /> 제안서 보내드리겠습니다.
              <br className="desktop" />
              감사합니다:)
            </p>
          </div>
        </section>

        <div className="btn_group center mt_32">
          {/* <button></button> */}
          <button
            className="btn_default half"
            onClick={submitData}
            disabled={!checkboxState.question2}
          >
            문의하기
          </button>
        </div>
        {/* )} */}
      </div>
    </aside>
  );
}
