import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useModalHandler from "hooks/useModalHandler";
import useDatePicker from "hooks/useDatePicker";
import useFindPostAddr from "hooks/useFindPostAddr";
import useInputHandler from "hooks/useInputHandler";
import useRequestAPI from "hooks/useRequestAPI";
import useValidation from "hooks/useValidation";
import ReturnToList from "components/ReturnToList";
import formatDate from "utils/formatDate";
import adjustTime from "utils/adjustTime";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

export default function AcademyShuttleEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const { ModalComponent, openModal, sendModalData, currentModal } =
    useModalHandler();
  const { DatePickerModule, startDate, endDate, setEndDate } = useDatePicker({
    startDate: new Date(state.resData.bus.start_date),
    endDate: new Date(state.resData.bus.end_date),
  });

  const { DaumPost, userAddr, openDaumPost, position } = useFindPostAddr(true);
  const [dataForm, setDataForm, attributes] = useInputHandler({
    ...state.resData.bus,
    departure_time: state.resData.bus.departure_time.slice(0, -3),
    arrival_time: state.resData.bus.arrival_time.slice(0, -3),
    bus_stop_list: sortStation(state.resData.bus_stop_list),
    pic: state.resData.bus.manager_id.toString(),
    bus_driver_id: "0", //임시로 0, 백엔드에서 필요한 값이라 보냄
  });

  const { form, setForm, validPass, errorCheck, validAttributes } =
    useValidation();
  const [stationDataForm, setStationDataForm, stationAttributes] =
    useInputHandler({
      station: "",
      address: "",
      location: "",
      boarding_time: "",
    });
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();
  const [deleteStationList, setDeleteStationList] = useState([]);
  const [isDupTeacher, setIsDupTeacher] = useState(false);

  const boardingTimeCheck = (target) => {
    return (
      target.boarding_time.replace(":", "") >
        dataForm.arrival_time.replace(":", "") ||
      target.boarding_time.replace(":", "") <
        dataForm.departure_time.replace(":", "")
    );
  };

  const submitData = async () => {
    let { id, manager_id, memo, ...etc } = dataForm;
    etc = {
      ...etc,
      bus_no: form.bus_no.val,
      driver_name: form.mb_name.val,
      driver_phone_number: form.mb_ph.val,
    };
    if (dataForm.schedule_type === "0") {
      delete etc.start_date;
      delete etc.end_date;
    } else {
      etc.start_date = formatDate(startDate, "-");
      etc.end_date = formatDate(endDate, "-");
    }

    if (
      !Object.values(etc).every((el) => {
        return el[0];
      })
    ) {
      return setMessage(alertMsg.all_fields);
    }
    if (!validPass(["mb_name", "mb_ph", "bus_no"])) {
      return setMessage("입력값의 형식을 다시 확인해주세요.");
    }
    if (
      etc.bus_stop_list.some((el) => {
        return boardingTimeCheck(el);
      })
    ) {
      return setMessage(
        "정류장 탑승시간은 출발시간과 도착시간을 벗어날 수 없습니다."
      );
    }
    if (isDupTeacher) {
      return setMessage(
        "담당 선생님 일정이 중복되었습니다.\n 다른 선생님으로 다시 선택해주세요."
      );
    }

    const res = await requestAPI("patch", "academy/shuttle-bus/update", {
      ...etc,
      bus_id: id,
      pic: manager_id,
      direction: dataForm.direction,
      memo,
      bus_stop_delete_list: deleteStationList,
    });
    if (!res) return;
    setMessage({
      text: alertMsg.change,
      confirm: () => navigate("/academy/shuttle/1"),
    });
  };

  function sortStation(arr) {
    arr.sort((a, b) => {
      a = a.boarding_time.slice(0, 5).replace(":", "");
      b = b.boarding_time.slice(0, 5).replace(":", "");
      return a - b;
    });
    return arr.map((el, index) => {
      return { ...el, index };
    });
  }

  const addStation = async () => {
    if (!Object.values(stationDataForm).every((el) => el))
      return setMessage(alertMsg.all_fields);
    if (
      stationDataForm.boarding_time.replace(":", "") >
      dataForm.arrival_time.replace(":", "")
    ) {
      return setMessage("탑승시간은 도착시간을 초과할 수 없습니다.");
    }
    if (
      stationDataForm.boarding_time.replace(":", "") <
      dataForm.departure_time.replace(":", "")
    ) {
      return setMessage("탑승시간은 출발시간 이전일 수 없습니다.");
    }
    let copy = [...dataForm.bus_stop_list];
    const { latitude, longitude } = position;
    copy.push({ ...stationDataForm, latitude, longitude });
    copy = sortStation(copy);
    setDataForm({ ...dataForm, bus_stop_list: copy });
    setStationDataForm({
      station: "",
      address: "",
      location: "",
      boarding_time: "",
    });
  };

  const applyOneDay = (e) => {
    if (e.target.checked) {
      setEndDate(new Date(startDate));
    }
  };

  const deleteData = () => {
    if (dataForm.bus_stop_list[0])
      return setMessage("연결된 정류장을 모두 삭제 후 다시 진행해 주세요.");
    setMessage({
      type: "confirm",
      text: "삭제하시겠습니까?",
      confirm: async () => {
        const res = await requestAPI("delete", "academy/shuttle-bus/delete", {
          bus_id: id,
          bus_stop_delete_list: deleteStationList,
        });
        if (!res) return;
        setMessage({
          text: alertMsg.delete,
          confirm: () => navigate("/academy/shuttle/1"),
        });
      },
    });
  };

  const deleteStationData = (idx) => {
    let copy = { ...dataForm };
    copy.bus_stop_list.splice(idx, 1);
    copy.bus_stop_list = copy.bus_stop_list.map((el, index) => {
      return { ...el, index };
    });
    setDataForm(copy);
  };

  const teacherAvailability = async (timeData, mna_manager_id) => {
    const dayFormat = ["월", "화", "수", "목", "금", "토", "일"];
    timeData = timeData.map((el) => {
      let days = el.week.split(",");
      days = days.map((el) => dayFormat[el]);
      el.week = days.join();
      return el;
    });
    const res = await requestAPI("post", "academy/class/manager", {
      class_room_time: timeData.filter((el) => !el?.existing_data),
      mna_manager_id,
    });
    if (!res) return;
    if (res.data.data.class_time.is_valid) setIsDupTeacher(true);
    else setIsDupTeacher(false);
  };

  const checkDupTeachers = () => {
    let { operation_date, departure_time, arrival_time } = state.resData.bus;

    departure_time = departure_time.slice(0, -3);
    arrival_time = arrival_time.slice(0, -3);

    let class_room_time = [
      {
        week: dataForm.operation_date.join(","),
        start_time: dataForm.departure_time,
        end_time: dataForm.arrival_time,
        existing_data: true,
      },
    ];

    let existingTeacherTime = {
      ...class_room_time[0],
      existing_data: false,
      single_check: true,
    };

    const isAllIncludesDays = dataForm.operation_date.every(
      //현재 선택된 요일이 기존 요일에 모두 포함되는지의 여부
      (el) => operation_date.includes(el)
    )
      ? true
      : false;

    const includesDays = dataForm.operation_date.filter(
      //기존 요일에 포함되는 현재 선택된 요일
      (el) => {
        return operation_date.includes(el);
      }
    );

    const notIncludesDays = dataForm.operation_date.filter(
      //기존 요일에 포함되지 않는 현재 선택된 요일
      (el) => {
        return !operation_date.includes(el);
      }
    );

    if (notIncludesDays[0]) {
      //기존 요일에 포함되지 않는 현재 선택 요일이 1개 이상이라면
      class_room_time.push({
        ...existingTeacherTime,
        week: notIncludesDays.join(","),
      });
    }

    if (
      isAllIncludesDays &&
      //현재 선택된 요일이 기존 요일에 모두 포함되고
      dataForm.departure_time >= departure_time &&
      dataForm.arrival_time <= arrival_time
    ) {
      class_room_time.existing_data = true;
    } else if (
      includesDays[0] &&
      dataForm.departure_time < departure_time &&
      dataForm.arrival_time >= departure_time &&
      dataForm.arrival_time <= arrival_time
    ) {
      existingTeacherTime.week = includesDays.join(",");
      existingTeacherTime.end_time = adjustTime(departure_time, "-");
      class_room_time.push(existingTeacherTime);
    } else if (
      includesDays[0] &&
      dataForm.departure_time >= departure_time &&
      dataForm.departure_time <= arrival_time &&
      dataForm.arrival_time > arrival_time
    ) {
      existingTeacherTime.week = includesDays.join(",");
      existingTeacherTime.start_time = adjustTime(arrival_time, "+");
      class_room_time.push(existingTeacherTime);
    } else if (
      includesDays[0] &&
      dataForm.departure_time < departure_time &&
      dataForm.arrival_time > arrival_time
    ) {
      const times = [
        {
          week: includesDays.join(","),
          start_time: dataForm.departure_time,
          end_time: adjustTime(departure_time, "-"),
          single_check: true,
        },
        {
          week: includesDays.join(","),
          start_time: adjustTime(arrival_time, "+"),
          end_time: dataForm.arrival_time,
          single_check: true,
        },
      ];
      times.forEach((el) => class_room_time.push(el));
    } else if (
      includesDays[0] &&
      dataForm.departure_time !== departure_time &&
      dataForm.arrival_time !== arrival_time
    ) {
      existingTeacherTime.week = includesDays.join(",");
      class_room_time.push(existingTeacherTime);
    }
    return class_room_time;
  };

  useEffect(() => {
    const { bus_no, driver_name, driver_phone_number } = state.resData.bus;
    setForm({
      ...form,
      bus_no: { val: bus_no, isValid: true },
      mb_name: { val: driver_name, isValid: true },
      mb_ph: { val: driver_phone_number, isValid: true },
    });
  }, []);

  useEffect(() => {
    setStationDataForm({
      ...stationDataForm,
      address: userAddr.addr,
    });
  }, [userAddr]);

  useEffect(() => {
    switch (currentModal.type) {
      case "currentStudent": {
        deleteStationData(sendModalData.idx);
        return setDeleteStationList([
          ...deleteStationList,
          sendModalData.bus_stop_id,
        ]);
      }
      case "selectTeacher": {
        return setDataForm({
          ...dataForm,
          manager_name: sendModalData.name,
          pic: sendModalData.id.toString(),
        });
      }
      default: {
        return;
      }
    }
  }, [sendModalData]);

  useEffect(() => {
    teacherAvailability(checkDupTeachers(), dataForm.pic);
  }, [
    dataForm.departure_time,
    dataForm.arrival_time,
    dataForm.operation_date,
    dataForm.pic,
  ]);

  return (
    <div className="inner shuttle_add">
      <Loading />
      <header>
        <h2>
          셔틀버스 수정({{ 0: "정기", 1: "임시" }[dataForm.schedule_type]})
        </h2>
        <div className="btn_group">
          <ReturnToList />
        </div>
      </header>
      <section className="content_wrap">
        <table className="detail desktop">
          <tr>
            <th>버스이름</th>
            <td colSpan={2}>
              <div className="cell_content_wrap">
                <input type="text" {...attributes("bus_name")} />
              </div>
            </td>
            <th>차량번호</th>
            <td>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  className={errorCheck("bus_no")?.state}
                  {...validAttributes("bus_no")}
                />
              </div>
            </td>
          </tr>
          <tr>
            <th>운전자명</th>
            <td colSpan={2}>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  className={errorCheck("mb_name")?.state}
                  {...validAttributes("mb_name")}
                />
              </div>
            </td>
            <th>
              운전자
              <br />
              전화번호
            </th>
            <td>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  className={errorCheck("mb_ph")?.state}
                  {...validAttributes("mb_ph")}
                  data-format="number"
                />
              </div>
            </td>
          </tr>
          <tr>
            <th rowSpan={3}>운행정보</th>
            <td width={"120px"}>종류</td>
            <td className="shuttle_type">
              <div className="cell_content_wrap">
                {["등원", "하원"][state.resData.bus.direction]}
              </div>
            </td>
            <th rowSpan={3}>운행요일</th>
            <td rowSpan={3}>
              <div className="cell_content_wrap">
                <ul className="operating_days">
                  {["월", "화", "수", "목", "금", "토", "일"].map((el, idx) => (
                    <li key={idx}>
                      <input
                        type="checkbox"
                        className="mr_4"
                        id={"day_" + idx}
                        {...attributes("operation_date", idx)}
                        checked={dataForm.operation_date.includes(
                          idx.toString()
                        )}
                        data-existing-data={state.resData.bus.operation_date.includes(
                          idx.toString()
                        )}
                      />
                      <label htmlFor={"day_" + idx}>{el}</label>
                    </li>
                  ))}
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td>출발 시간</td>
            <td className="start_time">
              <div className="cell_content_wrap">
                <input type="time" {...attributes("departure_time")} />
              </div>
            </td>
          </tr>
          <tr>
            <td>도착 시간</td>
            <td className="start_time">
              <div className="cell_content_wrap">
                <input type="time" {...attributes("arrival_time")} />
              </div>
            </td>
          </tr>
          <tr>
            <th>정류장 정보</th>
            <td colSpan={4} className="station_list">
              <div className="cell_content_wrap">
                <table className="detail">
                  <colgroup>
                    <col width="auto" />
                    <col width="30%" />
                    <col width="auto" />
                    <col width="auto" />
                    <col width="auto" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>명칭</th>
                      <th>주소 (클릭하여 조회)</th>
                      <th>상세위치</th>
                      <th>탑승시간</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="shuttle_option">
                      <td>
                        <input
                          type="text"
                          value={stationDataForm.name}
                          {...stationAttributes("station")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="장소선택"
                          value={stationDataForm.address}
                          onClick={openDaumPost}
                          readOnly
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={stationDataForm.detailAddr}
                          {...stationAttributes("location")}
                        />
                      </td>
                      <td>
                        <input
                          type="time"
                          value={stationDataForm.time}
                          {...stationAttributes("boarding_time")}
                        />
                      </td>
                      <td>
                        <div className="btn_group">
                          <button
                            className="btn_default slim green3"
                            onClick={addStation}
                          >
                            추가
                          </button>
                        </div>
                      </td>
                    </tr>
                    {dataForm?.bus_stop_list.map((el, idx) => (
                      <tr className="station_item" key={idx}>
                        <td className="hide_text">{el.station}</td>
                        <td className="hide_text">{el.address}</td>
                        <td className="hide_text">{el.location}</td>
                        <td>
                          <input
                            className={boardingTimeCheck(el) ? "wrong" : ""}
                            type="time"
                            value={el.boarding_time}
                            onChange={(e) => {
                              if (
                                e.target.value.replace(":", "") >
                                dataForm.arrival_time.replace(":", "")
                              ) {
                                return setMessage(
                                  "도착시간을 초과할 수 없습니다."
                                );
                              }
                              if (
                                e.target.value.replace(":", "") <
                                dataForm.departure_time.replace(":", "")
                              ) {
                                return setMessage(
                                  "출발시간보다 이전일 수 없습니다."
                                );
                              }
                              let copy = { ...dataForm };
                              copy.bus_stop_list[idx].boarding_time =
                                e.target.value;
                              copy.bus_stop_list = sortStation(
                                copy.bus_stop_list
                              );
                              setDataForm(copy);
                            }}
                          />
                        </td>
                        <td>
                          <div className="btn_group">
                            <button
                              className="btn_default slim del"
                              data-id={el.id}
                              data-idx={idx}
                              onClick={() => {
                                if (!el.id) return deleteStationData(idx);
                                openModal("currentStudent", "edit", {
                                  bus_id: id,
                                  bus_stop_id: el.id,
                                  idx,
                                });
                              }}
                            >
                              삭제
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <th>담당 선생님</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                {dataForm.teacher}
                <input
                  className={"teacher" + (isDupTeacher ? " error" : "")}
                  type="text"
                  value={
                    dataForm?.manager_name + (isDupTeacher ? " (시간중복)" : "")
                  }
                  readOnly
                />
                <div className="btn_group short">
                  <button
                    className="btn_default slim green3"
                    onClick={() => {
                      openModal("selectTeacher", "edit", {
                        mna_manager_id: state.resData.bus.manager_id,
                        class_room_time: checkDupTeachers(),
                      });
                    }}
                    disabled={!dataForm.operation_date[0]}
                  >
                    선택
                  </button>
                </div>
              </div>
            </td>
          </tr>
          {dataForm.schedule_type === "1" && (
            <tr>
              <th>적용일정</th>
              <td colSpan={4}>
                <div className="cell_content_wrap">
                  <DatePickerModule type="start" />~
                  <DatePickerModule type="end" />
                  <label htmlFor="">
                    <input
                      type="checkbox"
                      onClick={applyOneDay}
                      checked={startDate.getTime() === endDate.getTime()}
                    />{" "}
                    하루적용
                  </label>
                </div>
              </td>
            </tr>
          )}

          <tr>
            <th>메모</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                <textarea {...attributes("memo")}></textarea>
              </div>
            </td>
          </tr>
        </table>

        <table className="detail fixed mobile">
          <tr>
            <th>버스이름</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                <input type="text" {...attributes("bus_name")} />
              </div>
            </td>
          </tr>

          <tr>
            <th>차량번호</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  className={errorCheck("bus_no")?.state}
                  {...validAttributes("bus_no")}
                />
              </div>
            </td>
          </tr>

          <tr>
            <th>운전자명</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  className={errorCheck("mb_name")?.state}
                  {...validAttributes("mb_name")}
                />
              </div>
            </td>
          </tr>

          <tr>
            <th>
              운전자
              <br />
              전화번호
            </th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  className={errorCheck("mb_ph")?.state}
                  {...validAttributes("mb_ph")}
                  data-format="number"
                />
              </div>
            </td>
          </tr>

          <tr>
            <th rowSpan={3}>운행정보</th>
            <td>종류</td>
            <td colSpan={3} className="shuttle_type">
              <div className="cell_content_wrap">
                {["등원", "하원"][state.resData.bus.direction]}
              </div>
            </td>
          </tr>

          <tr>
            <td>
              출발
              <br />
              시간
            </td>
            <td colSpan={3} className="start_time">
              <div className="cell_content_wrap">
                <input type="time" {...attributes("departure_time")} />
              </div>
            </td>
          </tr>

          <tr>
            <td>
              도착
              <br />
              시간
            </td>
            <td colSpan={3} className="start_time">
              <div className="cell_content_wrap">
                <input type="time" {...attributes("arrival_time")} />
              </div>
            </td>
          </tr>

          <tr>
            <th>운행요일</th>
            <td colSpan={4}>
              <div className="cell_content_wrap pt_12 pb_12">
                <ul className="operating_days">
                  {["월", "화", "수", "목", "금", "토", "일"].map((el, idx) => (
                    <li key={idx}>
                      <input
                        type="checkbox"
                        className="mr_4"
                        id={"day_" + idx}
                        {...attributes("operation_date", idx)}
                        checked={dataForm.operation_date.includes(
                          idx.toString()
                        )}
                        data-existing-data={state.resData.bus.operation_date.includes(
                          idx.toString()
                        )}
                      />
                      <label htmlFor={"day_" + idx}>{el}</label>
                    </li>
                  ))}
                </ul>
              </div>
            </td>
          </tr>

          <tr>
            <td colSpan={5} className="station_list" style={{ border: "none" }}>
              <div className="flex jc_sp_bw ai_st">
                <Swiper
                  className="mySwiper"
                  pagination={true}
                  modules={[Pagination]}
                >
                  {dataForm?.bus_stop_list.map((el, idx) => (
                    <SwiperSlide>
                      <div key={idx} className="table_wrap">
                        <table className={"detail "}>
                          <colgroup>
                            <col width={"25%"} />
                            <col width={""} />
                          </colgroup>
                          <tr>
                            <th colSpan={2} className="title">
                              <div className="flex jc_sp_bw">
                                <strong>정류장 정보</strong>
                                <div className="btn_group short">
                                  <button
                                    className="btn_default del slim"
                                    data-id={el.id}
                                    data-idx={idx}
                                    onClick={() => {
                                      if (!el.id) return deleteStationData(idx);
                                      openModal("currentStudent", "edit", {
                                        bus_id: id,
                                        bus_stop_id: el.id,
                                        idx,
                                      });
                                    }}
                                  >
                                    삭제
                                  </button>
                                </div>
                              </div>
                            </th>
                          </tr>
                          <tr>
                            <th>명칭</th>
                            <td>{el.station}</td>
                          </tr>

                          <tr>
                            <th>주소</th>
                            <td>{el.address}</td>
                          </tr>

                          <tr>
                            <th>상세위치</th>
                            <td>{el.location}</td>
                          </tr>

                          <tr>
                            <th>탑승시간</th>
                            <td>{el.boarding_time}</td>
                          </tr>
                        </table>
                      </div>
                    </SwiperSlide>
                  ))}
                  <SwiperSlide>
                    <div className="table_wrap">
                      <table className={"detail"}>
                        <colgroup>
                          <col width={"25%"} />
                          <col width={""} />
                        </colgroup>

                        <tr>
                          <th colSpan={2} className="title">
                            <div className="flex jc_sp_bw">
                              <strong>정류장 정보</strong>
                              <div className="btn_group short">
                                <button
                                  className="btn_default slim"
                                  onClick={addStation}
                                >
                                  추가
                                </button>
                              </div>
                            </div>
                          </th>
                        </tr>

                        <tr>
                          <th>명칭</th>
                          <td>
                            <input
                              type="text"
                              value={stationDataForm.name}
                              {...stationAttributes("station")}
                            />
                          </td>
                        </tr>

                        <tr>
                          <th>주소</th>
                          <td>
                            <input
                              className="pointer"
                              type="text"
                              placeholder="클릭하여 조회"
                              value={stationDataForm.address}
                              onClick={openDaumPost}
                              readOnly
                            />
                          </td>
                        </tr>

                        <tr>
                          <th>상세위치</th>
                          <td>
                            <input
                              type="text"
                              value={stationDataForm.detailAddr}
                              {...stationAttributes("location")}
                            />
                          </td>
                        </tr>

                        <tr>
                          <th>탑승시간</th>
                          <td>
                            <input
                              type="time"
                              value={stationDataForm.time}
                              {...stationAttributes("boarding_time")}
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </td>
          </tr>

          <tr>
            <th>담당 선생님</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                {dataForm.teacher}
                <input
                  className={"teacher" + (isDupTeacher ? " error" : "")}
                  type="text"
                  value={
                    dataForm?.manager_name + (isDupTeacher ? " (시간중복)" : "")
                  }
                  readOnly
                />
                <div className="btn_group">
                  <button
                    className="btn_default slim green3"
                    onClick={() => {
                      openModal("selectTeacher", "edit", {
                        mna_manager_id: state.resData.bus.manager_id,
                        class_room_time: checkDupTeachers(),
                      });
                    }}
                    disabled={!dataForm.operation_date[0]}
                  >
                    선택
                  </button>
                </div>
              </div>
            </td>
          </tr>

          {dataForm.schedule_type === "1" && (
            <tr>
              <th>적용일정</th>
              <td colSpan={4}>
                <div className="cell_content_wrap">
                  <DatePickerModule type="start" />~
                  <DatePickerModule type="end" />
                  <label htmlFor="">
                    <input
                      type="checkbox"
                      onClick={applyOneDay}
                      checked={startDate.getTime() === endDate.getTime()}
                    />{" "}
                    하루적용
                  </label>
                </div>
              </td>
            </tr>
          )}

          <tr>
            <th>메모</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                <textarea {...attributes("memo")}></textarea>
              </div>
            </td>
          </tr>
        </table>

        <div className="btn_group">
          <button className="btn_default half del" onClick={deleteData}>
            삭제
          </button>
          <button className="btn_default half" onClick={submitData}>
            수정
          </button>
        </div>
      </section>
      <ModalComponent />
      <DaumPost />
    </div>
  );
}
