import React from "react";
import "../../node_modules/react-vis/dist/style.css";
import { RadialChart } from "react-vis";

export default function PieGraph({ data }) {
  let myData = data.map((el) => {
    return {
      angle: el || 0,
    };
  });
  myData = myData.filter((el) => el.angle);

  const colors = ["#B7B7B7", "#FFAC4B", "#19C791"];
  const colorData = [];
  data.forEach((el, idx) => {
    const reverse = colors.reverse();
    colorData.unshift(reverse[idx]);
  });

  return (
    <div>
      <RadialChart
        className={"mobile"}
        height={240}
        width={240}
        data={myData}
        colorRange={colorData}
        colorDomain={colorData}
        style={{ strokeWidth: 3, stroke: "white" }}
      />
      <RadialChart
        className={"desktop"}
        height={322}
        width={322}
        data={myData}
        colorRange={colorData}
        colorDomain={colorData}
        style={{ strokeWidth: 3, stroke: "white" }}
      />
    </div>
  );
}
