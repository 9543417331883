import { useEffect, useState } from "react";
import useRequestAPI from "hooks/useRequestAPI";
import Pagination from "components/Pagination";

export default function SelectShuttle({ closeModal, changeData, currentData }) {
  const { requestAPI } = useRequestAPI();
  const [currPage, setCurrPage] = useState(0);
  const [resData, setResData] = useState();

  const getData = async () => {
    const res = await requestAPI("post", "academy/shuttle-bus/select", {
      page: currPage,
      size: 5,
    });
    if (!res) return;
    setResData(res.data.data.shuttle_bus_list);
  };

  useEffect(() => {
    getData();
  }, [currPage]);

  return (
    <aside id="selectShuttle" className="modal dim select">
      <div className="modal_content_wrap">
        <header>
          <h2>셔틀버스 선택</h2>
          <div className="btn_group">
            <button className="close btn_default" onClick={closeModal}>
              닫기
            </button>
          </div>
        </header>

        {/* <section className="mt_52"> */}
        <Pagination
          currPage={currPage}
          setCurrPage={setCurrPage}
          lastPage={resData?.totalPages || 0}
          changeURL={false}
        />

        <section className="table_scroll_wrap">
          <table className="detail">
            <thead>
              <tr>
                <th>셔틀버스</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              {resData?.content.map((el, idx) => (
                <tr key={idx}>
                  <td>{el.bus_name}</td>
                  <td>
                    <div className="btn_group">
                      <button
                        className="btn_default slim small green3"
                        onClick={() => {
                          changeData(el);
                        }}
                      >
                        선택
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        {/* </section> */}
      </div>
    </aside>
  );
}
