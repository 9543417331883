import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "components/Pagination";
import useSelectBox from "hooks/useSelectBox";
import useModalHandler from "hooks/useModalHandler";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import useRequestAPI from "hooks/useRequestAPI";
import permissionCheck from "utils/permissionCheck";
import formatExcelTitle from "utils/formatExcelTitle";

export default function AcademySchedule() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { ModalComponent } = useModalHandler();
  const [SelectBox, selectValue] = useSelectBox([
    { name: "전체", code: "all" },
    { name: "타입", code: "class_type" },
    { name: "과목", code: "class_field" },
    { name: "수업명", code: "class_name" },
    { name: "담당", code: "teacher_name" },
    { name: "운영여부", code: "is_opening" },
  ]);
  const [SelectBoxIsOpen, selectValueIsOpen] = useSelectBox([
    { name: "운영", code: true },
    { name: "미운영", code: false },
  ]);
  const [McBoardContent, selectMcBoardContent] = useSelectBox([
    { name: "시간", code: "class_time" },
    { name: "수업일", code: "class_week" },
    { name: "타입", code: "class_type" },
    { name: "과목", code: "class_field" },
    { name: "담당", code: "teacher_name" },
    { name: "강의실", code: "room" },
    { name: "수강료(원)", code: "class_price" },
    { name: "학생 수", code: "student_cnt" },
  ]);

  const listDataFormat = (type, data) => {
    switch (type) {
      case "class_week": {
        return data.class_time.map((el, idx) => {
          if (data.class_type === "일반") {
            return (
              <>
                매주 {sortDay(el.week)}
                <br key={idx} />
              </>
            );
          } else {
            return (
              <>
                {el.week.split(",").map((el2, idx) => {
                  return (
                    <>
                      {el2}
                      <br key={idx} />
                    </>
                  );
                })}
              </>
            );
          }
        });
      }
      case "class_time": {
        return data.class_time.map((el, idx) => {
          return (
            <>
              {el.start_time.slice(0, -3)}~{el.end_time.slice(0, -3)}
              <br key={idx} />
            </>
          );
        });
      }
      case "room": {
        return data.class_time.map((el2, idx) => {
          return (
            <>
              {el2.room}
              <br key={idx} />
            </>
          );
        });
      }
      case "class_price": {
        return data.class_price.toLocaleString("ko-KR");
      }
      case "student_cnt": {
        return data.student_cnt + "/" + data.class_capacity;
      }
      default: {
        return data[type];
      }
    }
  };

  const [searchKeyword, setSerchKeword] = useState("");
  const [currPage, setCurrPage] = useState(id - 1);
  const { requestAPI, Loading } = useRequestAPI();
  const [resData, setResData] = useState();
  const [currSearchData, setCurrSearchData] = useState({
    page: currPage,
    size: 5,
    search_type: "all",
    search_keyword: "",
  });

  const getData = async (e) => {
    e?.preventDefault();
    let body = { ...currSearchData, page: currPage };
    if (e?.target.tagName === "BUTTON") {
      body = {
        page: currPage,
        size: 5,
        search_type: selectValue?.code,
        search_keyword:
          selectValue.name === "운영여부"
            ? selectValueIsOpen.code
            : searchKeyword,
      };
      setCurrSearchData(body);
      return setCurrPage(0);
    }
    const res = await requestAPI("post", "academy/classes", body);
    if (!res) return;
    let content = res.data.data.classes.content;
    content = content.map((el) => ({
      ...el,
      class_time: JSON.parse(el.class_time),
    }));
    res.data.data.classes.content = content;
    setResData(res.data.data);
  };

  const sortDay = (days) => {
    const dayList = ["월", "화", "수", "목", "금", "토", "일"];
    days = days.split(",");
    days.sort((a, b) => {
      return dayList.indexOf(a) - dayList.indexOf(b);
    });
    return days.join(",");
  };

  useEffect(() => {
    getData();
  }, [currPage, currSearchData]);

  return (
    <div className="inner">
      <Loading />
      <section className="page_handling_wrap">
        <form className="search_wrap">
          <SelectBox />
          {selectValue.name === "운영여부" ? (
            <SelectBoxIsOpen className="medium" />
          ) : (
            <input
              type="text"
              onChange={(e) => {
                setSerchKeword(e.target.value);
              }}
            />
          )}

          <div className="btn_group">
            <button
              className="btn_default search"
              onClick={getData}
              style={selectValue.name === "운영여부" ? { display: "flex" } : {}}
            >
              검색
            </button>
          </div>
        </form>
        <Pagination
          currPage={currPage}
          setCurrPage={setCurrPage}
          lastPage={resData?.classes.totalPages || 0}
        />
      </section>

      <section className="board_wrap">
        <header>
          <h2>시간표 조회</h2>
          <div className="btn_group">
            <ReactHTMLTableToExcel
              className="btn_default excel"
              table="table"
              filename={formatExcelTitle("시간표목록")}
              sheet="tableSheet"
              buttonText="엑셀다운로드"
            />
            {permissionCheck(1) && (
              <button
                className="btn_default add"
                onClick={() => {
                  navigate("/academy/schedule/type");
                }}
              >
                시간표 등록
              </button>
            )}
          </div>
        </header>

        <table id="table">
          <colgroup className="mobile">
            <col width={"30%"} />
            <col width={"auto"} />
            <col width={"30%"} />
          </colgroup>
          <colgroup className="desktop">
            <col width={"5%"} />
            <col width={"5%"} />
            <col width={"5%"} />
            <col width={"15%"} />
            <col width={"5%"} />
            <col width={"auto"} />
            <col width={"10%"} />
            <col width={"10%"} />
            <col width={"10%"} />
            <col width={"5%"} />
            <col width={"10%"} />
          </colgroup>
          <thead>
            <tr className="mobile">
              <th>수업명</th>
              <th>
                <McBoardContent />
              </th>
              <th>운영여부</th>
            </tr>
            <tr className="desktop">
              {[
                "번호",
                "타입",
                "과목",
                "수업명",
                "담당",
                "수업일",
                "시간",
                "강의실",
                "수강료(원)",
                "학생 수",
                "운영여부",
              ].map((item) => (
                <th className="hide_text">{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {resData && !resData.classes.content[0] && (
              <tr>
                <td colSpan={11}>표시할 데이터가 없습니다.</td>
              </tr>
            )}
            {resData?.classes.content.map((el, idx) => {
              return (
                <tr
                  onClick={() => {
                    navigate("/academy/schedule/detail/" + el.class_id);
                  }}
                >
                  <td className="desktop">
                    {resData?.classes.totalElements - currPage * 5 - idx}
                  </td>
                  <td className="desktop hide_text">
                    {listDataFormat("class_type", el)}
                  </td>
                  <td className="desktop hide_text">
                    {listDataFormat("class_field", el)}
                  </td>
                  <td className="hide_text hide_text">
                    {listDataFormat("class_name", el)}
                  </td>
                  {/* 모바일 */}
                  <td className="mobile">
                    {listDataFormat(selectMcBoardContent.code, el)}
                  </td>
                  {/* 모바일 */}

                  <td className="desktop hide_text">
                    {listDataFormat("teacher_name", el)}
                  </td>
                  <td className="desktop hide_text">
                    {listDataFormat("class_week", el)}
                  </td>
                  <td className="desktop hide_text">
                    {listDataFormat("class_time", el)}
                  </td>
                  <td className="desktop hide_text">
                    {listDataFormat("room", el)}
                  </td>
                  <td className="desktop hide_text">
                    {listDataFormat("class_price", el)}
                  </td>
                  <td className="desktop hide_text">
                    {listDataFormat("student_cnt", el)}
                  </td>
                  <td>{el.class_is_opening ? "운영" : "미운영"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
      <ModalComponent />
    </div>
  );
}
