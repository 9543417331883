import { useState } from "react";

export default function useValidation() {
  // 유효성 검사 데이터 폼 생성 훅
  const [form, setForm] = useState({
    mb_name: { val: "", isValid: false },
    mb_password: { val: "", isValid: false },
    mb_confirm_password: { val: "", isValid: false },
    mb_email: { val: "", isValid: false },
    mb_ph: { val: "", isValid: false },
    company_ph: { val: "", isValid: false },
    company_fax: { val: "", isValid: false },
    bus_no: { val: "", isValid: false },
    license_no: { val: "", isValid: false },
  });

  const [isShowPw, setIsShowPw] = useState("password"); // 비밀번호 숨김/노출 여부 state

  const errorText = {
    mb_name: "이름 입력 형식이 잘못되었습니다.",
    mb_password: "비밀번호 입력 형식이 잘못되었습니다.",
    mb_confirm_password: "입력하신 비밀번호와 일치하지 않습니다.",
    mb_email: "이메일 입력 형식이 잘못되었습니다.",
    mb_ph: "휴대폰번호 입력 형식이 잘못되었습니다.",
    company_ph: "전화번호 입력 형식이 잘못되었습니다.",
    company_fax: "팩스 번호 입력 형식이 잘못되었습니다.",
    bus_no: "차량번호 입력 형식이 잘못되었습니다.",
    license_no: "사업장 등록번호 입력 형식이 잘못되었습니다.",
  };

  const placeholder = {
    mb_name: "한글, 영문만 입력",
    mb_password: "대문자/소문자/특수문자 포함 8자 이상",
    mb_confirm_password: "입력한 비밀번호를 다시 입력해 주세요.",
    mb_email: "",
    mb_ph: "",
    company_ph: "",
    company_fax: "7~15자리의 팩스번호",
    bus_no: "",
    license_no: "사업자 등록 번호 10자를 입력해 주세요.",
  };

  const getFormValues = (target) => {
    const formValue = {};
    for (let prop of form) {
      if (target.includes(prop)) {
        form[prop] = form[prop].val;
      }
    }
    return formValue;
  };

  const exp = {
    // mb_name: /^[가-힣a-zA-Z0-9]+$/,
    mb_name: /^[가-힣a-zA-Z]+[0-9]*$/,
    //한글, 영문, 숫자만 허용
    mb_password:
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/,
    //영문, 숫자, 특수문자를 최소 한가지씩 조합 8자 이상 30자 이하
    mb_email:
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
    // 이메일 형식
    mb_ph: /^(01[016789]{1}|070)[0-9]{3,4}[0-9]{4}$/,
    //01로 시작하는 10~11자리 숫자
    company_ph:
      /^(0(2|3[1-6]|4[0-5]|5[0-6]|6[1-3]|7[0-7]|8[0-9]|9[0-4])-?\d{3,4}-?\d{4}|01([016789])-?\d{3,4}-?\d{4})$/,
    //전화번호, 휴대폰번호 형식
    company_fax: /^\d{7,15}$/,
    // FAX
    bus_no: /(\d{2,3})[가-힣ㄱ-ㅎㅏ-ㅣ\x20]\d{4}$/g,
    // 12저1234
    license_no: /^\d{3}-\d{2}-\d{5}$/,
  };

  const valid = (e) => {
    let val = e.target.value && e.target.value.replace(/ /g, "");
    if (e.target.dataset.format === "number") val = val.replace(/[^0-9]/g, "");
    const type = e.target.dataset.type;
    switch (
      type // 데이터 타입 별 예외처리
    ) {
      case "mb_password": {
        //만약 입력중인 데이터 타입이 비밀번호라면 '비밀번호 확인' 항목의 값과도 비교하여 두 항목의 상태를 업데이트 한다.
        const pwCfmVal = form.mb_confirm_password.val;
        setForm({
          ...form,
          [type]: { val, isValid: exp[type].test(val) },
          mb_confirm_password: { val: pwCfmVal, isValid: val === pwCfmVal },
        });
        break;
      }
      case "mb_confirm_password": {
        //위와 동일
        setForm({
          ...form,
          [type]: { val, isValid: val === form.mb_password.val },
        });
        break;
      }
      case "email": {
        break;
      }
      case "bus_no": {
        const exp2 = /[가-힣ㄱ-ㅎㅏ-ㅣ\x20]{2}\d{2}[가-힣ㄱ-ㅎㅏ-ㅣ\x20]\d{4}/g;
        //차대번호는 정규식이 두가지이다. 첫번째 정규식 미통과 시 두번째 정규식까지 검사 ex)서울12치1233
        let isValidCheck = false;
        if (exp[type].test(val) && val.length <= 8) isValidCheck = true;
        if (exp2.test(val) && val.length <= 9) isValidCheck = true;
        setForm({
          ...form,
          [type]: {
            val,
            isValid: isValidCheck,
          },
        });
        break;
      }
      case "license_no": {
        //사업자번호 입력 시 자동으로 하이픈 추가
        val = val.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");

        setForm({ ...form, [type]: { val, isValid: exp[type].test(val) } });
        break;
      }
      default: {
        setForm({ ...form, [type]: { val, isValid: exp[type].test(val) } });
      }
    }
  };

  const validPass = (option) => {
    //유효성 검사 항목들이 모두 검사를 통과하였는지 체크하는 함수
    if (option) {
      return option.every((el) => form[el].isValid);
    } else return Object.values(form).every((el) => el.isValid);
  };

  const errorCheck = (type) => {
    //항목의 유효성 검사 통과 여부 데이터를 얻는 함수
    if (form[type].val && !form[type].isValid) {
      return {
        state: "error",
        alert: <p className="error_text">{errorText[type]}</p>,
      };
    }
  };

  const visibilityPw = () => {
    // 비밀번호 노출 여부 설정 함수
    if (isShowPw === "password") setIsShowPw("text");
    else setIsShowPw("password");
  };

  const validAttributes = (type, dataValue) => {
    // useInputHandler와 같이 태그에 필수적으로 지정 해야하는 속성&이벤트들을 담은 객체
    let value = form[type].val || "";
    const attributeData = {
      value,
      "data-type": type,
      "data-value": dataValue,
      placeholder: placeholder[type],
      onChange: valid,
    };

    const ph = ["mb_ph"];
    if (ph.includes(type)) {
      attributeData.value = value.replace(
        /(\d{3})(\d{3,4})(\d{4})/,
        "$1-$2-$3"
      );
      attributeData.maxLength = 13;
    }
    if (type === "license_no") {
      attributeData.value = value.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
      attributeData.maxLength = 12;
    }
    return attributeData;
  };

  return {
    form,
    isShowPw,
    setForm,
    valid,
    validPass,
    errorCheck,
    visibilityPw,
    getFormValues,
    validAttributes,
    exp,
  };
}
