import { useEffect, useState } from "react";
import useRequestAPI from "hooks/useRequestAPI";

export default function CurrentStudent({
  closeModal,
  changeData,
  currentData,
}) {
  const { requestAPI, Loading, setMessage } = useRequestAPI();
  const [resData, setResData] = useState();

  const getData = async () => {
    const res = await requestAPI(
      "post",
      "academy/shuttle-bus/detail/bus-stop",
      {
        bus_id: currentData.data.bus_id,
        bus_stop_id: currentData.data.bus_stop_id,
      }
    );
    if (!res) return;
    setResData(res.data.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <aside id="currentStudent" className="modal dim select">
      <Loading />
      <div className="modal_content_wrap">
        <header>
          <h2>
            탑승학생
            <span className="desc">
              ※정류장 삭제 시 아래 학생들의 정류장 연결이 해제됩니다.
            </span>
          </h2>
          <div className="btn_group">
            <button className="close btn_default" onClick={closeModal}>
              닫기
            </button>
          </div>
        </header>

        <section className="current_student">
          {resData && !resData[0] && (
            <p>해당 정류장에서 탑승하는 학생이 없습니다.</p>
          )}
          {resData && resData[0] && (
            <table className="detail fixed">
              <colgroup className="mobile">
                <col width={"24%"} />
                <col width={"auto"} />
                <col width={"30%"} />
              </colgroup>
              <colgroup className="desktop">
                <col width={"12%"} />
                <col width={"auto"} />
                <col width={"16%"} />
                <col width={"16%"} />
                <col width={"16%"} />
              </colgroup>
              <thead>
                <tr>
                  <th>이름</th>
                  <th>주소</th>
                  <th className="desktop">연락처</th>
                  <th className="desktop">보호자 연락처</th>
                  <th>수업</th>
                </tr>
              </thead>
              <tbody>
                {resData?.map((el, idx) => (
                  <tr key={idx}>
                    <td>{el?.child_name}</td>
                    <td className="">{el?.address}</td>
                    <td className="desktop">{el?.child_phone_number}</td>
                    <td className="desktop">{el?.parent_phone_number}</td>
                    <td>{el?.class_name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </section>

        <div className="btn_group mt_40">
          <button></button>
          <button
            className="btn_default half"
            onClick={() => {
              setMessage({
                type: "confirm",
                text: "해당 정류장에서 탑승하는 모든 학생들의\n연결이 해제됩니다. 삭제하시겠습니까?",
                confirm: () => changeData(currentData.data),
              });
            }}
          >
            삭제
          </button>
        </div>
      </div>
    </aside>
  );
}
