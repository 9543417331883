import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useModalHandler from "hooks/useModalHandler";
import useDatePicker from "hooks/useDatePicker";
import useInputHandler from "hooks/useInputHandler";
import useSelectBox from "hooks/useSelectBox";
import useRequestAPI from "hooks/useRequestAPI";
import ReturnToList from "components/ReturnToList";
import formatDate from "utils/formatDate";
import formatPriceKR from "utils/formatPriceKR";

export default function AcademyScheduleAdd() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { ModalComponent, openModal, sendModalData, currentModal } =
    useModalHandler();
  const { DatePickerModule, startDate, endDate } = useDatePicker();
  const [dataForm, setDataForm, attributes] = useInputHandler({
    type: id === "sp" ? "특강" : "일반",
    class_name: "",
    field_code: "field_1",
    class_room_time: [],
    start_date: id === "sp" ? null : startDate.toLocaleDateString(),
    end_date: id === "sp" ? null : endDate.toLocaleDateString(),
    is_end_date: id === "sp" ? false : true,
    mna_manager_id: "",
    mna_teacher_name: "",
    level: "1",
    memo: "",
    book: "",
    sub_book: "",
    price: null,
    class_capacity: null,
  });
  const [
    SelectBox_subject,
    selectValue_subject,
    setSelectValue_subject,
    setSelectList_subject,
  ] = useSelectBox([]);
  const [SelectBox_level, selectValue_level] = useSelectBox([
    { name: 1, code: 1 },
    { name: 2, code: 2 },
    { name: 3, code: 3 },
  ]);
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();
  const [isDupTeacher, setIsDupTeacher] = useState(false);

  const submitCondition = () => {
    return (
      dataForm.class_name &&
      dataForm.class_room_time[0] &&
      dataForm.price &&
      dataForm.mna_teacher_name &&
      dataForm.class_capacity
    );
  };

  const getData = async () => {
    const res = await requestAPI("post", "academy/field", {});
    if (!res) return;
    // console.log(res.data.data);
    const subjectList = res.data.data.map((el) => {
      return { name: el.fd_name, code: el.fd_code };
    });
    setSelectValue_subject(subjectList[0]);
    setSelectList_subject(subjectList);
  };

  const submitConfirm = () => {
    if (dataForm.class_capacity > scheduleCapacity()) {
      setMessage({
        type: "confirm",
        text: "수업 정원이 강의실 정원을 초과하였습니다. 그래도 등록 하시겠습니까?",
        confirm: submitData,
      });
    } else submitData();
  };

  const submitData = async () => {
    const body = {
      ...dataForm,
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
      field_code: selectValue_subject.code,
      level: selectValue_level.code,
    };
    delete body.mna_teacher_name;
    const res = await requestAPI("post", "academy/class/create", body);
    if (!res) return;
    setMessage({
      text: alertMsg.add,
      confirm: () => navigate("/academy/schedule/1"),
    });
  };

  const deleteTimeData = (e) => {
    let copy = [...dataForm.class_room_time];
    copy.splice(e.target.dataset.idx, 1);
    setDataForm({ ...dataForm, class_room_time: copy });
  };

  const scheduleCapacity = () => {
    if (!dataForm.class_room_time[0]) return 0;
    return Math.min(
      ...dataForm.class_room_time.map((el) => {
        return Number(el.capacity);
      })
    );
  };

  const teacherAvailability = async () => {
    if (!dataForm.mna_manager_id) return;
    const res = await requestAPI("post", "academy/class/manager", {
      class_room_time: dataForm.class_room_time,
      mna_manager_id: dataForm.mna_manager_id,
    });
    if (!res) return;
    if (res.data.data.class_time.is_valid) setIsDupTeacher(true);
    else setIsDupTeacher(false);
  };

  useEffect(() => {
    teacherAvailability(dataForm.class_room_time, dataForm.mna_manager_id);
  }, [dataForm.class_room_time, dataForm.mna_manager_id]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    switch (currentModal.type) {
      case "selectTime":
      case "selectSpecialTime": {
        const mergeData = [...dataForm.class_room_time, sendModalData];
        return setDataForm({
          ...dataForm,
          class_room_time: mergeData,
        });
      }
      case "selectTeacher": {
        return setDataForm({
          ...dataForm,
          mna_manager_id: sendModalData.id,
          mna_teacher_name: sendModalData.name,
        });
      }
      default: {
        return;
      }
    }
  }, [sendModalData]);

  return (
    <div className="inner schedule_add">
      <Loading />
      <header>
        <h2>시간표 등록({{ ga: "일반", sp: "특강" }[id]})</h2>
        <div className="btn_group">
          <ReturnToList />
        </div>
      </header>
      <section className="content_wrap">
        {id === "sp" && (
          <p className="desc mb_24">
            ※ 특강 등록 시, 일반 수업과
            <strong className="red">
              강의실 및 담당 선생님이 <br className="mobile" />
              중복될 수 있습니다.
            </strong>{" "}
            운영 중인 수업 확인 후 등록해 주세요.{" "}
          </p>
        )}

        <table className="detail fixed">
          <tbody>
            <tr className="desktop">
              <th>수업명</th>
              <td>
                <div className="cell_content_wrap">
                  <input type="text" {...attributes("class_name")} />
                </div>
              </td>
              <th>과목</th>
              <td>
                <div className="cell_content_wrap">
                  <SelectBox_subject />
                </div>
              </td>
            </tr>

            <tr className="mobile">
              <th>수업명</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <input type="text" {...attributes("class_name")} />
                </div>
              </td>
            </tr>
            <tr className="mobile">
              <th>과목</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <SelectBox_subject />
                </div>
              </td>
            </tr>

            <tr className="desktop">
              <th rowSpan={2}>시간</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <div className="btn_group">
                    <button
                      className="btn_default slim green3"
                      onClick={() => {
                        openModal("selectTime", id, dataForm.class_room_time);
                      }}
                    >
                      추가
                    </button>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <th className="mobile">시간</th>
              <td colSpan={3} className="select_list">
                <div className="cell_content_wrap">
                  {dataForm.class_room_time.map((el, idx) => {
                    return (
                      <span key={idx} className="select_item">
                        {`${el.week || el.date} / ${el.start_time} - ${
                          el.end_time
                        } / ${el.room}`}
                        <button data-idx={idx} onClick={deleteTimeData}>
                          &times;
                        </button>
                      </span>
                    );
                  })}
                  <button
                    className="add_schedule_time mobile"
                    onClick={() => {
                      openModal("selectTime", id, dataForm.class_room_time);
                    }}
                  >
                    +
                  </button>
                </div>
              </td>
            </tr>

            {id === "ga" && (
              <tr>
                <th>기간</th>
                <td colSpan={3}>
                  <div className="cell_content_wrap date">
                    <DatePickerModule type="start" />~
                    {!dataForm.is_end_date ? (
                      <DatePickerModule
                        type="end"
                        readOnly={dataForm.is_end_date}
                      />
                    ) : (
                      <input
                        className="indefinite"
                        type="text"
                        readOnly
                      ></input>
                    )}
                    <label htmlFor="isEndDate">
                      <input
                        type="checkbox"
                        id="isEndDate"
                        className="mr_8"
                        checked={dataForm.is_end_date}
                        onChange={(e) => {
                          let is_end_date;
                          if (e.target.checked) is_end_date = true;
                          else is_end_date = false;
                          setDataForm({ ...dataForm, is_end_date });
                        }}
                      />
                      종료일 없음
                    </label>
                  </div>
                </td>
              </tr>
            )}

            <tr className="desktop">
              <th>담당</th>
              <td>
                <div className="cell_content_wrap">
                  <input
                    className={"teacher" + (isDupTeacher ? " error" : "")}
                    type="text"
                    value={
                      dataForm?.mna_teacher_name +
                      (isDupTeacher ? " (시간중복)" : "")
                    }
                    readOnly
                  />
                  <div className="btn_group short">
                    <button
                      className="btn_default  slim green3"
                      onClick={() =>
                        openModal("selectTeacher", "add", {
                          class_room_time: dataForm.class_room_time,
                        })
                      }
                      disabled={!dataForm.class_room_time[0]}
                    >
                      선택
                    </button>
                  </div>
                </div>
              </td>

              <th>레벨</th>
              <td>
                <div className="cell_content_wrap">
                  <SelectBox_level />
                </div>
              </td>
            </tr>

            <tr className="mobile">
              <th>담당</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <input
                    className={
                      "teacher desktop " + (isDupTeacher ? " error" : "")
                    }
                    type="text"
                    value={
                      dataForm?.mna_teacher_name +
                      (isDupTeacher ? " (시간중복)" : "")
                    }
                    readOnly
                  />
                  <input
                    className={"small mobile " + (isDupTeacher ? " error" : "")}
                    type="text"
                    value={
                      dataForm?.mna_teacher_name +
                      (isDupTeacher ? " (시간중복)" : "")
                    }
                    readOnly
                  />
                  <div className="btn_group">
                    <button
                      className="btn_default  slim green3"
                      onClick={() =>
                        openModal("selectTeacher", "add", {
                          class_room_time: dataForm.class_room_time,
                        })
                      }
                      disabled={!dataForm.class_room_time[0]}
                    >
                      선택
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="mobile">
              <th>레벨</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <SelectBox_level />
                </div>
              </td>
            </tr>

            <tr>
              <th>수업내용</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <textarea {...attributes("memo")} />
                </div>
              </td>
            </tr>
            <tr>
              <th>교재</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <input type="text" {...attributes("book")} />
                </div>
              </td>
            </tr>
            <tr>
              <th>부교재</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <input type="text" {...attributes("sub_book")} />
                </div>
              </td>
            </tr>
            <tr className="desktop">
              <th>수업료</th>
              <td>
                <div className="cell_content_wrap">
                  <input
                    type="text"
                    className="half"
                    {...attributes("price")}
                    data-format="number"
                  />
                  <span className="unit hide_text">
                    {dataForm.price ? formatPriceKR(dataForm.price) : ""}
                  </span>
                </div>
              </td>
              <th>정원</th>
              <td>
                <div className="cell_content_wrap">
                  <input
                    type="text"
                    className="half"
                    {...attributes("class_capacity")}
                    data-format="number"
                  />
                  <span className="unit hide_text">
                    강의실 정원 {scheduleCapacity()}명
                  </span>
                </div>
              </td>
            </tr>

            <tr className="mobile">
              <th>수업료</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <input
                    type="text"
                    className="small"
                    {...attributes("price")}
                    data-format="number"
                  />
                  <span className="unit hide_text">
                    {dataForm.price ? formatPriceKR(dataForm.price) : ""}
                  </span>
                </div>
              </td>
            </tr>

            <tr className="mobile">
              <th>정원</th>
              <td colSpan={3}>
                <div className="cell_content_wrap">
                  <input
                    type="text"
                    className="small"
                    {...attributes("class_capacity")}
                    data-format="number"
                  />
                  <span className="unit hide_text desktop">
                    강의실 정원 {scheduleCapacity()}명
                  </span>
                  <span className="unit hide_text mobile">
                    정원 {scheduleCapacity()}명
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="btn_group">
          <button></button>
          <button
            className="btn_default half"
            onClick={submitConfirm}
            disabled={!submitCondition()}
          >
            등록
          </button>
        </div>
      </section>
      <ModalComponent />
    </div>
  );
}
