import { useEffect } from "react";
import useSelectBox from "hooks/useSelectBox";
import useFileHandler from "hooks/useFileHandler";
import useInputHandler from "hooks/useInputHandler";
import useRequestAPI from "hooks/useRequestAPI";
import ic_file_off from "assets/img/icon/ic_file_off.png";
import ic_file_on from "assets/img/icon/ic_file_on.png";

export default function AddPayment({ closeModal, changeData, currentData }) {
  const [dataForm, setDataForm, attributes] = useInputHandler({
    bank_account: currentData.data?.bank_account || "",
    bank_account_name: currentData.data?.bank_account_name || "",
    is_cms: false,
  });
  const backList = [
    { code: "02", name: "한국산업은행" },
    { code: "03", name: "기업은행" },
    { code: "04", name: "국민은행" },
    { code: "05", name: "하나은행 (구 외환)" },
    { code: "06", name: "국민은행 (구 주택)" },
    { code: "07", name: "수협중앙회" },
    { code: "11", name: "농협중앙회" },
    { code: "12", name: "단위농협" },
    { code: "16", name: "축협중앙회" },
    { code: "20", name: "우리은행" },
    { code: "21", name: "구) 조흥은행" },
    { code: "22", name: "상업은행" },
    { code: "23", name: "SC제일은행" },
    { code: "24", name: "한일은행" },
    { code: "25", name: "서울은행" },
    { code: "26", name: "구) 신한은행" },
    { code: "27", name: "한국씨티은행 (구 한미)" },
    { code: "31", name: "대구은행" },
    { code: "32", name: "부산은행" },
    { code: "34", name: "광주은행" },
    { code: "35", name: "제주은행" },
    { code: "37", name: "전북은행" },
    { code: "38", name: "강원은행" },
    { code: "39", name: "경남은행" },
    { code: "45", name: "새마을금고" },
    { code: "48", name: "신용협동조합중앙회" },
    { code: "50", name: "상호저축은행" },
    { code: "53", name: "한국씨티은행" },
    { code: "64", name: "산림조합" },
    { code: "70", name: "신안상호저축은행" },
    { code: "71", name: "우체국" },
    { code: "81", name: "하나은행" },
    { code: "83", name: "평화은행" },
    { code: "88", name: "신한(통합)은행" },
    { code: "89", name: "케이뱅크" },
    { code: "90", name: "카카오뱅크" },
    { code: "92", name: "토스뱅크" },
  ];
  const [SelectBox, selectValue, setSelectValue] = useSelectBox(backList);
  const { fileData, uploadFile, uploadS3 } = useFileHandler();
  const { requestAPI, alertMsg, Loading, setMessage } = useRequestAPI();

  // console.log(fileData);

  useEffect(() => {
    if (currentData.data?.bank_name)
      setSelectValue(
        backList.find((el) => el.name === currentData.data.bank_name)
      );
  }, []);

  return (
    <aside id="addPayment" className="modal dim">
      <Loading />
      <div className="modal_content_wrap">
        <header>
          <h2>계좌등록</h2>
          <div className="btn_group">
            <button className="close btn_default" onClick={closeModal}>
              닫기
            </button>
          </div>
        </header>

        <section>
          <table>
            <tr>
              <th>서비스 이용약관</th>
            </tr>
            <tr>
              <td>
                <div className="content_wrap">
                  1. 총칙
                  <br />
                  <br />
                  <br />
                  제 1 조 (목적)
                  <br />
                  <br />
                  본 이용약관은 “버터플라잉 주식회사(이하 “회사”)”이 제공하는
                  학습관리커뮤니케이션플랫폼 서비스 “에듀빌리”
                  (https://www.eduvily.kr/)를 통해 에듀빌리 및 밀리빌리, 회사의
                  제휴사가 제공하는 모든 “서비스”를 회원이 이용함에 있어
                  “회사”와 “회원” 간의 권리와 의무 및 책임사항을 정하는 데 그
                  목적이 있습니다.
                  <br />
                  <br />
                  제 2 조 (용어의 정의)
                  <br />
                  1.이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                  <br />
                  <span className="mr_32" />
                  1. “에듀빌리”(이하 '에듀빌리')란 PC 와 모바일 등
                  정보통신설비를 통해 학원(대표,주임선생님,선생님)과
                  "밀리빌리"(이하 '어플리케이션')을 사용하는 원생(학원생,
                  학부모, 학원생의 가족 등 이하 "밀리빌리 이용자") 등 모든
                  이용자가 오프라인 및 온라인의 학원 수업 및 전달 내용에서
                  발생하는 모든 정보를 실시간으로 공유하고 관리할 수 있는
                  서비스를 제공하는 플랫폼입니다.
                  <br />
                  <span className="mr_32" />
                  2. “서비스”는 회사가 이용자에게 정보통신설비를 이용하여 정보
                  및 재화 또는 용역을 거래할 수 있도록 회사가 설정하여 운영하는
                  웹사이트, 모바일웹, 앱 등을 말합니다. 아울러 전자상거래를
                  운영하는 사업자의 의미로도 사용합니다.
                  <br />
                  <span className="mr_32" />
                  3. “이용자”란 에듀빌리(웹 또는 APP)에 접속하여 이 약관에 따라
                  회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
                  <br />
                  <span className="mr_32" />
                  4. “사용자”이라 함은 회사에 개인정보를 제공하여 회원가입을 한
                  자로서, 휴대폰 본인 인증을 통해 회원인증 절차를 진행한 자를
                  말합니다. 회원은 종류는 크게 3 가지로 구분됩니다.
                  <br />
                  <span className="mr_64" />• “대표” : “대표”는 “생성”을 통해
                  에듀빌리 서비스 내 학원을 등록한 자로 에듀빌리 내 서비스를
                  이용하여 학원에서 운영되는 학습, 관리, 행정 등의 학원 운영과
                  관련된 업무를 운영하고, 밀리빌리 사용자에게 제공하는 정보에
                  대해 관리를 하는 자를 말합니다.
                  <br />
                  <span className="mr_64" />• “주임선생님” : 에듀빌리에 등록된
                  학원에 소속되어 “대표”로 부터 권한 위임을 받은 자를 말합니다.
                  <br />
                  <span className="mr_64" />• “선생님” : 에듀빌리에 등록된
                  학원에 소속되어 수업에 대한 정보를 제공하는 자입니다.
                  <br />
                  <span className="mr_32" />
                  5. “제휴 서비스”는 회사와 제휴를 통해 외부에서 자체적으로 제공
                  및 운영하는 APP 또는 웹(Web)사이트에서 에듀빌리를 통해
                  회원에게 제공되는 서비스를 의미합니다. 회사는 에듀빌리 내
                  배너, 링크, API 연결 등을 통해 “제휴 서비스”를 이용할 수 있는
                  연결 통로를 제공할 뿐 이용자가 “제휴 서비스”에 접속한 이후에는
                  본 약관이 적용되지 않으며, 회사는 이용자의 “제휴 서비스”의
                  이용에 대해서 어떠한 의무나 책임도 없습니다. “제휴 서비스”는
                  회사와 제휴 관계에 따라 계속해서 서비스의 증감 및 변동이
                  발생될 수 있습니다.
                  <br />
                  <span className="mr_32" />
                  6. “아이디(ID)”라 함은 사용자가 서비스를 이용하고, 회사가
                  회원을 구분, 식별할 수 있도록 본인인증을 통해 검증된 휴대폰
                  전화번호를 말합니다.
                  <br />
                  <span className="mr_32" />
                  7. “비밀번호”라 함은 사용자가 아이디(ID)와 동일한 회원임을
                  확인하고 권익을 보호받기 위하여 이용자가 선정한 문자가 숫자의
                  조합을 말합니다.
                  <br />
                  <span className="mr_32" />
                  8. “회원가입”이라 함은 이용자가 회사가 제공하는 신청서 양식에
                  해당정보를 기입하고 본 약관에 동의하여 서비스 이용계약을
                  완료하는 행위를 말합니다.
                  <br />
                  <span className="mr_32" />
                  9. “회원탈퇴”라 함은 회사 또는 회원이 서비스 이용계약을
                  해지하는 것을 말합니다.
                  <br />
                  2. 이 약관에서 사용하는 용어의 정의는 제 1 항에서 정하는 것을
                  제외하고는 거래관행 및 관계법령에서 정하는 바에 의합니다.
                  <br />
                  <br />
                  제 3 조 (약관의 효력 및 변경)
                  <br />
                  1. 회사는 서비스의 초기화면에 이 약관을 명시합니다.
                  <br />
                  2. 이 약관은 회사와 이용자 사이에 제 5 조에 따라 서비스 이용
                  계약이 성립함과 동시에 효력이 발생하며, 이 약관에 동의하지
                  않을 경우 이용자는 서비스 이용계약을 철회할 수 있습니다.
                  <br />
                  3. 회사는 “전자상거래 등에서의 소비자보호에 관한 법률”,
                  “개인정보보호법”, “약관의 규제에 관한 법률”, “정보통신망
                  이용촉진 및 정보보호 등에 관한 법률”, “콘텐츠산업진흥법” 등
                  관련법을 위배하지 않는 범위에서 임의로 이 약관을 개정할 수
                  있습니다.
                  <br />
                  4. 변경된 약관은 적용일 및 변경 내용, 변경 사유 등을 명시하여
                  적용일 15 일 이전(다만, 이용자에게 불리한 약관의 개정의
                  경우에는 적용일 30 일 이전)부터 적용일 전일까지 서비스에
                  고지한 후 적용일부터 효력이 발생됩니다.
                  <br />
                  5. 이용자는 변경된 약관에 동의하지 않을 권리가 있으며, 변경된
                  약관에 동의하지 않을 경우에는 서비스 이용을 중단하고 탈퇴할 수
                  있습니다.
                  <br />
                  6. 변경된 약관 공지 시 이용자가 회사에 명시적으로 “거부”의
                  의사표시를 하지 않거나 또는 이용자가 변경된 이용약관의 적용일
                  이후에도 서비스를 계속 사용하는 경우에는 변경된 약관에 동의한
                  것으로 간주합니다.
                  <br />
                  <br />
                  제 4 조 (약관 외 내용)
                  <br />
                  이 약관은 회사가 제공하는 개인정보 보호정책과 함께 적용하며,
                  이 약관에서 규정하지 않은 사항에 대해서는
                  전자거래소비자보호지침 및 관계법령 또는 상관례에 따릅니다.
                  <br />
                  <br />
                  <br />
                  2. 서비스의 이용
                  <br />
                  <br />
                  <br />
                  제 5 조 (서비스 이용 계약의 성립)
                  <br />
                  1. 서비스 이용계약은 이용자의 본 약관의 내용에 대한 동의와
                  이용신청에 대하여 회사의 이용 승낙으로 성립합니다.
                  <br />
                  2. 본 이용약관에 대한 동의는 회원가입 당시 서비스의 ‘동의함’
                  버튼을 누름으로써 의사표시를 합니다.
                  <br />
                  3. 회사는 회원이 되고자 하는 자의 신청에 대하여 서비스 이용을
                  승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는
                  신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수
                  있습니다.
                  <br />
                  <span className="mr_32" />
                  1. 가입신청자가 본 이용약관에 의하여 이전에 회원자격을 상실한
                  적이 있는 경우, 단, 회사의 회원 재가입 승낙을 얻는 경우에는
                  예외로 한다.
                  <br />
                  <span className="mr_32" />
                  2. 타인의 명의를 이용한 경우
                  <br />
                  <span className="mr_32" />
                  3. 허위의 정보를 기재하거나 회사가 제시하는 내용을 기재하지
                  않은 경우
                  <br />
                  <span className="mr_32" />
                  4. 가입신청자의 귀책사유로 인하여 승인이 불가능하거나 기타
                  규정한 제반 사항을 위반하며 신청하는 경우
                  <br />
                  4. 제 3 항에 따른 신청에 있어 회사는 전문기관을 통한 실명확인,
                  본인인증 및 법정대리인의 동의를 요청할 수 있습니다.
                  <br />
                  <br />
                  제 6 조 (서비스 가입 및 계정)
                  <br />
                  1. 회원으로 가입하여 본 서비스를 이용하고자 하는 고객은
                  회사에서 요청하는 정보(이름, 연락처, 전자우편주소, 본인인증
                  등)를 제공하여야 합니다.
                  <br />
                  2. 모든 회원은 반드시 회원 본인의 이름과 회사에서 요청하는
                  정보를 제공하여야만 서비스를 이용할 수 있으며, 실명으로
                  등록하지 않은 사용자는 일체의 권리를 주장할 수 없습니다.
                  <br />
                  3. 회원가입은 본인인증을 통한 실명으로만 가입할 수 있으며,
                  개인정보의 변경이 있을 시 추가적인 본인인증을 통해 개인정보를
                  갱신하여야 합니다. 최신 정보가 아닐 경우 서비스의 제약이 있을
                  수 있습니다.
                  <br />
                  4. 회사는 계정 정보를 통해 미성년자 여부 확인 등의 이용자 관리
                  업무를 수행합니다.
                  <br />
                  5. 타인의 명의(이름 및 타인신상정보)를 도용하여 이용신청을 한
                  회원의 모든 정보는 비활성화되며, 관계법령에 따라 처벌을 받을
                  수 있습니다.
                  <br />
                  6. 이용자는 자신의 계정을 성실히 관리하여야 합니다. 이용자가
                  본인의 계정을 소홀히 관리하거나 제 3 자에게 이용을
                  승낙함으로써 발생하는 손해에 대해서는 이용자에게 책임이
                  있습니다.
                  <br />
                  <br />
                  제 7 조 (만 14 세 미만 아동의 회원가입에 관한 특칙)
                  <br />
                  1. 만 14 세 미만의 이용자는 에듀빌리에서 제공하는 서비스
                  사용이 불가합니다. 별도의 예외상황의 경우, 회사와 협의 후
                  진행될 수 있습니다.
                  <br />
                  2. 회사는 만 14 세 미만 이용자에 대하여는 회원가입을 불허 또는
                  취소할 수 있습니다.
                  <br />
                  <br />
                  제 8 조 (서비스의 종류)
                  <br />
                  회사가 자체 개발 및 외부 제휴를 통해 회원에게 제공하는
                  서비스의 종류는 다음과 같습니다.
                  <br />
                  1. 학원 홍보 서비스 : 지역 내 학원을 찾고 있는 가망 회원(학생,
                  학부모)에게 영상, 사진, 게시글 형태로 학원을 홍보하는 서비스를
                  말합니다.
                  <br />
                  2. 학원 운영 서비스 : 학원 정보 관리, 선생님/셔틀버스 관리,
                  학생 관리 등의 서비스를 말합니다.
                  <br />
                  3. 수업 관리 서비스 : 출결, 알림, 숙제, 준비물 등 학원에서
                  진행되는 수업에 필요한 기능을 제공하는 서비스를 말합니다.
                  <br />
                  4. 수납 발송 및 학원비 간편 결제 서비스 : 학원이 등록한
                  수납일에 맞춰 학원비 수납정보를 수신 및 열람하거나 수납 요청에
                  따른 학원비를 간편하게 납부할 수 있는 서비스를 말합니다.
                  <br />
                  5. 위치기반 학원 정보 및 교육 정보 제공 서비스는 아래와 같은
                  서비스를 제공합니다.
                  <br />
                  <span className="mr_32" />• 위치정보를 활용한 학원 또는 클래스
                  정보 검색결과 제공
                  <br />
                  <span className="mr_32" />• 위치정보를 활용한 교육 정보 및
                  콘텐츠를 제공하거나 추천
                  <br />
                  <span className="mr_32" />• 위치기반의 콘텐츠 분류를 위한
                  콘텐츠 태깅(Geotagging)
                  <br />
                  <span className="mr_32" />• 위치기반의 맞춤형 광고
                  <br />
                  6. 실시간 상담/채팅 서비스 : 학원-학부모 간, 학원-학생 간 화상
                  또는 메시지 형태로 실시간 소통을 지원하는 서비스를 말합니다.
                  <br />
                  7. 화상강의 : 학원이 호스트가 되어 클래스에 등록된 학생을
                  대상으로 화상강의를 진행할 수 있는 서비스를 말합니다.
                  <br />
                  8. 기타 연계 서비스 : 제휴사와 연계하여 제공하는 서비스 및 본
                  조 각 서비스에 부수하거나 연결된 서비스를 말합니다.
                  <br />
                  <br />
                  제 9 조 (학원 일정 추천 서비스)
                  <br />
                  1.“학원 일정 추천 서비스”는 밀리빌리 이용자가 서비스를 통해
                  학원 추천을 요청할 경우 에듀빌리에 등록된 학원과 클래스를
                  검색하여 등록을 희망하는 학원의 상담, 온라인 수강신청, 수강료
                  간편 결제까지 진행하는 서비스를 말합니다.
                  <br />
                  2. 밀리빌리 이용자는 본 서비스를 통해 에듀빌리에 등록되어 있는
                  학원의 추천과 상담을 요청할 수 있습니다.
                  <br />
                  3. 회사는 양 당사자간의 계약에 따른 수업에 대하여 책임을
                  부담하지 아니하며 특히 수업 완료 등록 이후 수강료 지급이
                  완료된 건에 대해 발생한 분쟁에 대하여 책임지지 않습니다.
                  <br />
                  4. 이외 수강 계약 체결에 따라 발생할 수 있는 수강료 결제, 수업
                  회차에 따른 수강료 정산 및 환불의 경우, 각 학원의 수강/환불
                  규정에 따르며, 이에 따라 발생할 수 있는 분쟁에 대해서 회사는
                  별도의 개입을 하지 않으며, 어떠한 책임도 지지 않습니다.
                  <br />
                  <br />
                  제 10 조 (수집하는 개인정보 항목)
                  <br />
                  1.회사는 서비스 이용 희망자가 서비스를 이용하기 위해 회원으로
                  가입할 때 서비스 제공을 위하여 아래와 같은 개인 정보를
                  수집하고 있습니다.
                  <br />
                  1. 수집 항목
                  <br />
                  <span className="mr_32" />• 회원 필수 항목 : 성명, 생년월일,
                  이메일 주소, 비밀번호, 휴대전화 번호, 서비스 이용기록, 접속
                  로그, 쿠키, IP 주소
                  <br />
                  <span className="mr_32" />• “학원 생성” 시 필수 항목 : 학원명,
                  주소, 주요 교습 카테고리, 학원 주소, 학원 대표 번호, 학원
                  사업자등록증 사본, 학원 통장 사본
                  <br />
                  <span className="mr_32" />• “학생 등록” 선택 항목 : 이름,
                  생년월일, 성별, 보호자 연락처
                  <br />
                  2. 수집 방법 : 에듀빌리 사이트 및 모바일 앱
                  <br />
                  <br />
                  제 11 조 (개인정보 수집 및 이용 목적)
                  <br />
                  1.회사는 회원의 사전 동의 없이는 회원의 개인정보를 공개하지
                  않으며 수집된 정보는 다음의 목적을 위해 활용하고 있습니다.
                  <br />
                  <span className="mr_32" />
                  1. 회원의 에듀빌리 서비스 및 정보서비스 제공에 관한 계약 이행
                  및 서비스 제공에 따른 요금 정산, 콘텐츠 서비스 이용, 구매 및
                  요금결제 등
                  <br />
                  <span className="mr_32" />
                  2. 회원 관리, 회원제 서비스에 따른 본인 확인, 개인 식별, 불량
                  회원의 부정 이용 방지 및 비인가 사용 방지, 가입 의사 확인,
                  연령확인, 불만 처리 등 민원 처리, 고지사항 전달 등
                  <br />
                  <span className="mr_32" />
                  3. 마케팅 및 광고에 활용, 신규 서비스 개발 및 특화, 이벤트 등
                  광고성 정보 전달, 인구통계학적 특성에 따른 서비스 제공 및 광고
                  게재, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계
                  (정보수집 동의 시 동의하지 않을 수 있습니다.)
                  <br />
                  <br />
                  제 12 조 (이용자 및 회원 개인정보의 보유 및 이용기간)
                  <br />
                  1.회원이 서비스를 이용하는 동안 회사는 회원의 개인 정보를
                  계속적으로 보유하며 서비스 제공 등을 위해 이용할 수 있습니다.
                  원칙적으로 개인정보 수집 및 이용 목적이 달성된 후에는 해당
                  정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의
                  이유로 명시한 기간 동안 보존합니다.
                  <br />
                  <span className="mr_32" />
                  1. 서비스 이용 및 기관 수사 협조 등을 위해 필요한 경우 아래와
                  같이 회원 또는 이용자 정보를 보관합니다.
                  <br />
                  <span className="mr_64" />• 보존항목: 서비스 이용기록,
                  접속로그, 쿠키, 접속 IP 정보
                  <br />
                  <span className="mr_64" />• 보존근거: 서비스 혼선 방지,
                  수사기관에 의한 협조
                  <br />
                  <span className="mr_64" />• 보존기간: 6 개월
                  <br />
                  <span className="mr_32" />
                  2. 관계법령의 규정에 의하여 보존할 필요가 있는 경우 아래와
                  같이 관계법령에서 정한 일정한 기간 동안 "회원" 정보를
                  보관합니다.
                  <br />
                  <span className="mr_64" />• 표시, 광고에 관한 기록 : 6 개월
                  (전자상거래등에서의 소비자보호에 관한 법률)
                  <br />
                  <span className="mr_64" />• 계약 또는 청약철회 등에 관한 기록
                  : 5 년 (전자상거래등에서의 소비자보호에 관한 법률)
                  <br />
                  <span className="mr_64" />• 대금결제 및 재화 등의 공급에 관한
                  기록 : 5 년 (전자상거래등에서의 소비자보호에 관한 법률)
                  <br />
                  <span className="mr_64" />• 소비자의 불만 또는 분쟁처리에 관한
                  기록 : 3 년 (전자상거래등에서의 소비자보호에 관한 법률)
                  <br />
                  <br />
                  제 13 조 (회사의 의무)
                  <br />
                  1.회사는 관계 법령을 준수하고, 이 약관에서 정하는 권리의
                  행사와 의무의 이행을 신의에 따라 성실하게 수행합니다.
                  <br />
                  2. 회사는 계속적이고 안정적인 서비스의 제공을 위하여 최선의
                  노력을 다합니다.
                  <br />
                  3. 회사는 이용자들이 서비스를 편리하게 이용할 수 있도록 최선의
                  노력을 다합니다.
                  <br />
                  4. 회사는 이용자가 제기한 의견이나 불만이 정당하다고
                  객관적으로 인정될 경우에는 합리적인 기간 내에 신속하게
                  처리하기 위해 노력합니다.
                  <br />
                  5. 회사는 개인정보 보호를 위해 보안시스템을 구축하며 개인정보
                  보호정책을 공시하고 준수합니다.
                  <br />
                  <br />
                  제 14 조 (이용자의 의무)
                  <br />
                  1.이용자는 서비스를 본래의 이용 목적 이외의 용도로 사용하거나
                  다음 각 호에 해당하는 행위를 해서는 안되며, 아래의 항을
                  위반하는 것으로 의심되는 이용자를 발견할 시 이메일을 통해
                  신고를 접수하여야 합니다.
                  <br />
                  <span className="mr_32" />
                  1. 다른 이용자의 계정 혹은 타인의 결제 정보를 도용하거나
                  회사의 관리자를 사칭하는 행위
                  <br />
                  <span className="mr_32" />
                  2. 서비스 이용 중 허위 사실을 기재하여 서비스 운영을 방해하는
                  행위
                  <br />
                  <span className="mr_32" />
                  3. 회사가 제공한 계정을 타인에게 양도하는 행위
                  <br />
                  <span className="mr_32" />
                  4. 회사의 서비스를 이용하여 얻은 정보를 회사의 사전 승낙없이
                  복제, 유통하여 상업적으로 이용하는 행위
                  <br />
                  <span className="mr_32" />
                  5. 사기성, 음란성, 사행성, 혐오성 메시지 혹은 음성 등이 담긴
                  콘텐츠를 유포하거나 게시하는 행위
                  <br />
                  <span className="mr_32" />
                  6. 타인의 명예를 훼손하거나 사이버 불링, 폭력적인 행위를 하는
                  등 이용자의 정상적인 서비스 이용을 방해하는 행위
                  <br />
                  <span className="mr_32" />
                  7. 저작권을 포함한 지적재산권, 초상권 등 회사 및 제 3 자의
                  기타 권리를 침해하는 행위
                  <br />
                  <span className="mr_32" />
                  8. 타인의 개인정보를 동의없이 수집, 저장, 유포, 게시하는 행위
                  <br />
                  <span className="mr_32" />
                  9. 일반적이지 않은 방식으로 데이터를 조작하여 회사의 서비스
                  운영을 방해하는 행위
                  <br />
                  <span className="mr_32" />
                  10. 컴퓨터 소프트웨어, 하드웨어, 전기 통신 장비의 정상적인
                  가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타
                  다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 전송,
                  게시, 유포, 사용하는 행위
                  <br />
                  <span className="mr_32" />
                  11. 서버 해킹, 데이터 유출, 버그 등을 통해 서비스를 변경하거나
                  회사가 정하지 않은 방식으로 서비스를 이용하는 행위
                  <br />
                  <span className="mr_32" />
                  12. 관계 법령, 서비스이용약관, 운영정책, 공지사항을 위배하는
                  행위
                  <br />
                  <span className="mr_32" />
                  13. 기타 공공질서 및 미풍양속을 위반하거나 불법적, 부당한
                  방식의 행동을 하는 행위
                  <br />
                  2. 회사는 이용자가 제 1 항에 정한 서비스 이용 제한 기준을
                  위반하여 회사의 서비스에 지장을 초래한 경우, 사전 통보 없이
                  기간을 정하여 서비스 이용을 제한할 수 있습니다.
                  <br />
                  <br />
                  제 15 조 (게시물 관리)
                  <br />
                  1.에듀빌리 내의 모든 콘텐츠에 대한 저작권 및 지식재산권은
                  이용자가 직접 작성한 게시물을 제외하고 모두 회사에 귀속됩니다.
                  회사의 사전 승인 없이 특정 소프트웨어, 상표, 콘텐츠의 내용을
                  무단으로 도용하거나, 수정 및 변경을 가한 파생물을 제작하여
                  상업적으로 이용하는 행위는 금지되어 있으며 상업적인 목적 없이
                  이용하는 경우라도 단순 링크 연결이 아닌, 콘텐츠 직접 게재
                  방식을 취하는 경우에는 회사의 사전 동의를 얻은 후 그 표현
                  방식에 관해서도 협의를 거쳐야 합니다.
                  <br />
                  2. 에듀빌리 이용 시 다른 이용자 또는 제 3 자의 저작권 등 기타
                  권리를 침해하는 행위는 금지됩니다. 서비스 이용 중 저작권과
                  관련하여 발생하는 분쟁에 대한 책임은 이용자에게 있습니다.
                  <br />
                  3. 회사는 이용자가 게시한 콘텐츠를 국내외에서 제공하는 관련
                  서비스(홍보 등) 목적으로 사용할 수 있습니다.
                  <br />
                  4. 제 3 항의 사용범위는 이용자가 서비스 내에 게시한 콘텐츠의
                  복제, 수정, 전시, 전송, 배포, 출판 및 2 차 저작물과 편집
                  저작물 작성을 포함합니다.
                  <br />
                  5. 이용자가 게시한 콘텐츠를 상업적 혹은 제 4 항에서 정하지
                  않은 방식으로 사용할 경우, 회사는 전화, 팩스, 전자 우편 등의
                  방식으로 이용자에게 동의를 얻을 수 있으며, 별도의 보상을 할 수
                  있습니다.
                  <br />
                  6. 회사는 이용자가 작성한 게시물이 관련 법령에 위반되거나 음란
                  또는 청소년에게 유해한 게시물, 차별 갈등을 조장하는 게시물,
                  도배 • 광고 ▪ 홍보 ▪ 스팸성 게시물, 계정을 양도 또는
                  거래하는 내용의 게시물, 타인을 사칭하는 게시물, 타인의 권리를
                  침해하는 게시물 등이라고 판단되는 경우 이를 삭제하거나 게시를
                  거부할 수 있습니다.
                  <br />
                  7. 회사는 회사가 작성하지 아니한 게시물에 대해서는 신뢰도,
                  정확성, 타인 권리 불침해성, 적법성 등에 관한 일체의 보증을
                  하지 않으며, 일체의 책임도 부담하지 아니합니다. 해당 게시물에
                  관해서는 해당 게시물을 제공한 이용자 등 주체가 단독으로 모든
                  책임을 부담하게 됩니다.
                  <br />
                  8. 회사는 회원이 제공하는 정보, 자료에 대하여 회사의 공지,
                  이용 안내 등을 통해 일정한 게재 기간이나 자료의 용량을 정할 수
                  있습니다.
                  <br />
                  <br />
                  제 16 조 (서비스 내용 및 중단)
                  <br />
                  1. 회사는 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1
                  일 24 시간 서비스를 제공합니다.
                  <br />
                  2. 회사는 시스템 정기점검 등 서비스 운영상 필요한 경우,
                  일정기간 동안 서비스를 일시 중지시킬 수 있으며, 이러한 경우
                  회사는 그 내용 및 시간을 서비스 내에 공지합니다. 다만, 회사가
                  사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할
                  수 있습니다.
                  <br />
                  3. 회사는 새로운 서비스, 각종 버그 패치 등 서비스 운영 또는
                  기술상 필요한 경우에는 제공하고 있는 서비스의 전부 또는 일부를
                  수정, 추가, 폐지 등 변경할 수 있습니다. 변경될 서비스의 내용
                  및 일자 등에 대해서는 서비스 내에 공지함으로써 이용자에 대한
                  통지를 대체합니다.
                  <br />
                  4. 회사는 서비스의 기획이나 운영상 또는 회사의 긴박한 상황
                  등에 의해 서비스 전부를 중단할 필요가 있는 경우 서비스 내에
                  이를 공지하고 서비스의 제공을 중단할 수 있습니다. 단, 회사가
                  통제할 수 없는 사유로 인한 경우 공지없이 서비스가 중단될 수
                  있습니다.
                  <br />
                  <br />
                  제 17 조 (계약 변경 및 해지)
                  <br />
                  회원이 이용계약을 변경 또는 해지하고자 하는 때에는 관계법령 및
                  서비스 별 안내에서 정하는 바에 의합니다.
                  <br />
                  <br />
                  제 18 조 (서비스 이용제한)
                  <br />
                  1.회사는 회원이 서비스 이용 내용에 있어서 본 약관의 제 14 조를
                  위반하거나, 다음 각 호에 해당하는 경우 서비스 이용을 제한할 수
                  있습니다.
                  <br />
                  <span className="mr_32" />
                  1. 미풍양속을 저해하는 정보를 생산하는 경우
                  <br />
                  <span className="mr_32" />
                  2. 타 이용자에게 심한 모욕을 주거나, 서비스 이용을 방해한 경우
                  <br />
                  <span className="mr_32" />
                  3. 기타 정상적인 서비스 운영에 방해가 될 경우
                  <br />
                  <span className="mr_32" />
                  4. 정보통신 윤리위원회 등 관련 공공기관의 시정 요구가 있는
                  경우
                  <br />
                  <span className="mr_32" />
                  5. 컴퓨터 바이러스, 불법 자료, 음란물, 저작권이 글 또는 mp3
                  등의 콘텐츠를 무단으로 게재한 경우
                  <br />
                  2. 상기 이용제한 규정에 따라 서비스를 이용하는 회원에게 서비스
                  이용에 대하여 별도 공지없이 서비스 이용의 일시 정지, 초기화,
                  이용계약 해지 등을 처리규정에 따라 취할 수 있습니다.
                  <br />
                  <br />
                  <br />
                  3. 서비스 이용료
                  <br />
                  <br />
                  <br />
                  제 19 조 (결제 종류 및 방식)
                  <br />
                  1.결제 종류는 아래와 같습니다.
                  <br />
                  <span className="mr_32" />
                  1. 밀리빌리 이용자의 학원비 결제
                  <br />
                  <span className="mr_32" />
                  2. 에듀빌리 서비스 이용자에 대한 CMS 결제
                  <br />
                  2. 회사는 결제의 이행을 위하여 반드시 필요한 회원의 개인정보를
                  추가적으로 요구할 수 있으며, 회원은 회사가 요구하는 개인정보를
                  정확하게 제공하여야 합니다.
                  <br />
                  3. 본 서비스의 이용료는 밀리빌리 이용자가 학원비 수납시 차감
                  후 정산됩니다. 이용료는 웹페이지내 명시된 이용료가 적용됩니다.
                  <br />
                  4. 밀리빌리 이용자가 학원비 수납을 서비스 외 수단을 이용할
                  경우(계좌이체 및 현금지급 등) 등록된 CMS 계좌를 통해 서비스
                  이용료가 부과됩니다.
                  <br />
                  5. 밀리빌리 이용자가 학원비 수납시 발생하는 신용카드 수수료는
                  차감 후 정산됩니다. 해당 수수료는 웹페이지내 명시된 수수료
                  비율이 적용됩니다.
                  <br />
                  <br />
                  제 20 조 (결제 취소 및 청약 철회) <br />
                  1. 회사는 회원이 본 약관에 의하여 서비스 이용 계약을
                  해지하거나, 회사의 판단에 의해 회원의 자격을 상실한 경우 결제
                  취소 처리를 원칙으로 합니다.
                  <br />
                  2. 밀리빌리 이용자가 서비스에 대한 청약 철회의 의사를 표시할
                  경우, 에듀빌리 사용자는 서비스를 통해 해당 학생을 수업에서
                  제외해야 하고, 철회 시점에 따른 구매 대금에 대한 정산은 각
                  학원의 수강/환불 규정에 따르며, 이에 따라 발생할 수 있는
                  분쟁에 대해서 회사는 별도의 개입을 하지 않으며, 어떠한 책임도
                  지지 않습니다.
                  <br />
                  3. 회원의 청약철회 시점이 수업 스케줄 시작 이후라면 제 23 조
                  환불 규정을 적용하며, , 회사는 수납요청을 밀리빌리 이용자에게
                  제공합니다.
                  <br />
                  4. 회사는 청약 철회를 제외하고 회사 및 밀리빌리 이용자의
                  귀책사유로 인한 환불 시 회원이 “에듀빌리” 이용으로부터 얻은
                  이익 및 결제 수수료, 유료 서비스 이용 수수료 등 부대 비용을
                  공제(회사의 귀책으로 환불 시 부대 비용 공제 제외)하고 별첨에
                  정해진 수업 및 환불 규정에 따라 환불 조치합니다.
                  <br />
                  5. 회원 탈퇴로 인한 정보 확인 불가 및 환불 요청 시 회원의
                  오기(誤記) 등의 귀책사유로 인해 발생하는 불이익에 대해서
                  회사는 고의 또는 중과실이 없는 한 회사는 책임을 지지 않습니다.
                  <br />
                  6. 에듀빌리 사용자가 환불을 하고자 하는 경우 고객센터 또는
                  상품의 제공 주체에 이메일 등을 통하여 그 의사를 표시하여야
                  하며, 회사는 환불 요청을 접수하고 회원의 요청과 환불 규정을
                  확인한 후, 환불 요청 접수일로부터 3 영업일 이내에 해당 비용을
                  환불합니다. 단, 회사의 책임 영역 외의 금융사의 전산 마비 등
                  외부적 사정에 의하여 다소 지연이 될 수 있으며, 이와 같은
                  지연의 경우 회사는 책임을 지지 않습니다.
                  <br />
                  <br />
                  제 21 조 (환불 규정)
                  <br />
                  회사는 유료 서비스 상품에 대해 원칙적으로 다음과 같은 환불
                  규정을 적용합니다.
                  <br />
                  1.유료 서비스 상품 환불 규정
                  <br />
                  <span className="mr_32" />
                  1. 결제일로부터 5 일내 100% 환불
                  <br />
                  <span className="mr_32" />
                  2. 결제일로부터 5 일 초과시 해당 학원과 별도 진행
                  <br />
                  2. 비고
                  <br />
                  <span className="mr_32" />
                  1. 해당 수업이 종료된 이후에는 수강료 납부가 진행되지
                  않습니다.
                  <br />
                  <br />
                  <br />
                  4. 기타
                  <br />
                  <br />
                  <br />
                  제 22 조 (손해배상)
                  <br />
                  1.회사는 제공하는 서비스의 이용과 관련하여 이용자의 손해가
                  발생하는 경우 이용과 관련하여 개인정보보호정책에서 정하는
                  내용에 위반하는 등 회사측에 고의 또는 중과실이 있는 때가 아닌
                  한 어떠한 책임을 지지 않습니다.
                  <br />
                  2. 이용자는 서비스 이용과 관련하여 고의 또는 과실로 회사 또는
                  제 3 자에게 손해를 끼친 경우에는 이를 배상하여야 합니다.
                  <br />
                  <br />
                  제 23 조 (분쟁해결)
                  <br />
                  1.회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그
                  피해를 보상처리하기 위하여 피해보상처리기구인 고객만족센터를
                  설치/운영합니다.
                  <br />
                  2. 회사는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로
                  그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는
                  이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
                  <br />
                  3. 회사와 이용자 간에 발생한 전자상거래 분쟁과 관련하여
                  이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는
                  시/도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
                  <br />
                  <br />
                  제 24 조 (면책조항)
                  <br />
                  1.회사는 회원의 부주의나 고의로 인해서 발생된 어떠한 손해에
                  대해서도 책임을 지지 않습니다.
                  <br />
                  2. 회사는 회원이 회사의 서비스로부터 기대한 이익을 얻지
                  못하였거나, 서비스를 통해 취득한 자료를 취사선택하거나
                  이용함으로 인하여 입게 된 손해에 대하여 책임을 지지 않습니다.
                  <br />
                  3. 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여
                  책임을 지지 않습니다.
                  <br />
                  4. 회사는 회원이 서비스에 게재한 각종 정보, 자료, 사실의
                  신뢰도, 정확성 등 내용에 대하여 책임을 지지 않습니다.
                  <br />
                  5. 회사는 회원이 서비스를 이용하며 타 회원으로 인해 입게 되는
                  정신적 피해에 대하여 보상할 책임을 지지 않습니다.
                  <br />
                  6. 회사는 천재지변 등의 불가항력으로 인해 서비스를 제공할 수
                  없게 되어 발생한 손해에 대하여는 책임을 지지 않습니다.
                  <br />
                  7. 회사가 “제휴 서비스”를 제공함에 있어 제휴 사이트와의 계약에
                  의해 서비스를 제공하는 경우, 제휴 사이트가 제공하는 서비스로
                  인하여 발생하는 어떠한 손해에 대하여도 책임을 지지 않습니다.
                  <br />
                  8. 회사는 회원의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는
                  회원 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가
                  발생한 경우 책임을 지지 않습니다.
                  <br />
                  <br />
                  제 25 조 (준거법과 관할법원)
                  <br />
                  1.이 약관에 명시되지 않은 사항은 전기통신사업법 등 대한민국의
                  관계법령과 상관습에 따릅니다.
                  <br />
                  2. 회원과 회사 사이의 서비스 이용으로 발생한 분쟁에 대해
                  소송이 제기되는 경우 회사의 본사 소재지를 관할하는 법원을 관할
                  법원으로 합니다.
                  <br />
                  <br />
                  -부칙-
                  <br />본 이용약관은 2024 년 2 월 13 일부터 시행합니다
                </div>
              </td>
            </tr>
          </table>
          <div className="mt_32">
            <h3 className="mb_16">
              입금계좌<span className="ml_24">수강료 수납 입금</span>
            </h3>
            <span>법인 혹은 사업주 본인 명의의 계좌만 가능합니다.</span>
          </div>

          <table className="mt_32 desktop">
            <tr>
              <th>은행</th>
              <th>예금주</th>
              <th>계좌번호</th>
              <th></th>
            </tr>
            <tr>
              <td>
                <SelectBox />
              </td>
              <td>
                <input
                  type="text"
                  className="small"
                  {...attributes("bank_account_name")}
                />
              </td>
              <td>
                <input
                  type="text"
                  className="half"
                  {...attributes("bank_account")}
                  // placeholder="하이픈(-)을 포함하여 입력해 주세요."
                  data-format="number"
                />
              </td>
              <td>
                <div className="cell_content_wrap">
                  <label htmlFor="fileAccount">
                    <img
                      src={fileData[0] ? ic_file_on : ic_file_off}
                      alt=""
                      className="pointer"
                    />
                  </label>
                  <input
                    id="fileAccount"
                    type="file"
                    className="hidden"
                    onChange={uploadFile}
                  />
                  <h3>통장사본</h3>
                </div>
              </td>
            </tr>
          </table>

          <table className="mt_32 mobile">
            <colgroup>
              <col width={"60%"} />
            </colgroup>
            <tr>
              <th>은행</th>
              <th>예금주</th>
            </tr>
            <tr>
              <td>
                <SelectBox className="full" />
              </td>
              <td>
                <input
                  type="text"
                  className=""
                  {...attributes("bank_account_name")}
                />
              </td>
            </tr>

            <tr>
              <th>계좌번호</th>
              <th>통장사본</th>
            </tr>

            <tr>
              <td>
                <input
                  type="text"
                  className=""
                  {...attributes("bank_account")}
                  // placeholder="하이픈(-)을 포함하여 입력해 주세요."
                  data-format="number"
                />
              </td>
              <td>
                <div className="cell_content_wrap">
                  <label htmlFor="fileAccount">
                    <img
                      src={fileData[0] ? ic_file_on : ic_file_off}
                      alt=""
                      className="pointer"
                    />
                  </label>
                  <input
                    id="fileAccount"
                    type="file"
                    className="hidden"
                    onChange={uploadFile}
                  />
                </div>
              </td>
            </tr>
          </table>

          <div className="cms_wrap flex jc_sp_bw mt_32">
            <h3>
              CMS 출금계좌
              <br className="mobile" />
              <span className="ml_32 desktop">에듀빌리 서비스 이용료 결제</span>
              <span className="mobile">에듀빌리 서비스 이용료 결제</span>
            </h3>
            <div className="btn_group short">
              <button
                className={
                  "btn_default medium slim " +
                  (dataForm.is_cms ? "del" : "green3")
                }
                onClick={() => {
                  setDataForm({ ...dataForm, is_cms: !dataForm.is_cms });
                }}
              >
                간편 동의하기
              </button>
            </div>
          </div>

          <div className="btn_group center mt_52">
            <button
              className="btn_default half"
              onClick={async () => {
                if (!fileData[0])
                  return setMessage("통장 사본을 첨부해주세요.");
                if (!dataForm.is_cms)
                  return setMessage("간편 동의하기를 진행해주세요.");
                const uploader = localStorage.getItem("acadmey_id")
                  ? "manager"
                  : "teacher";
                const bank_path = fileData[0] ? await uploadS3(uploader) : [];
                const body = {
                  ...dataForm,
                  bank_name: selectValue.name,
                  bank_code: selectValue.code,
                  bank_path,
                };
                if (currentData.mode === "add") {
                  changeData(body);
                } else {
                  const res = await requestAPI(
                    "patch",
                    "academy/bank-account/update",
                    body
                  );
                  if (!res) return;
                  setMessage({
                    text: alertMsg.edit,
                    confirm: () => {
                      changeData({});
                    },
                  });
                }
              }}
              disabled={!dataForm?.bank_account}
            >
              등록하기
            </button>
          </div>
        </section>
      </div>
    </aside>
  );
}
