import useModalHandler from "hooks/useModalHandler";
import { useEffect } from "react";

export default function ContactUsPage() {
  const { ModalComponent, openModal } = useModalHandler();

  useEffect(() => {
    openModal("contactus", "page");
  }, []);

  return <ModalComponent />;
}
