import { useEffect, useState } from "react";
import useInputHandler from "hooks/useInputHandler";
import useDatePicker from "hooks/useDatePicker";
import useTimeInput from "hooks/useTimeInput";
import useRequestAPI from "hooks/useRequestAPI";
import Pagination from "components/Pagination";
import formatDate from "utils/formatDate";

export default function SelectTime({ closeModal, changeData, currentData }) {
  const [dataForm, setDataForm, attributes] = useInputHandler({
    week: [],
    room: "",
  });
  const { DatePickerModule, specificDate } = useDatePicker({
    specific: new Date(),
  });
  const [TimeInput, getTimeValue, timeValue] = useTimeInput();
  const { requestAPI, setMessage } = useRequestAPI();
  const [currPage, setCurrPage] = useState(0);
  const [resData, setResData] = useState();
  const day = ["월", "화", "수", "목", "금", "토", "일"];

  const submitData = () => {
    let copy = {
      ...dataForm,
      start_time: getTimeValue.start,
      end_time: getTimeValue.end,
    };
    copy.week =
      currentData.mode === "ga"
        ? day.filter((el) => dataForm.week.includes(el)).join(",")
        : formatDate(specificDate);
    if (!Object.values(copy).every((el) => el[0] || typeof el === "number")) {
      return setMessage("입력 항목을 모두 입력해주세요.");
    }
    changeData(copy);
  };

  const getData = async () => {
    if (currentData.mode === "ga" && !dataForm.week[0]) {
      return setMessage("반복 요일을 설정해주세요.");
    }
    // console.log(dataForm);
    let copy = {
      ...dataForm,
      start_time: getTimeValue.start,
      end_time: getTimeValue.end,
    };
    copy.week =
      currentData.mode === "ga"
        ? copy.week.join(",")
        : formatDate(specificDate);

    // console.log(copy);
    const class_room_time = [
      ...currentData.data.filter((el) => !el?.existing_data),
      {
        ...copy,
        start_time: getTimeValue.start,
        end_time: getTimeValue.end,
      },
    ];
    const body = {
      class_room_time: class_room_time,
      page: resData ? currPage : 0,
      size: 3,
    };
    const res = await requestAPI("post", "academy/class/all-room", body, false);
    if (!res) return setMessage("이미 선택된 시간과 중복되는 시간입니다.");
    // if (!res.data.data.class_time.content[0]) {
    //   return setMessage("등록된 강의실이 없습니다. 강의실 등록 후 조회해 주세요.");
    // }
    setResData(res.data.data);
  };

  useEffect(() => {
    setDataForm({ ...dataForm, room: "" });
    setResData();
  }, [dataForm.week, timeValue]);

  useEffect(() => {
    if (resData) getData();
  }, [currPage]);

  return (
    <aside id="selectTime" className="modal dim select">
      <div className="modal_content_wrap">
        <header>
          <h2>시간 설정</h2>
          <div className="btn_group">
            <button className="close btn_default" onClick={closeModal}>
              닫기
            </button>
          </div>
        </header>
        <section className="select_time_wrap">
          <table>
            {currentData.mode === "ga" ? (
              <>
                <tr>
                  <th>반복요일</th>
                </tr>
                <tr>
                  <td>
                    <ul>
                      {day.map((el, idx) => {
                        return (
                          <li key={idx}>
                            <input
                              type="checkbox"
                              className="mr_8"
                              id={"day_" + idx}
                              {...attributes("week", el)}
                            />
                            <label htmlFor={"day_" + idx}>{el}</label>
                          </li>
                        );
                      })}
                    </ul>
                  </td>
                </tr>
              </>
            ) : (
              <>
                <tr>
                  <th>날짜</th>
                </tr>
                <tr>
                  <td>
                    <DatePickerModule type="specific" />
                  </td>
                </tr>
              </>
            )}
          </table>
          <table>
            <tr>
              <th>시작시간</th>
              <th></th>
              <th>종료시간</th>
            </tr>
            <tr>
              <td>
                <TimeInput type="start" index={1} />
              </td>
              <td>~</td>
              <td>
                <TimeInput type="end" index={2} />
              </td>
            </tr>
          </table>
          <table className="clasroom_search_wrap fixed">
            <colgroup>
              <col width={"20%"} />
            </colgroup>
            <tr>
              <th>강의실</th>
              <td>
                <input
                  type="text"
                  className="small desktop"
                  value={dataForm.room}
                  readOnly
                />
                <input
                  type="text"
                  className="small mobile"
                  value={dataForm.room}
                  readOnly
                />
                <div className="btn_group short ml_16">
                  <button className="btn_default small" onClick={getData}>
                    조회
                  </button>
                </div>
              </td>
            </tr>
          </table>

          <section className="mt_24 table_scroll_wrap">
            <Pagination
              currPage={currPage}
              setCurrPage={setCurrPage}
              lastPage={resData?.class_time.totalPages || 0}
              changeURL={false}
            />
            {(!resData || !resData.class_time.content[0]) &&
              (dataForm.room ? (
                <p className="result">
                  {dataForm.room} 강의실이 선택되었습니다.
                </p>
              ) : (
                <p className="result">조회된 강의실이 없습니다.</p>
              ))}
            {resData && resData.class_time.content[0] && (
              <table className="detail mt_24">
                <thead>
                  <tr>
                    <th>강의실</th>
                    <th>선택</th>
                  </tr>
                </thead>
                <tbody>
                  {resData.class_time.content.map((el, idx) => (
                    <tr key={idx}>
                      <td>{el.class_room_name}</td>
                      <td>
                        <div className="btn_group">
                          {el.is_valid ? (
                            <span className="invalid">시간중복</span>
                          ) : (
                            <button
                              className="btn_default slim green3 small"
                              onClick={() => {
                                // console.log("?");
                                setDataForm({
                                  ...dataForm,
                                  room: el.class_room_name,
                                  room_id: el.class_room_id,
                                  capacity: el.class_room_capacity,
                                });
                                setCurrPage(0);
                                setResData();
                              }}
                            >
                              선택
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </section>
        </section>
        <div className="btn_group mt_52">
          <button className="btn_default" onClick={submitData}>
            등록
          </button>
        </div>
      </div>
    </aside>
  );
}
