import { useRecoilState } from "recoil";
import { messageAtom } from "atoms/atoms";
import alertMsg from "variable/alertMsg";

export default function Message() {
  const [message, setMessage] = useRecoilState(messageAtom);
  const text = typeof message === "string" ? message : message?.text;
  // console.log(text);
  const icon =
    message?.type === "confirm"
      ? "confirm"
      : [
          "add",
          "edit",
          "change",
          "delete",
          "invite",
          "apply",
          "end",
          "class_time_delete",
          "class_time_delete_last",
          "find_pw_email",
          "logout",
          "payment_request",
          "create",
          "change_pw",
        ].some((el) => text?.includes(alertMsg[el]))
      ? "complete"
      : "alert";

  if (message)
    return (
      <aside id="alert" className="modal dim">
        <div className="modal_content_wrap">
          <h1 className={"center " + icon}>
            <strong>{{ alert: "!", confirm: "?", complete: "✓" }[icon]}</strong>
          </h1>
          <p className="text fs_20 center">
            <span>
              {text.split("\n").map((el) => (
                <>
                  {el}
                  <br />
                </>
              ))}
            </span>
          </p>
          <div className="btn_group center">
            {message.type === "confirm" && (
              <button
                className="btn_default del medium"
                onClick={() => {
                  if (message?.cancel) message.cancel();
                  setMessage(null);
                }}
              >
                취소
              </button>
            )}
            <button
              className="btn_default medium"
              onClick={() => {
                if (message?.confirm) message.confirm();
                if (!message?.next) setMessage(null);
              }}
            >
              확인
            </button>
          </div>
        </div>
      </aside>
    );
}
