import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSelectBox from "hooks/useSelectBox";
import useRequestAPI from "hooks/useRequestAPI";
import Pagination from "components/Pagination";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import formatExcelTitle from "utils/formatExcelTitle";
import formatDate from "utils/formatDate";

export default function AcademyEvaluation() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [SelectBox, selectValue] = useSelectBox([
    { name: "전체", code: "all" },
    { name: "선생님", code: "teacher_name" },
    { name: "수업명", code: "class_name" },
  ]);

  const [McBoardContent, selectMcBoardContent] = useSelectBox([
    { name: "수업평점", code: "total_rating" },
    { name: "수업명", code: "class_name" },
    { name: "선생님 평점", code: "owner_rating" },
    { name: "최근평가일", code: "latest_date" },
  ]);

  const listDataFormat = (type, data) => {
    switch (type) {
      case "latest_date": {
        return data.latest_date?.slice(0, -3).replace(/-/g, ".");
      }
      default: {
        return data[type];
      }
    }
  };

  const { requestAPI, Loading } = useRequestAPI();
  const [searchKeyword, setSearchKeword] = useState("");
  const [currPage, setCurrPage] = useState(id - 1);
  const [resData, setResData] = useState();
  const [currSearchData, setCurrSearchData] = useState({
    page: currPage,
    size: 5,
    search_type: "all",
    search_keyword: "",
    target_month: formatDate(new Date()).slice(0, -3),
  });

  const getData = async (e) => {
    e?.preventDefault();
    let body = { ...currSearchData, page: currPage };
    if (e?.target.tagName === "BUTTON") {
      body = {
        page: currPage,
        size: 5,
        search_type: selectValue?.code,
        search_keyword: searchKeyword,
        target_month: formatDate(new Date()).slice(0, -3),
      };

      setCurrSearchData(body);
      return setCurrPage(0);
    }
    const res = await requestAPI("post", "academy/class/rating", body);
    if (!res) return;
    setResData(res.data.data);
  };

  useEffect(() => {
    getData();
  }, [currPage, currSearchData]);

  return (
    <div className="inner">
      <Loading />
      <section className="page_handling_wrap">
        <form className="search_wrap">
          <SelectBox />
          <input
            type="text"
            onChange={(e) => {
              setSearchKeword(e.target.value);
            }}
          />
          <div className="btn_group">
            <button className="btn_default search" onClick={getData}>
              검색
            </button>
          </div>
        </form>
        <Pagination
          currPage={currPage}
          setCurrPage={setCurrPage}
          lastPage={resData?.class_ratings.totalPages || 0}
        />
      </section>

      <section className="board_wrap">
        <header>
          <h2>평가 목록</h2>
          <div className="btn_group">
            <ReactHTMLTableToExcel
              className="btn_default excel"
              table="table"
              filename={formatExcelTitle("평가목록")}
              sheet="tableSheet"
              buttonText="엑셀다운로드"
            />
          </div>
        </header>
        <table id="table">
          <colgroup className="mobile">
            <col width={"30%"} />
            <col width={"auto"} />
            <col width={"25%"} />
          </colgroup>
          <colgroup className="desktop">
            <col width={"10%"} />
            <col width={"15%"} />
            <col width={"10%"} />
            <col width={"15%"} />
            <col width={"10%"} />
            <col width={"auto"} />
            <col width={"10%"} />
          </colgroup>
          <thead>
            <tr className="mobile">
              <th>담당선생님</th>
              <th>
                <McBoardContent />
              </th>
              <th>평가</th>
            </tr>
            <tr className="desktop">
              {[
                "번호",
                "수업명",
                "수업 평점",
                "담당 선생님",
                "선생님 평점",
                "최근 평가일",
                "평가",
              ].map((item) => (
                <th className="hide_text">{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {resData && !resData.class_ratings.content[0] && (
              <tr>
                <td colSpan={7} className="desktop">
                  표시할 데이터가 없습니다.
                  <br /> 평가 결과 산출을 위해 데이터 수집 기간이 필요합니다.
                </td>
                <td colSpan={3} className="mobile">
                  표시할 데이터가 없습니다.
                  <br /> 평가 결과 산출을 위해 데이터 수집 기간이 필요합니다.
                </td>
              </tr>
            )}

            {resData &&
              resData.class_ratings.content.map((el, idx) => (
                <tr key={idx}>
                  <td className="desktop">
                    {resData.class_ratings.totalElements - currPage * 5 - idx}
                  </td>
                  <td className="desktop">
                    {listDataFormat("class_name", el)}
                  </td>
                  <td className="desktop">
                    {listDataFormat("total_rating", el)}
                  </td>
                  <td>{listDataFormat("teacher_name", el)}</td>
                  {/* 모바일 */}
                  <td className="mobile">
                    {listDataFormat(selectMcBoardContent.code, el)}
                  </td>
                  {/* 모바일 */}
                  <td className="desktop">
                    {listDataFormat("owner_rating", el)}
                  </td>
                  <td className="desktop">
                    {listDataFormat("latest_date", el)}
                  </td>
                  <td>
                    <div className="btn_group">
                      <button
                        className="btn_default slim green3"
                        onClick={() => {
                          navigate(
                            "/academy/evaluation/detail/" + el.rating_id,
                            { state: { owner_rating: el.owner_rating } }
                          );
                        }}
                      >
                        평가
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </section>
    </div>
  );
}
