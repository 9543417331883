import { useEffect, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import useRequestAPI from "hooks/useRequestAPI";

export default function useFindPostAddr(isPosition = false) {
  const { requestAPI, setMessage } = useRequestAPI();
  const [userAddr, setUserAddr] = useState({
    zonecode: "",
    addr: "",
    detailAddr: "",
  });
  const [position, setPosition] = useState({
    latitude: "",
    longitude: "",
  });
  const [findPost, setFindPost] = useState(false);

  const handleComplete = async (data) => {
    let zonecode = data.zonecode;
    let addr = data.address;
    let extraAddress = "";
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      addr += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setUserAddr({ zonecode, addr, detailAddr: "" });
    setFindPost(false);
  };

  const detailAddr = (e) => {
    let copy = { ...userAddr };
    copy.detailAddr = e.target.value;
    setUserAddr(copy);
  };

  const openDaumPost = () => {
    setFindPost(true);
  };

  const getAddressInfo = async (addr) => {
    const res = await requestAPI("post", "common/coordinate", {
      address: addr,
    });
    if (!res) {
      setMessage("위치 정보 조회를 실패하였습니다. 주소를 다시 입력해주세요.");
      setUserAddr({
        zonecode: "",
        addr: "",
        detailAddr: "",
      });
      setPosition({ latitude: "", longitude: "" });
      return;
    }
    const { latitude, longitude } = res.data.data;
    setPosition({ latitude, longitude });
  };

  function DaumPost() {
    if (findPost)
      return (
        <div className="post_wrap">
          <button
            className="btn_close"
            onClick={(e) => {
              e.preventDefault();
              setFindPost(false);
            }}
          ></button>
          <DaumPostcode
            className="find_post"
            onComplete={handleComplete}
            //   {...props}
          />
        </div>
      );
  }

  useEffect(() => {
    if (isPosition && userAddr.addr) getAddressInfo(userAddr.addr);
  }, [userAddr]);

  return {
    DaumPost,
    userAddr,
    setUserAddr,
    detailAddr,
    openDaumPost,
    position,
  };
}
