export default function ToggleButton({ index, checked, dataName, onChange }) {
  return (
    <label htmlFor={"btnToggle" + index} className="btn_toggle">
      <input
        type="checkbox"
        id={"btnToggle" + index}
        data-name={dataName}
        onChange={onChange}
        checked={checked}
      />
      <span
        className={"btn_toggle_handler_wrap " + (checked && "toggle_checked")}
      >
        <span className="btn_toggle_handler"></span>
      </span>
    </label>
  );
}
