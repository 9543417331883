export default function formatPriceKR(number) {
  const unit1 = ["", "만", "억", "조", "경", "해"];
  const unit2 = ["", "십", "백", "천"];

  const numberStr = String(number);
  const length = numberStr.length;

  let result = "";

  for (let i = 0; i < length; i++) {
    const digit = Number(numberStr.charAt(i));

    if (digit !== 0) {
      result += `${getKoreanDigit(digit)}${unit2[(length - i - 1) % 4]}`;
    }

    if ((length - i - 1) % 4 === 0 && i !== length - 1) {
      result += unit1[(length - i - 1) / 4];
    }
  }

  return result + "원";
}

function getKoreanDigit(digit) {
  const digits = ["영", "일", "이", "삼", "사", "오", "육", "칠", "팔", "구"];
  return digits[digit];
}
