import { atom } from "recoil";

export const headerTitleAtom = atom({
  key: "headerTitleAtom",
  default: "",
});

export const messageAtom = atom({
  key: "messageAtom",
  default: null,
});

export const isLoginAtom = atom({
  key: "isLoginAtom",
  default: false,
});

export const isApiLoadingAtom = atom({
  key: "isApiLoadingAtom",
  default: false,
});

export const joinDataAtom = atom({
  key: "joinDataAtom",
  default: {
    mb_ci: "",
    mb_name: "",
    mb_birth: "",
    mb_ph: "",
    mb_gender: "",
    mb_email: "",
    mb_password: "",
  },
});

export const ownerDataAtom = atom({
  key: "ownerDataAtom",
  default: {
    mb_id: "",
    owner_name: "",
    opening_date: "",
    business_registration: "",
    business_name: "",
    business_address: "",
    education_field: "",
  },
});
