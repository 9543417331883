import useValidation from "hooks/useValidation";
import show_pw from "assets/img/icon/show_pw.png";
import useRequestAPI from "hooks/useRequestAPI";
import { useEffect } from "react";

export default function ChangePw() {
  const {
    form,
    isShowPw,
    validPass,
    errorCheck,
    visibilityPw,
    validAttributes,
  } = useValidation();
  const { requestAPI, logout, alertMsg, setMessage } = useRequestAPI();

  const submitData = async () => {
    const body = {
      teacher_password: form.mb_password.val,
      teacher_confirm_password: form.mb_confirm_password.val,
    };
    const res = await requestAPI("patch", "teacher/change-pw", body);
    if (!res) return;
    setMessage({ text: alertMsg.change_pw, confirm: logout });
  };

  useEffect(() => {
    return () => {
      logout();
    };
  }, []);

  return (
    <main id="changePw" className="container nav_none align_center">
      <div className="inner pt_0">
        <section>
          <header>
            <h1>비밀번호 변경</h1>
          </header>
          <section className="input_group">
            <div className="input_item_group">
              <dl>
                <dt>비밀번호</dt>
                <dd className="pw">
                  <input
                    type={isShowPw}
                    className="input"
                    {...validAttributes("mb_password")}
                  />
                  <img
                    src={show_pw}
                    alt=""
                    className="show_pw"
                    onClick={visibilityPw}
                  />
                </dd>
                <dd>{errorCheck("mb_password")?.alert}</dd>
              </dl>
            </div>
            <div className="input_item_group">
              <dl>
                <dt>비밀번호 확인</dt>
                <dd>
                  <input
                    type={isShowPw}
                    className="input"
                    {...validAttributes("mb_confirm_password")}
                  />
                </dd>
                <dd>{errorCheck("mb_confirm_password")?.alert}</dd>
              </dl>
            </div>
            <div className="btn_group">
              <button
                className="btn_default"
                onClick={submitData}
                disabled={!validPass(["mb_password", "mb_confirm_password"])}
              >
                변경
              </button>
            </div>
          </section>
        </section>
      </div>
    </main>
  );
}
