import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import useSelectBox from "hooks/useSelectBox";
import useRequestAPI from "hooks/useRequestAPI";
import Pagination from "components/Pagination";

export default function StudentSelectClass() {
  const navigate = useNavigate();
  const { selectClass, deleteClass, classList } = useOutletContext();
  const [SelectBox, selectValue] = useSelectBox([
    { name: "전체", code: "all" },
  ]);
  const { requestAPI, Loading } = useRequestAPI();

  const [searchKeyword, setSeachKeword] = useState("");
  const [currPage, setCurrPage] = useState(0);
  const [resData, setResData] = useState();
  const [currSearchData, setCurrSearchData] = useState({
    page: currPage,
    size: 5,
    search_type: "all",
    search_keyword: "",
  });

  const [McBoardContent, selectMcBoardContent] = useSelectBox([
    { name: "레벨", code: "level" },
    { name: "일정", code: "class_schedule" },
    { name: "수업시간", code: "class_time" },
    { name: "선생님", code: "mna_teacher_name" },
    { name: "강의실", code: "class_room" },
    { name: "과목", code: "class_field" },
    { name: "정원", code: "class_capacity" },
    { name: "메모	", code: "memo" },
  ]);

  const listDataFormat = (type, data, idx) => {
    switch (type) {
      case "class_schedule": {
        return data.class_time.map((el, idx) => {
          return (
            <>
              {el.week}
              <br key={idx} />
            </>
          );
        });
      }
      case "class_time": {
        return data.class_time.map((el, idx) => {
          return (
            <>
              {el.start_time.slice(0, -3)}~{el.end_time.slice(0, -3)}
              <br key={idx} />
            </>
          );
        });
      }
      case "class_room": {
        return data.class_time.map((el, idx) => {
          return (
            <>
              {el.room_name}
              <br key={idx} />
            </>
          );
        });
      }
      case "class_capacity": {
        return data.student_cnt + "/" + data.class_capacity;
      }
      case "select": {
        return (
          <div className="cell_content_wrap center">
            {data.student_cnt >= data.class_capacity ? (
              "정원완료"
            ) : classList.find((el2) => data.class_id === el2.class_id) ? (
              <div className="btn_group center">
                <button
                  className="btn_default slim small del"
                  onClick={() => {
                    deleteClass(data);
                  }}
                >
                  삭제
                </button>
              </div>
            ) : (
              <div className="btn_group center">
                <button
                  className="btn_default slim small green3"
                  onClick={() => {
                    selectClass(data);
                  }}
                >
                  선택
                </button>
              </div>
            )}
          </div>
        );
      }
      default: {
        return data[type];
      }
    }
  };

  const day = ["월", "화", "수", "목", "금", "토", "일"];

  const getData = async (e) => {
    e?.preventDefault();
    let body = { ...currSearchData, page: currPage };
    if (e?.target.tagName === "BUTTON") {
      body = {
        page: currPage,
        size: 5,
        search_type: selectValue?.code,
        search_keyword: searchKeyword,
      };
      setCurrSearchData(body);
      return setCurrPage(0);
    }
    const res = await requestAPI("post", "student/select-classes", body);
    if (!res) return;
    let copy = { ...res.data.data.classes };
    copy.content = copy.content.map((el) => {
      return {
        ...el,
        class_time: JSON.parse(el.class_time)
          .sort((a, b) => {
            return (
              day.indexOf(a.week.slice(0, 1)) - day.indexOf(b.week.slice(0, 1))
            );
          })
          .map((el) => {
            return {
              ...el,
              week: el.week
                .split(",")
                .sort((a, b) => {
                  return day.indexOf(a) - day.indexOf(b);
                })
                .join(","),
            };
          }),
        shuttle_class_list: JSON.parse(el.shuttle_class_list),
      };
    });
    setResData(copy);
  };

  useEffect(() => {
    getData();
  }, [currPage, currSearchData]);

  return (
    <div id="student" className="container background_white">
      <div className="inner">
        <Loading />
        <section className="page_handling_wrap">
          <form className="search_wrap">
            <SelectBox />
            <input
              type="text"
              onChange={(e) => {
                setSeachKeword(e.target.value);
              }}
            />
            <div className="btn_group">
              <button className="btn_default search" onClick={getData}>
                검색
              </button>
            </div>
          </form>
          <Pagination
            currPage={currPage}
            setCurrPage={setCurrPage}
            lastPage={resData?.totalPages || 0}
            changeURL={false}
          />
        </section>

        <section className="page_content_wrap mt_24">
          <header>
            <h2>수업선택</h2>
            <div className="btn_group">
              <button
                className="btn_default"
                onClick={() => {
                  navigate(-1);
                }}
              >
                선택완료
              </button>
            </div>
          </header>
          <table className="detail fixed border_row outline_none align_c mt_44">
            <colgroup className="desktop">
              <col width={"20%"} />
              <col width={"7%"} />
              <col width={"20%"} />
              <col width={"auto"} />
              <col width={"10%"} />
              <col width={"13%"} />
              <col width={"10%"} />
              <col width={"10%"} />
              <col width={"10%"} />
              <col width={"auto"} />
            </colgroup>
            <colgroup className="mobile">
              <col width={"35%"} />
              <col width={"auto"} />
              <col width={"30%"} />
            </colgroup>
            <thead>
              <tr className="mobile">
                <th>수업명</th>
                <th>
                  <McBoardContent />
                </th>
                <th>선택</th>
              </tr>
              <tr className="desktop">
                <th>수업명</th>
                <th>레벨</th>
                <th>일정</th>
                <th>수업시간</th>
                <th>선생님</th>
                <th>강의실</th>
                <th>과목</th>
                <th>정원</th>
                <th>메모</th>
                <th>시간선택</th>
              </tr>
            </thead>
            <tbody>
              {resData && !resData?.content[0] && (
                <tr>
                  <td colSpan={10}>
                    학원정보관리에서 시간표를 우선 설정하세요.
                  </td>
                </tr>
              )}
              {resData?.content.map((el, idx) => (
                <tr
                  key={idx}
                  className={
                    (el.student_cnt === el.class_capacity && "capacity_full ") +
                    (classList.find((el2) => el.class_id === el2.class_id) &&
                      "select")
                  }
                >
                  <td>{listDataFormat("class_name", el)}</td>
                  {/* 모바일 */}
                  <td className="mobile">
                    {listDataFormat(selectMcBoardContent.code, el)}
                  </td>
                  {/* 모바일 */}
                  <td className="desktop">{listDataFormat("level", el)}</td>
                  <td className="desktop">
                    {listDataFormat("class_schedule", el)}
                  </td>
                  <td className="desktop">
                    {listDataFormat("class_time", el)}
                  </td>
                  <td className="desktop">
                    {listDataFormat("mna_teacher_name", el)}
                  </td>
                  <td className="desktop">
                    {listDataFormat("class_room", el)}
                  </td>
                  <td className="desktop">
                    {listDataFormat("class_field", el)}
                  </td>
                  <td className="desktop">
                    {listDataFormat("class_capacity", el)}
                  </td>
                  <td className="hide_text desktop">
                    {listDataFormat("memo", el)}
                  </td>
                  <td>{listDataFormat("select", el)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </div>
    </div>
  );
}
