import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { CookiesProvider } from "react-cookie";
// import { QueryClient, QueryClientProvider } from "react-query";
import ErrorBoundary from "routes/errorBoundary/ErrorBoundary";

import "./App.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
// const queryClient = new QueryClient();
root.render(
  // <React.StrictMode>
  // <QueryClientProvider client={queryClient}>
  <RecoilRoot>
    <CookiesProvider>
      <BrowserRouter>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </CookiesProvider>
  </RecoilRoot>
  // </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
