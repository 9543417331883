import { useEffect, useState } from "react";
import useRequestAPI from "hooks/useRequestAPI";
import Pagination from "components/Pagination";

export default function SelectClassShuttle({
  closeModal,
  changeData,
  currentData,
}) {
  const { requestAPI } = useRequestAPI();
  const [currPage, setCurrPage] = useState(0);
  const [resData, setResData] = useState();

  const getData = async () => {
    const classData = currentData.data.classData;
    const timeData = currentData.data.timeData;
    const body = {
      class_id: classData.class_id,
      direction: { riding: 0, quit: 1 }[currentData.mode],
      class_dates: timeData.week,
      class_time: timeData.start_time,
      class_end_time: timeData.end_time,
      class_start_date: classData.start_date,
      latitude: classData.position.latitude,
      longitude: classData.position.longitude,
      page: currPage,
      size: 5,
    };
    const res = await requestAPI("post", "student/select-bus", body);
    if (!res) return;
    setResData(res.data.data.bus_list);
  };

  useEffect(() => {
    getData();
  }, [currPage]);

  // console.log(currentData);

  return (
    <aside id="selectClassShuttle" className="modal dim select">
      <div className="modal_content_wrap">
        <header>
          <h2>셔틀버스 선택</h2>
          <div className="btn_group">
            <button className="close btn_default" onClick={closeModal}>
              닫기
            </button>
          </div>
        </header>
        {/* {resData && resData.content[0] && ( */}
        <section className="table_scroll_wrap">
          <Pagination
            currPage={currPage}
            setCurrPage={setCurrPage}
            lastPage={resData?.totalPages || 0}
            changeURL={false}
          />
          {resData && !resData.content[0] && (
            <p className="flex">
              해당 수업시간에 이용할 수 있는{" "}
              {{ riding: "등원", quit: "하원" }[currentData.mode]} 차량이
              없습니다.
            </p>
          )}
          {resData && resData.content[0] && (
            <table className="detail fixed">
              <colgroup className="mobile">
                <col width={"40%"} />
                <col width={"auto"} />
                <col width={"auto"} />
              </colgroup>
              <colgroup className="desktop">
                <col width={"268px"} />
                <col width={"auto"} />
                <col width={"auto"} />
              </colgroup>
              <thead>
                <tr className="desc">
                  <td colSpan={3}>
                    <span className="desc">
                      ※ 학생 집주소와 가까운 운행노선 순으로 정렬된 셔틀버스
                      목록입니다.
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>셔틀버스</th>
                  <th>유형</th>
                  <th>선택</th>
                </tr>
              </thead>
              <tbody>
                {resData.content.map((el, idx) => (
                  <tr key={idx}>
                    <td>
                      {el.bus_name}
                      <br />
                      <span>
                        (
                        {currentData.mode === "riding"
                          ? "도착시간 : " + el.arrival_time.slice(0, -3)
                          : "출발시간 : " + el.departure_time.slice(0, -3)}
                        )
                      </span>
                    </td>
                    <td>{["정기", "임시"][el.schedule_type]}</td>
                    <td>
                      <div className="btn_group">
                        <button
                          className="btn_default slim small green3"
                          onClick={() => {
                            changeData({
                              idx: currentData.data.idx,
                              mode: currentData.mode,
                              detail: {
                                ...el,
                                bus_stop_list: JSON.parse(
                                  el.bus_stop_list
                                ).sort((a, b) => {
                                  return a.order - b.order;
                                }),
                              },
                            });
                          }}
                        >
                          선택
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </section>
        {/* )} */}
      </div>
    </aside>
  );
}
