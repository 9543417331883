import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { isLoginAtom, messageAtom } from "atoms/atoms";
import { url } from "variable/url";
import alertMsg from "variable/alertMsg";
import ic_loading from "assets/img/icon/ic_loading.gif";
axios.defaults.withCredentials = true;

export default function useRequestAPI() {
  const setMessage = useSetRecoilState(messageAtom);
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const [isDataLoad, setIsDataLoad] = useState(true);
  const navigate = useNavigate();

  const requestAPI = async (
    type,
    apiPath,
    body,
    isAlert = true,
    isLoading = true
  ) => {
    if (!isDataLoad) return setMessage("요청 작업을 처리중입니다."); // api 호출 응답 전
    if (isLoading) setIsDataLoad(false); // loading 이미지를 띄우도록 설정했다면 loading 이미지 출력 조건 true

    const apiUrl = url.server + apiPath; // 로그인 시 api 호출에 필요한 기본적인 인증 정보들을 localstorage에 저장한다.
    body.teacher_id = localStorage.getItem("teacher_id");
    const academyId = localStorage.getItem("academy_id");
    const managerId = localStorage.getItem("manager_id");
    if (academyId && managerId) {
      body.academy_id = academyId;
      body.manager_id = managerId;
    }

    try {
      let res;
      if (type === "post" || type === "put" || type === "patch") {
        res = await axios[type](apiUrl, body);
      } else if (type === "delete") {
        res = await axios[type](apiUrl, {
          data: body,
        });
      }
      if (res.data.status !== 200) {
        setMessage(alertMsg.error);
        return false;
      }
      setIsLogin(true);
      return res;
    } catch (err) {
      if (err.response?.status === 401) {
        // 401은 토큰 만료 에러로, 발생 시 로그아웃 시킨다.
        setIsLogin(false);
        return setMessage({
          text: "만료된 요청입니다.",
          confirm: () => {
            navigate("/login"); //login 페이지 가면 자동으로 로그아웃 됨
          },
        });
      }
      let res = err?.response?.data.message || alertMsg.error;
      if (res.length > 100) res = alertMsg.error;
      if (isAlert) setMessage(res);
      return false;
    } finally {
      setIsDataLoad(true);
    }
  };

  const logout = async () => {
    try {
      await axios.put(url.server + "teacher/logout");
      setIsLogin(false);
      localStorage.clear();
      return navigate("/login");
    } catch {
      console.log("이미 로그아웃 상태입니다.");
    }
  };

  const Loading = () => {
    // loading 컴포넌트
    if (!isDataLoad)
      return (
        <div className="loading_wrap">
          <img src={ic_loading} alt="" />
        </div>
      );
  };

  return {
    requestAPI,
    isLogin,
    isDataLoad,
    Loading,
    logout,
    alertMsg,
    setMessage,
  };
}
