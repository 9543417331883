export default function formatExcelTitle(type) {
  const name = localStorage.getItem("academy_name", true);
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 1을 더하고, 두 자리로 패딩합니다.
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const time = `${year}${month}${day}${hours}${minutes}`;
  return `${name}_${type}_${time}`;
}
