import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import IntroDesktop from "routes/intro/IntroDesktop";
import IntroMobile from "routes/intro/IntroMobile";
import createChannelIO from "utils/createChannelIO";
import bootChannelIO from "utils/bootChannelIO";

export default function Intro() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/qna") {
      createChannelIO();
      bootChannelIO();
      ChannelIO("showMessenger");
    }
  }, []);

  return (
    <>
      <IntroDesktop />
      <IntroMobile />
    </>
  );
}
