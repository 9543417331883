import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import useInputHandler from "hooks/useInputHandler";
import useSelectBox from "hooks/useSelectBox";
import useFindPostAddr from "hooks/useFindPostAddr";
import useDatePicker from "hooks/useDatePicker";
import useRequestAPI from "hooks/useRequestAPI";
import ReturnToList from "components/ReturnToList";
import useValidation from "hooks/useValidation";
import formatDate from "utils/formatDate";

export default function StudentEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const { pathname } = useLocation();
  const [dataForm, setDataForm, attributes] = useInputHandler();
  const [studentPhoneNumber, setStudentPhoneNumber] = useState("");
  const [existingDataForm, setExistingDataForm] = useState();
  const [deleteClassList, setDeleteClassList] = useState([]);
  const [SelectBox, selectValue, setSelectValue] = useSelectBox([
    { name: "재학", code: "status_1" },
    { name: "휴학", code: "status_2" },
  ]);
  const { DatePickerModule, specificDate } = useDatePicker({
    specific: state?.data ? new Date(state.data.student_birth) : null,
  });
  const { DaumPost, userAddr, setUserAddr, openDaumPost, position } =
    useFindPostAddr(true);
  const { form, setForm, validPass, errorCheck, validAttributes } =
    useValidation();
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();

  const [McBoardContent, selectMcBoardContent] = useSelectBox([
    { name: "레벨", code: "level" },
    { name: "일정", code: "class_schedule" },
    { name: "수업시간", code: "class_time" },
    { name: "선생님", code: "mna_teacher_name" },
    { name: "수업장소", code: "class_room" },
    { name: "과목", code: "class_field" },
    { name: "수강료 납입일", code: "payment_date" },
  ]);

  const listDataFormat = (type, data, idx) => {
    switch (type) {
      case "class_schedule": {
        return data.class_time.map((el) => {
          return (
            <>
              {el.week}
              <br />
            </>
          );
        });
      }
      case "class_time": {
        return data.class_time.map((el, idx) => {
          return (
            <>
              {el.start_time.slice(0, -3)}~{el.end_time.slice(0, -3)}
              <br key={idx} />
            </>
          );
        });
      }
      case "class_room": {
        return data.class_time.map((el, idx) => {
          return (
            <>
              {el.room_name}
              <br key={idx} />
            </>
          );
        });
      }
      case "class_shuttle": {
        return (
          <div className="btn_group flex jc_c">
            <button
              className={
                data.shuttle_bus_stop_ids &&
                data.shuttle_bus_stop_ids.some((el) => {
                  return el.riding?.stop_id || el.quit?.stop_id;
                })
                  ? "btn_default slim del"
                  : "btn_default slim green3"
              }
              onClick={() => {
                if (!userAddr.addr) {
                  return setMessage("학생 집주소를 먼저 입력해 주세요.");
                }
                if (!position.latitude || !position.longitude) {
                  return setMessage(
                    "위치정보를 불러오는 중입니다. 잠시 후 다시 시도해 주세요."
                  );
                }
                navigate("select-shuttle", {
                  state: {
                    data: {
                      ...data,
                      idx,
                      position,
                      type: "신청",
                    },
                  },
                });
              }}
            >
              {data.shuttle_bus_stop_ids &&
              data.shuttle_bus_stop_ids.some((el) => {
                return el.riding?.stop_id || el.quit?.stop_id;
              })
                ? "상세"
                : "신청"}
            </button>
          </div>
        );
      }
      case "payment_date": {
        return (
          <div className="cell_content_wrap">
            <input
              type="number"
              value={data.payment_date}
              data-idx={idx}
              onChange={setPaymentDate}
            />
          </div>
        );
      }
      case "class_delete": {
        return (
          <div className="btn_group flex jc_c">
            <button
              className="btn_default slim del"
              onClick={() => {
                deleteClass(data);
              }}
            >
              삭제
            </button>
          </div>
        );
      }
      default: {
        return data[type];
      }
    }
  };

  const deleteData = () => {
    setMessage({
      type: "confirm",
      text: "삭제하시겠습니까?",
      confirm: async () => {
        const res = await requestAPI("delete", "student/delete", {
          student_id: id,
        });
        if (!res) return;
        setMessage({
          text: alertMsg.delete,
          confirm: () => navigate("/student/1"),
        });
      },
    });
  };

  const setPaymentDate = (e) => {
    let val = e.target.value.replace(/[^0-9]/g, "");
    if (val > 31) return (val = 31);
    if (val < 1) return (val = 1);
    let copy = { ...dataForm };
    copy.class_list[e.target.dataset.idx].payment_date = val;
    setDataForm(copy);
  };

  const submitData = async () => {
    let copy = JSON.parse(JSON.stringify(dataForm));
    copy.student_name = form.mb_name.val;
    copy.parent_phone_number = form.mb_ph.val;
    copy.student_status = selectValue.code;
    copy.student_birth = formatDate(specificDate);
    copy.student_phone_number = studentPhoneNumber.val;
    copy.latitude = position.latitude;
    copy.longitude = position.longitude;
    copy.postal_code = userAddr.zonecode;
    copy.address = userAddr.addr;
    copy.delete_class_list = deleteClassList;

    copy.class_list = copy.class_list.map((el) => {
      let existingStopData = existingDataForm.class_list.find(
        (find_el) => el.class_id === find_el.class_id
      );
      existingStopData = existingStopData?.shuttle_bus_stop_ids;
      if (el.shuttle_bus_stop_ids) {
        el.shuttle_bus_stop_ids = el.shuttle_bus_stop_ids.map((item) => {
          const origin = existingStopData?.find(
            (target) => target.class_time_id === item.class_time_id
          );
          const stop_ids = [];
          const new_stop_ids = [];
          const delete_stop_ids = [];
          if (origin?.riding?.stop_id) stop_ids.push(origin.riding.stop_id);
          if (origin?.quit?.stop_id) stop_ids.push(origin.quit.stop_id);
          if (item.riding?.stop_id) {
            if (!origin || !origin.riding) {
              new_stop_ids.push(item.riding.stop_id);
            } else if (item.riding.stop_id !== origin.riding.stop_id) {
              new_stop_ids.push(item.riding.stop_id);
              delete_stop_ids.push(origin.riding.stop_id);
            }
          } else if (origin?.riding?.stop_id) {
            delete_stop_ids.push(origin.riding.stop_id);
          }
          if (item.quit?.stop_id) {
            if (!origin || !origin.quit) {
              new_stop_ids.push(item.quit.stop_id);
            } else if (item.quit.stop_id !== origin.quit.stop_id) {
              new_stop_ids.push(item.quit.stop_id);
              delete_stop_ids.push(origin.quit.stop_id);
            }
          } else if (origin?.quit?.stop_id) {
            delete_stop_ids.push(origin.quit.stop_id);
          }
          return {
            classtime_id: item.class_time_id,
            stop_ids,
            new_stop_ids,
            delete_stop_ids,
          };
        });
      }
      // el.classtime_ids = el.class_time.map((el) => el.class_time_id);
      el.classtime_ids = el.shuttle_class_list.map((el) => el.class_time_id);
      return el;
    });
    const res = await requestAPI("patch", "student/update", copy);
    if (!res) return;
    setMessage({ text: alertMsg.edit, confirm: () => navigate("/student/1") });
  };

  const submitInviteData = async () => {
    const res = await requestAPI("post", "milivily/member/send-sms", {
      phone_number: form.mb_ph.val,
      student_name: form.mb_name.val,
      student_birth: formatDate(specificDate),
    });
    if (!res) return;
    setMessage(alertMsg.invite);
  };

  const selectClass = (data) => {
    let copy = { ...dataForm };
    // console.log(data);
    copy.class_list.push({ ...data, payment_date: 1 });
    setDataForm(copy);
  };

  const deleteClass = (data) => {
    let copy = { ...dataForm };
    const targetIdx = copy.class_list.findIndex(
      (el) => el.class_id === data.class_id
    );
    copy.class_list.splice(targetIdx, 1);
    // console.log(data);
    setDataForm(copy);
    if (data.existing_data) {
      setDeleteClassList([...deleteClassList, data.classhistory_id]);
    }
  };

  const selectShuttle = (data) => {
    let copy = { ...dataForm };
    const target = copy.class_list[data.targetIdx];
    target.shuttle_bus_stop_ids = data.shuttleData;
    setDataForm(copy);
  };

  useEffect(() => {
    const newClassList = state.data.class_list.map((el) => {
      const newClassTime = el.shuttle_class_list.reduce((acc, el2) => {
        const existingClass = acc.find(
          (el3) =>
            el3.start_time === el2.start_time &&
            el3.end_time === el2.end_time &&
            el3.room_name === el2.room_name
        );
        if (existingClass) {
          existingClass.week += "," + el2.week;
        } else {
          acc.push({ ...el2 });
        }
        return acc;
      }, []);
      return { ...el, class_time: newClassTime };
    });

    setDataForm({
      ...state.data,
      class_list: newClassList.map((el) => {
        return { ...el, existing_data: true };
      }),
    });
    setExistingDataForm({
      ...state.data,
      class_list: newClassList.map((el) => {
        return { ...el, existing_data: true };
      }),
    });
    setUserAddr({
      zonecode: state.data.postal_code,
      addr: state.data.address,
    });
    setForm({
      ...form,
      mb_name: { val: state.data.student_name, isValid: true },
      mb_ph: { val: state.data.parent_phone_number, isValid: true },
    });
    setSelectValue({
      name: state.data.student_status,
      code: { 재학: "status_1", 휴학: "status_2" }[state.data.student_status],
    });
    setStudentPhoneNumber({
      val: state.data.student_phone_number,
      isValid: true,
    });
  }, []);

  if (!dataForm)
    return (
      <div className="inner">
        <header>
          <h2>학생수정</h2>
        </header>
      </div>
    );
  else if (pathname !== "/student/edit/" + id) {
    return (
      <Outlet
        context={{
          selectClass,
          deleteClass,
          classList: dataForm.class_list,
          selectShuttle,
        }}
      />
    );
  } else
    return (
      <div id="student" className="container background_white">
        <div className="inner">
          <Loading />
          <section className="page_content_wrap">
            <header>
              <h2>학생수정</h2>
              <div className="btn_group">
                <ReturnToList />
              </div>
            </header>

            <table className="detail desktop mt_44">
              <colgroup>
                <col width={"160px"} />
                <col width={"35%"} />
                <col width={"160px"} />
                <col width={"35%"} />
              </colgroup>
              <tr>
                <th>이름</th>
                <td>
                  <div className="cell_content_wrap">
                    <input
                      type="text"
                      className={errorCheck("mb_name")?.state}
                      {...validAttributes("mb_name")}
                    />
                  </div>
                </td>
                <th>성별</th>
                <td>
                  <div className="cell_content_wrap">
                    <label htmlFor="man">
                      남
                      <input
                        type="checkbox"
                        name="gender"
                        id="man"
                        className="ml_4"
                        checked={dataForm.student_gender === "남"}
                        {...attributes("student_gender", "남")}
                      />
                    </label>
                    <label htmlFor="woman">
                      여
                      <input
                        type="checkbox"
                        name="gender"
                        id="woman"
                        className="ml_4"
                        checked={dataForm.student_gender === "여"}
                        {...attributes("student_gender", "여")}
                      />
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <th>상태</th>
                <td className="student_state">
                  <div className="cell_content_wrap">
                    <SelectBox />
                  </div>
                </td>
                <th>생년월일</th>
                <td>
                  <div className="cell_content_wrap">
                    <DatePickerModule
                      type="specific"
                      minDate={"off"}
                      maxDate={"on"}
                      className="full"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>주소</th>
                <td colSpan={3}>
                  <div className="cell_content_wrap">
                    <input
                      type="text"
                      className="small"
                      placeholder="우편번호"
                      value={userAddr.zonecode}
                      readOnly
                    />
                    <input
                      type="text"
                      placeholder="기존주소"
                      value={userAddr.addr}
                      readOnly
                    />
                    <div className="btn_group short">
                      <button
                        className="btn_default slim small green3"
                        onClick={openDaumPost}
                      >
                        주소검색
                      </button>
                    </div>
                    <input
                      type="text"
                      placeholder="상세주소"
                      {...attributes("detail_address")}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  보호자
                  <br />
                  연락처
                </th>
                <td>
                  <div className="cell_content_wrap">
                    <input
                      type="text"
                      className={errorCheck("mb_ph")?.state}
                      data-format="number"
                      {...validAttributes("mb_ph")}
                    />
                    <div className="btn_group short">
                      <button
                        className="btn_default slim small green3"
                        onClick={submitInviteData}
                      >
                        초대하기
                      </button>
                    </div>
                  </div>
                </td>
                <th>
                  학생
                  <br /> 연락처
                </th>
                <td>
                  <StudentPhoneNumber
                    studentPhoneNumber={studentPhoneNumber}
                    setStudentPhoneNumber={setStudentPhoneNumber}
                  />
                </td>
              </tr>
            </table>

            <table className="detail fixed mobile">
              <colgroup>
                <col width={"22%"} />
              </colgroup>
              <tr>
                <th>이름</th>
                <td>
                  <div className="cell_content_wrap">
                    <input
                      type="text"
                      className={errorCheck("mb_name")?.state}
                      {...validAttributes("mb_name")}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <th>성별</th>
                <td>
                  <div className="cell_content_wrap">
                    <label htmlFor="man">
                      남
                      <input
                        type="checkbox"
                        name="gender"
                        id="man"
                        className="ml_4"
                        checked={dataForm.student_gender === "남"}
                        {...attributes("student_gender", "남")}
                      />
                    </label>
                    <label htmlFor="woman">
                      여
                      <input
                        type="checkbox"
                        name="gender"
                        id="woman"
                        className="ml_4"
                        checked={dataForm.student_gender === "여"}
                        {...attributes("student_gender", "여")}
                      />
                    </label>
                  </div>
                </td>
              </tr>

              <tr>
                <th>상태</th>
                <td className="student_state">
                  <div className="cell_content_wrap">
                    <SelectBox />
                  </div>
                </td>
              </tr>

              <tr>
                <th>생년월일</th>
                <td>
                  <div className="cell_content_wrap">
                    <DatePickerModule
                      type="specific"
                      minDate={"off"}
                      maxDate={"on"}
                      className="full"
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <th>주소</th>
                <td>
                  <div className="cell_content_wrap address">
                    <div className="postcode_wrap">
                      <input
                        type="text"
                        className="pointer"
                        placeholder="우편번호"
                        value={userAddr.zonecode}
                        onClick={openDaumPost}
                        readOnly
                      />
                      <div className="btn_group short">
                        <button
                          className="btn_default small slim green3"
                          onClick={openDaumPost}
                        >
                          주소검색
                        </button>
                      </div>
                    </div>

                    <input
                      type="text"
                      className="pointer"
                      value={userAddr.addr}
                      onClick={openDaumPost}
                      readOnly
                    />

                    <input
                      type="text"
                      placeholder="상세주소"
                      {...attributes("detail_address")}
                    />
                  </div>
                </td>
              </tr>

              <tr>
                <th>
                  보호자
                  <br />
                  연락처
                </th>
                <td>
                  <div className="cell_content_wrap">
                    <input
                      type="text"
                      className={errorCheck("mb_ph")?.state}
                      data-format="number"
                      {...validAttributes("mb_ph")}
                    />
                    <div className="btn_group short">
                      <button
                        className="btn_default slim small green3"
                        onClick={submitInviteData}
                      >
                        초대하기
                      </button>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <th>
                  학생
                  <br />
                  연락처
                </th>
                <td>
                  <StudentPhoneNumber
                    studentPhoneNumber={studentPhoneNumber}
                    setStudentPhoneNumber={setStudentPhoneNumber}
                  />
                </td>
              </tr>
            </table>
          </section>

          <section className="page_content_wrap mt_56">
            <header>
              <h2>선택 수업 리스트</h2>
              <div className="btn_group">
                <button
                  className="btn_default"
                  onClick={() => {
                    navigate("select-class");
                  }}
                >
                  수업리스트에서 선택
                </button>
              </div>
            </header>

            <table className="detail border_none outline_none">
              <colgroup className="mobile">
                <col width={"25%"} />
                <col width={"35%"} />
                <col width={"20%"} />
                <col width={"20%"} />
              </colgroup>
              <colgroup className="desktop">
                <col width={"10%"} />
                <col width={"5%"} />
                <col width={"15%"} />
                <col width={"15%"} />
                <col width={"10%"} />
                <col width={"10%"} />
                <col width={"5%"} />
                <col width={"10%"} />
                <col width={"10%"} />
                <col width={"10%"} />
              </colgroup>
              <thead>
                <tr className="mobile">
                  <th>수업명</th>
                  <th>
                    <McBoardContent />
                  </th>
                  <th>셔틀버스</th>
                  <th>선택</th>
                </tr>
                <tr className="desktop">
                  <th>수업</th>
                  <th>레벨</th>
                  <th>일정</th>
                  <th>수업시간</th>
                  <th>선생님</th>
                  <th>수업장소</th>
                  <th>과목</th>
                  <th>셔틀버스</th>
                  <th>
                    수강료
                    <br />
                    납입일
                  </th>
                  <th>선택</th>
                </tr>
              </thead>
              <tbody>
                {dataForm.class_list.map((el, idx) => (
                  <tr key={idx}>
                    <td className="hide_text">
                      {listDataFormat("class_name", el)}
                    </td>
                    {/* 모바일 */}
                    <td className="mobile">
                      {listDataFormat(selectMcBoardContent.code, el)}
                    </td>
                    {/* 모바일 */}
                    <td className="desktop">{listDataFormat("level", el)}</td>
                    <td className="desktop">
                      {listDataFormat("class_schedule", el)}
                    </td>
                    <td className="desktop">
                      {listDataFormat("class_time", el)}
                    </td>
                    <td className="desktop">
                      {listDataFormat("mna_teacher_name", el)}
                    </td>
                    <td className="desktop hide_text">
                      {listDataFormat("class_room", el)}
                    </td>
                    <td className="desktop">
                      {listDataFormat("class_field", el)}
                    </td>
                    <td>{listDataFormat("class_shuttle", el, idx)}</td>
                    <td className="desktop">
                      {listDataFormat("payment_date", el, idx)}
                    </td>
                    <td>{listDataFormat("class_delete", el)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>

          <div className="btn_group">
            <button className="btn_default half del" onClick={deleteData}>
              삭제
            </button>
            <button
              className="btn_default half"
              onClick={submitData}
              disabled={
                !validPass(["mb_name", "mb_ph"]) ||
                (studentPhoneNumber.val && !studentPhoneNumber.isValid)
              }
            >
              수정
            </button>
          </div>
        </div>
        <DaumPost />
      </div>
    );
}

function StudentPhoneNumber({ studentPhoneNumber, setStudentPhoneNumber }) {
  const { form, setForm, validAttributes, errorCheck } = useValidation();

  useEffect(() => {
    setForm({ ...form, mb_ph: studentPhoneNumber });
  }, []);

  useEffect(() => {
    setStudentPhoneNumber(form.mb_ph);
  }, [form]);

  return (
    <div className="cell_content_wrap">
      <input
        type="text"
        className={errorCheck("mb_ph")?.state}
        data-format="number"
        {...validAttributes("mb_ph")}
      />
    </div>
  );
}
