import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import useModalHandler from "hooks/useModalHandler";
import formatPhoneNumber from "utils/formatPhoneNumber";
import formatText from "utils/formatText";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

export default function StudentSelectShuttle() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { selectShuttle } = useOutletContext();
  const { ModalComponent, openModal, sendModalData } = useModalHandler();
  const [shuttleData, setShuttleData] = useState(
    state.data.shuttle_class_list.map((el) => {
      return {
        ...el,
        riding: null,
        quit: null,
      };
    })
  );

  const updateShuttleData = () => {
    if (!state.data.shuttle_bus_stop_ids) return;
    let copy = [...shuttleData];
    state.data.shuttle_bus_stop_ids.forEach((el) => {
      copy.forEach((el2, idx) => {
        if (el.class_time_id === el2.class_time_id) {
          copy[idx] = { ...el };
        }
      });
    });
    setShuttleData(copy);
  };

  const selectStation = (data) => {
    let copy = [...shuttleData];
    copy[data.idx] = { ...copy[data.idx], [data.mode]: data.detail };
    setShuttleData(copy);
  };

  useEffect(() => {
    updateShuttleData();
  }, []);

  useEffect(() => {
    if (sendModalData) selectStation(sendModalData);
  }, [sendModalData]);

  return (
    <div id="student" className="container background_white">
      <div className="inner">
        {shuttleData.map((el, idx) => {
          return (
            <>
              <section className="page_content_wrap ">
                <header>
                  <h2>
                    등원-승차위치{" "}
                    <span>
                      (
                      {el.week +
                        " " +
                        el.start_time.slice(0, -3) +
                        "~" +
                        el.end_time.slice(0, -3) +
                        " 수업"}
                      )
                    </span>
                    <div className="btn_group short">
                      <button
                        className="btn_default slim green3"
                        onClick={() => {
                          openModal("selectClassShuttle", "riding", {
                            classData: state.data,
                            timeData: el,
                            idx,
                          });
                        }}
                      >
                        셔틀버스 조회
                      </button>
                    </div>
                  </h2>
                </header>

                {!el.riding && (
                  <p className="empty_data">
                    선택된 셔틀버스가 없습니다.
                    <br /> ‘셔틀버스 조회’를 통해 정류장을 선택해주세요.
                  </p>
                )}
                {el.riding && (
                  <>
                    <table className="detail desktop">
                      <tr>
                        <th>버스이름</th>
                        <td colSpan={2}>
                          <div className="cell_content_wrap">
                            {el.riding.bus_name}
                          </div>
                        </td>
                        <th>차량번호</th>
                        <td>
                          <div className="cell_content_wrap">
                            {el.riding.bus_no}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>운전자명</th>
                        <td colSpan={2}>
                          <div className="cell_content_wrap">
                            {el.riding.driver_name}
                          </div>
                        </td>
                        <th>
                          운전자
                          <br />
                          전화번호
                        </th>
                        <td>
                          <div className="cell_content_wrap">
                            {formatPhoneNumber(el.riding.driver_phone_number)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th rowSpan={3}>운행정보</th>
                        <td>종류</td>
                        <td className="shuttle_type">
                          <div className="cell_content_wrap">
                            {{ 0: "등원", 1: "하원" }[el.riding?.direction]}
                          </div>
                        </td>
                        <th rowSpan={3}>운행요일</th>
                        <td rowSpan={3}>
                          <div className="cell_content_wrap">
                            {el.riding.operation_date
                              .sort((a, b) => a - b)
                              .map((el) => {
                                return [
                                  "월",
                                  "화",
                                  "수",
                                  "목",
                                  "금",
                                  "토",
                                  "일",
                                ][el];
                              })
                              .join(", ")}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>출발시간</td>
                        <td className="start_time">
                          <div className="cell_content_wrap">
                            {el.riding.departure_time.slice(0, -3)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>도착시간</td>
                        <td className="start_time">
                          <div className="cell_content_wrap">
                            {el.riding.arrival_time.slice(0, -3)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>정류장 정보</th>
                        <td colSpan={4} className="station_list">
                          <div className="cell_content_wrap">
                            <table className="detail outline_none border_none bg_none">
                              <colgroup>
                                <col width="15%" />
                                <col width="40%" />
                                <col width="auto" />
                                <col width="auto" />
                                <col width="auto" />
                              </colgroup>
                              <thead>
                                <tr>
                                  <th>명칭</th>
                                  <th>주소 (클릭하여 조회)</th>
                                  <th>상세위치</th>
                                  <th>탑승시간</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {el.riding.bus_stop_list.map((el) => (
                                  <tr>
                                    <td>{el.station}</td>
                                    <td>
                                      {el.address} (
                                      {Math.round(el.stop_distance)}
                                      Km)
                                    </td>
                                    <td>{el.location}</td>
                                    <td>{el.boarding_time.slice(0, -3)}</td>
                                    <td>
                                      <div className="btn_group">
                                        {shuttleData[idx].riding.stop_id ===
                                        el.stop_id ? (
                                          <button
                                            className="btn_default slim del"
                                            onClick={() => {
                                              let copy = [...shuttleData];
                                              let target = copy[idx].riding;
                                              delete target.stop_id;
                                              selectShuttle({
                                                targetIdx: state.data.idx,
                                                shuttleData: copy,
                                              });
                                            }}
                                          >
                                            취소
                                          </button>
                                        ) : (
                                          <button
                                            className="btn_default slim green3"
                                            onClick={() => {
                                              let copy = [...shuttleData];
                                              copy[idx].riding.stop_id =
                                                el.stop_id;
                                              selectShuttle({
                                                targetIdx: state.data.idx,
                                                shuttleData: copy,
                                              });
                                            }}
                                          >
                                            선택
                                          </button>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>담당 선생님</th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {el.riding.teacher_name}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th>메모</th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {formatText(el.riding.memo)}
                          </div>
                        </td>
                      </tr>
                    </table>

                    <table className="detail fixed mobile">
                      <colgroup>
                        <col width={"26%"} />
                      </colgroup>
                      <tr>
                        <th>버스이름</th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {el.riding.bus_name}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>차량번호</th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {el.riding.bus_no}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>운전자명</th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {el.riding.driver_name}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          운전자
                          <br />
                          전화번호
                        </th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {formatPhoneNumber(el.riding.driver_phone_number)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th rowSpan={3}>운행정보</th>
                        <td>종류</td>
                        <td className="shuttle_type" colSpan={3}>
                          <div className="cell_content_wrap">
                            {{ 0: "등원", 1: "하원" }[el.riding?.direction]}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          출발
                          <br />
                          시간
                        </td>
                        <td className="start_time" colSpan={3}>
                          <div className="cell_content_wrap">
                            {el.riding.departure_time.slice(0, -3)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          도착
                          <br />
                          시간
                        </td>
                        <td className="start_time" colSpan={3}>
                          <div className="cell_content_wrap">
                            {el.riding.arrival_time.slice(0, -3)}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th>운행요일</th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {el.riding.operation_date
                              .sort((a, b) => a - b)
                              .map((el) => {
                                return [
                                  "월",
                                  "화",
                                  "수",
                                  "목",
                                  "금",
                                  "토",
                                  "일",
                                ][el];
                              })
                              .join(", ")}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td
                          colSpan={5}
                          className="station_list"
                          style={{ border: "none" }}
                        >
                          <div className="flex jc_sp_bw ai_st">
                            <Swiper
                              className="mySwiper"
                              pagination={true}
                              modules={[Pagination]}
                            >
                              {el.riding.bus_stop_list.map((el) => (
                                <SwiperSlide>
                                  <div className="table_wrap">
                                    <table className={"detail"}>
                                      <colgroup>
                                        <col width={"25%"} />
                                        <col width={""} />
                                      </colgroup>
                                      <tr>
                                        <th colSpan={2} className="title">
                                          <div className="flex jc_sp_bw">
                                            <strong>정류장 정보</strong>
                                            <div className="btn_group short">
                                              {shuttleData[idx].riding
                                                .stop_id === el.stop_id ? (
                                                <button
                                                  className="btn_default slim del"
                                                  onClick={() => {
                                                    let copy = [...shuttleData];
                                                    let target =
                                                      copy[idx].riding;
                                                    delete target.stop_id;
                                                    selectShuttle({
                                                      targetIdx: state.data.idx,
                                                      shuttleData: copy,
                                                    });
                                                  }}
                                                >
                                                  취소
                                                </button>
                                              ) : (
                                                <button
                                                  className="btn_default slim green3"
                                                  onClick={() => {
                                                    let copy = [...shuttleData];
                                                    copy[idx].riding.stop_id =
                                                      el.stop_id;
                                                    selectShuttle({
                                                      targetIdx: state.data.idx,
                                                      shuttleData: copy,
                                                    });
                                                  }}
                                                >
                                                  선택
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </th>
                                      </tr>
                                      <tr>
                                        <th>명칭</th>

                                        <td>{el.station}</td>
                                      </tr>

                                      <tr>
                                        <th>주소</th>

                                        <td>
                                          {el.address} (
                                          {Math.round(el.stop_distance)}
                                          Km)
                                        </td>
                                      </tr>

                                      <tr>
                                        <th>상세위치</th>

                                        <td>{el.location}</td>
                                      </tr>

                                      <tr>
                                        <th>탑승시간</th>

                                        <td>{el.boarding_time.slice(0, -3)}</td>
                                      </tr>
                                    </table>
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th>담당 선생님</th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {el.riding.teacher_name}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>메모</th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {formatText(el.riding.memo)}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </>
                )}
              </section>

              <section className="page_content_wrap mt_56 mb_56 ">
                <header>
                  <h2>
                    하원-하차위치{" "}
                    <span>
                      (
                      {el.week +
                        " " +
                        el.start_time.slice(0, -3) +
                        "~" +
                        el.end_time.slice(0, -3) +
                        " 수업"}
                      )
                    </span>
                    <div className="btn_group short">
                      <button
                        className="btn_default slim green3"
                        onClick={() => {
                          openModal("selectClassShuttle", "quit", {
                            classData: state.data,
                            timeData: el,
                            idx,
                          });
                        }}
                      >
                        셔틀버스 조회
                      </button>
                    </div>
                  </h2>
                </header>

                {!el.quit && (
                  <p className="empty_data">
                    선택된 셔틀버스가 없습니다.
                    <br /> ‘셔틀버스 조회’를 통해 정류장을 선택해주세요.
                  </p>
                )}

                {el.quit && (
                  <>
                    <table className="detail desktop">
                      <tr>
                        <th>버스이름</th>
                        <td colSpan={2}>
                          <div className="cell_content_wrap">
                            {el.quit.bus_name}
                          </div>
                        </td>
                        <th>차량번호</th>
                        <td>
                          <div className="cell_content_wrap">
                            {el.quit.bus_no}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>운전자명</th>
                        <td colSpan={2}>
                          <div className="cell_content_wrap">
                            {el.quit.driver_name}
                          </div>
                        </td>
                        <th>
                          운전자
                          <br />
                          전화번호
                        </th>
                        <td>
                          <div className="cell_content_wrap">
                            {formatPhoneNumber(el.quit.driver_phone_number)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th rowSpan={3}>운행정보</th>
                        <td>종류</td>
                        <td className="shuttle_type">
                          <div className="cell_content_wrap">
                            {{ 0: "등원", 1: "하원" }[el.quit.direction]}
                          </div>
                        </td>
                        <th rowSpan={3}>운행요일</th>
                        <td rowSpan={3}>
                          <div className="cell_content_wrap">
                            {el.quit.operation_date
                              .sort((a, b) => a - b)
                              .map((el) => {
                                return [
                                  "월",
                                  "화",
                                  "수",
                                  "목",
                                  "금",
                                  "토",
                                  "일",
                                ][el];
                              })
                              .join(", ")}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>출발시간</td>
                        <td className="start_time">
                          <div className="cell_content_wrap">
                            {el.quit.departure_time.slice(0, -3)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>도착시간</td>
                        <td className="start_time">
                          <div className="cell_content_wrap">
                            {el.quit.arrival_time.slice(0, -3)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>정류장 정보</th>
                        <td colSpan={4} className="station_list">
                          <div className="cell_content_wrap">
                            <table className="detail outline_none border_none bg_none">
                              <colgroup>
                                <col width="15%" />
                                <col width="40%" />
                                <col width="auto" />
                                <col width="auto" />
                                <col width="auto" />
                              </colgroup>
                              <thead>
                                <tr>
                                  <th>명칭</th>
                                  <th>주소 (클릭하여 조회)</th>
                                  <th>상세위치</th>
                                  <th>탑승시간</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {el.quit.bus_stop_list.map((el) => (
                                  <tr>
                                    <td>{el.station}</td>
                                    <td>{el.address}</td>
                                    <td>{el.location}</td>
                                    <td>{el.boarding_time.slice(0, -3)}</td>

                                    <div className="btn_group">
                                      {shuttleData[idx].quit.stop_id ===
                                      el.stop_id ? (
                                        <button
                                          className="btn_default slim del"
                                          onClick={() => {
                                            let copy = [...shuttleData];
                                            let target = copy[idx].quit;
                                            delete target.stop_id;
                                            selectShuttle({
                                              targetIdx: state.data.idx,
                                              shuttleData: copy,
                                            });
                                          }}
                                        >
                                          취소
                                        </button>
                                      ) : (
                                        <button
                                          className="btn_default slim green3"
                                          onClick={() => {
                                            let copy = [...shuttleData];
                                            copy[idx].quit.stop_id = el.stop_id;
                                            selectShuttle({
                                              targetIdx: state.data.idx,
                                              shuttleData: copy,
                                            });
                                          }}
                                        >
                                          선택
                                        </button>
                                      )}
                                    </div>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>담당 선생님</th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {el.quit?.manager_name}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th>메모</th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {formatText(el.quit?.memo)}
                          </div>
                        </td>
                      </tr>
                    </table>

                    <table className="detail fixed mobile">
                      <colgroup>
                        <col width={"26%"} />
                      </colgroup>
                      <tr>
                        <th>버스이름</th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {el.quit.bus_name}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>차량번호</th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {el.quit.bus_no}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>운전자명</th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {el.quit.driver_name}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          운전자
                          <br />
                          전화번호
                        </th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {formatPhoneNumber(el.quit.driver_phone_number)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th rowSpan={3}>운행정보</th>
                        <td>종류</td>
                        <td className="shuttle_type" colSpan={3}>
                          <div className="cell_content_wrap">
                            {{ 0: "등원", 1: "하원" }[el.quit?.direction]}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          출발
                          <br />
                          시간
                        </td>
                        <td className="start_time" colSpan={3}>
                          <div className="cell_content_wrap">
                            {el.quit.departure_time.slice(0, -3)}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          도착
                          <br />
                          시간
                        </td>
                        <td className="start_time" colSpan={3}>
                          <div className="cell_content_wrap">
                            {el.quit.arrival_time.slice(0, -3)}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th>운행요일</th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {el.quit.operation_date
                              .sort((a, b) => a - b)
                              .map((el) => {
                                return [
                                  "월",
                                  "화",
                                  "수",
                                  "목",
                                  "금",
                                  "토",
                                  "일",
                                ][el];
                              })
                              .join(", ")}
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td
                          colSpan={5}
                          className="station_list"
                          style={{ border: "none" }}
                        >
                          <div className="flex jc_sp_bw ai_st">
                            <Swiper
                              className="mySwiper"
                              pagination={true}
                              modules={[Pagination]}
                            >
                              {el.quit.bus_stop_list.map((el) => (
                                <SwiperSlide>
                                  <div className="table_wrap">
                                    <table className={"detail"}>
                                      <colgroup>
                                        <col width={"25%"} />
                                        <col width={""} />
                                      </colgroup>
                                      <tr>
                                        <th colSpan={2} className="title">
                                          <div className="flex jc_sp_bw">
                                            <strong>정류장 정보</strong>
                                            <div className="btn_group short">
                                              {shuttleData[idx].quit.stop_id ===
                                              el.stop_id ? (
                                                <button
                                                  className="btn_default slim del"
                                                  onClick={() => {
                                                    let copy = [...shuttleData];
                                                    let target = copy[idx].quit;
                                                    delete target.stop_id;
                                                    selectShuttle({
                                                      targetIdx: state.data.idx,
                                                      shuttleData: copy,
                                                    });
                                                  }}
                                                >
                                                  취소
                                                </button>
                                              ) : (
                                                <button
                                                  className="btn_default slim green3"
                                                  onClick={() => {
                                                    let copy = [...shuttleData];
                                                    copy[idx].quit.stop_id =
                                                      el.stop_id;
                                                    selectShuttle({
                                                      targetIdx: state.data.idx,
                                                      shuttleData: copy,
                                                    });
                                                  }}
                                                >
                                                  선택
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </th>
                                      </tr>
                                      <tr>
                                        <th>명칭</th>

                                        <td>{el.station}</td>
                                      </tr>

                                      <tr>
                                        <th>주소</th>

                                        <td>
                                          {el.address} (
                                          {Math.round(el.stop_distance)}
                                          Km)
                                        </td>
                                      </tr>

                                      <tr>
                                        <th>상세위치</th>

                                        <td>{el.location}</td>
                                      </tr>

                                      <tr>
                                        <th>탑승시간</th>

                                        <td>{el.boarding_time.slice(0, -3)}</td>
                                      </tr>
                                    </table>
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <th>담당 선생님</th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {el.quit.teacher_name}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>메모</th>
                        <td colSpan={4}>
                          <div className="cell_content_wrap">
                            {formatText(el.quit.memo)}
                          </div>
                        </td>
                      </tr>
                    </table>
                  </>
                )}
              </section>
            </>
          );
        })}
      </div>
      <div className="btn_group">
        <button
          className="btn_default"
          onClick={() => {
            navigate(-1);
          }}
        >
          선택완료
        </button>
      </div>
      <ModalComponent />
    </div>
  );
}
