import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useQuery } from "react-query";
import axios from "axios";
import useNiceCert from "hooks/useNiceCert";
import formatPhoneNumber from "utils/formatPhoneNumber";
import { url } from "variable/url";
import logo from "assets/img/icon/logo.png";
import logo_mc from "assets/img/icon/logo_mc.png";
import { useSetRecoilState } from "recoil";
import { messageAtom } from "atoms/atoms";

export default function FindPw() {
  const setMessage = useSetRecoilState(messageAtom);
  const navigate = useNavigate();
  const { openNicePopup, niceData } = useNiceCert();
  const [mask, setMask] = useState();

  const submitData = async (data, type) => {
    try {
      const res = await axios.post(url.server + "teacher/find-password", {
        ci: data.ci,
        name: data.mb_name,
        phone_number: formatPhoneNumber(data.mb_ph),
        type,
      });
      if (type === "email") setMask(res.data.data.mask);
      else
        setMessage(
          res.data.data.mask + " 번호로 임시 비밀번호가 발송되었습니다."
        );
    } catch (err) {
      let res = err?.response?.data.message || "error";
      if (res.length > 100) res = "error";
      setMessage(res);
      return false;
    }
  };

  useEffect(() => {
    openNicePopup();
  }, []);

  useEffect(() => {
    if (niceData) submitData(niceData, "email");
  }, [niceData]);

  if (!mask) {
    return (
      <main id="joinStep1" className="container pd_none">
        <section className="top_section">
          <img src={logo} alt="" className="desktop" />
          <img src={logo_mc} alt="" className="mobile" />
          <h1>
            성공으로 이끄는
            <br />
            <strong>학원운영</strong> 에듀빌리
          </h1>
        </section>
        {!niceData && (
          <section className="pending_nice center">
            휴대폰 인증 진행중입니다.
            <br />
            <br />
            인증 완료 전 팝업 종료 시, 좌측 상단의 에듀빌리를 클릭하며 처음부터
            진행 바랍니다.
          </section>
        )}
      </main>
    );
  } else
    return (
      <main id="findPw" className="container">
        <section>
          <header>
            <h1>임시 비밀번호 발급</h1>
          </header>
          <section>
            <p>
              임시 비밀번호를 이메일
              <span>
                <br className="mobile" />({mask})
              </span>
              로 발송하였습니다.
            </p>
            <button
              className="line"
              onClick={() => {
                submitData(niceData, "phone");
              }}
            >
              문자로 받기
            </button>
            <div className="btn_group mt_40">
              <button
                className="btn_default"
                onClick={() => {
                  navigate("/login");
                }}
              >
                로그인 페이지로 이동
              </button>
            </div>
          </section>
        </section>
      </main>
    );
}
