import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useRequestAPI from "hooks/useRequestAPI";
import useDatePicker from "hooks/useDatePicker";
import useFileHandler from "hooks/useFileHandler";
import useInputHandler from "hooks/useInputHandler";
import formatDate from "utils/formatDate";
import useValidation from "hooks/useValidation";

export default function JoinCreateHold() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { form, setForm, errorCheck, validAttributes } = useValidation();
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();
  const { fileData, fileInputRef, uploadFile, uploadS3 } = useFileHandler(
    ["jpg", "jpeg", "png", "pdf"],
    10
  );
  const [dataForm, setDataForm, attributes] = useInputHandler({
    academy_name: "",
  });
  const { DatePickerModule, specificDate, setSpecificDate } = useDatePicker();
  const [resData, setResData] = useState();

  const getData = async () => {
    const res = await requestAPI("post", "academy/sign-up/checking", {
      ocr_academy_id: state.ocr_academy_id,
    });
    if (!res) return;
    const data = res.data.data;
    setResData(data);
    setForm({
      ...form,
      license_no: { val: data.license_no, isValid: true },
      mb_name: { val: data.ceo_name, isValid: true },
    });
    setDataForm({ ...dataForm, academy_name: data.academy_name });
    setSpecificDate(new Date(data.opening_date));
  };

  const submitData = async () => {
    if (!fileData[0]) return setMessage("사업자 등록증을 첨부해주세요.");
    const uploader = localStorage.getItem("acadmey_id") ? "manager" : "teacher";
    const licenseData = await uploadS3(uploader);
    const body = {
      ...resData,
      ...dataForm,
      ceo_name: form.mb_name.val,
      license_no: form.license_no.val,
      license_path: licenseData,
      opening_date: formatDate(specificDate),
    };
    const res = await requestAPI("post", "academy/sign-up", body);
    if (!res) return;
    if (res.data.data.status) {
      setMessage({
        text: alertMsg.create,
        confirm: () =>
          navigate("/auth/join/complete", { state: { data: { ...body } } }),
      });
    } else {
      setMessage({
        text: "첨부한 사업자 등록증과 입력한 정보가 일치하지 않아 재 심사 처리 되었습니다.",
        confirm: () =>
          navigate("/auth/join/hold", {
            state: { ocr_academy_id: res.data.data.ocr_academy_id },
          }),
      });
    }
  };

  const deleteData = () => {
    setMessage({
      type: "confirm",
      text: "입주를 취소하시겠습니까?",
      confirm: async () => {
        const res = await requestAPI(
          "delete",
          "academy/sign-up/checking/delete",
          {
            ocr_academy_id: state.ocr_academy_id,
          }
        );
        if (!res) return;
        setMessage({
          text: "사업장 생성이 취소되었습니다.",
          confirm: () => navigate("/auth/join/step2"),
        });
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <main id="joinCreateHold" className="container ">
      <Loading />
      <section>
        <figure>
          <header>
            <h1>
              에듀빌리 입주 심사 중
              <br />
              <span className="desc">
                <strong>심사 사유 : 사업자 등록증 일치 여부 확인</strong>
                <br />
                ※ 빠른 심사를 위해 식별이 용이한 사업자등록증을 등록해주세요.
                <br />※ 학원 코드를 통해 로그인 시 심사 여부를 확인할 수
                있습니다.
              </span>
            </h1>
          </header>

          <table className="desktop">
            <tr>
              <th>사업자 등록번호</th>
              <th>사업자 등록증</th>
            </tr>
            <tr>
              <td>
                <div className="cell_content_wrap">
                  <input
                    type="text"
                    className={"half " + errorCheck("license_no")?.state}
                    {...validAttributes("license_no")}
                    data-format="number"
                  />
                </div>
              </td>
              <td>
                <div className="btn_group ">
                  <button className="btn_default">
                    <label htmlFor="file">파일첨부</label>
                  </button>

                  <input
                    id="file"
                    type="file"
                    className="hidden"
                    ref={fileInputRef}
                    onChange={uploadFile}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>상호명</th>
            </tr>
            <tr>
              <td colSpan={2}>
                <input type="text" {...attributes("academy_name")} />
              </td>
            </tr>
            <tr>
              <th>대표자명</th>
              <th>개업일자</th>
            </tr>
            <tr>
              <td className="pd_none">
                <input
                  type="text"
                  className={"half " + errorCheck("mb_name")?.state}
                  {...validAttributes("mb_name")}
                />
              </td>
              <td className="pd_none">
                <DatePickerModule
                  type="specific"
                  className="half"
                  minDate={"off"}
                  maxDate={"on"}
                />
              </td>
            </tr>
          </table>

          <table className="mobile">
            <tr>
              <th>사업자 등록번호</th>
            </tr>
            <tr>
              <td>
                <div className="cell_content_wrap">
                  <input
                    type="text"
                    className={errorCheck("license_no")?.state}
                    {...validAttributes("license_no")}
                    data-format="number"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>사업자 등록증</th>
            </tr>
            <tr>
              <td>
                <div className="btn_group ">
                  <button className="btn_default medium">
                    <label htmlFor="file">파일첨부</label>
                  </button>
                  <input
                    id="file"
                    type="file"
                    className="hidden"
                    ref={fileInputRef}
                    onChange={uploadFile}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th colSpan={2}>상호명</th>
            </tr>
            <tr>
              <td colSpan={2}>
                <input type="text" {...attributes("academy_name")} />
              </td>
            </tr>
            <tr>
              <th>대표자명</th>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  className={errorCheck("mb_name")?.state}
                  {...validAttributes("mb_name")}
                />
              </td>
            </tr>
            <tr>
              <th>개업일자</th>
            </tr>
            <tr>
              <td>
                <DatePickerModule
                  type="specific"
                  minDate={"off"}
                  maxDate={"on"}
                />
              </td>
            </tr>
          </table>

          <div className="btn_group">
            <button className="btn_default del" onClick={deleteData}>
              입주취소
            </button>
            <button className="btn_default " onClick={submitData}>
              다시제출
            </button>
          </div>
        </figure>
      </section>
    </main>
  );
}
