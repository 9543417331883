import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import useSelectBox from "hooks/useSelectBox";
import useRequestAPI from "hooks/useRequestAPI";
import Pagination from "components/Pagination";
import formatExcelTitle from "utils/formatExcelTitle";
import guide_notice from "assets/file/guide_notice.pdf";

export default function Notification() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [SelectBox, selectValue] = useSelectBox([
    { name: "전체", code: "all" },
    { name: "수업명", code: "class_name" },
    { name: "내용", code: "desc" },
  ]);
  const { requestAPI, Loading } = useRequestAPI();
  const [searchKeyword, setSerchKeword] = useState("");
  const [currPage, setCurrPage] = useState(id - 1);
  const [resData, setResData] = useState();
  const [currSearchData, setCurrSearchData] = useState({
    type: "notify",
    page: currPage,
    size: 5,
    search_type: "all",
    search_keyword: "",
    mna_manager_id: localStorage.getItem("manager_id"),
  });

  const [McBoardContent, selectMcBoardContent] = useSelectBox([
    { name: "내용", code: "desc_text" },
    { name: "읽음", code: "true_count" },
    { name: "발송일시", code: "created_at" },
    { name: "메모", code: "memo" },
  ]);

  const listDataFormat = (type, data) => {
    switch (type) {
      case "created_at": {
        return data.created_at
          .split(".")
          .slice(0, -1)
          .join()
          .replace(/-/g, ".");
      }
      case "true_count": {
        return data.true_count + "/" + data.total_count;
      }
      default: {
        return data[type];
      }
    }
  };

  const getData = async (e) => {
    let body = { ...currSearchData, page: currPage };
    if (e?.target.tagName === "BUTTON") {
      body = {
        type: "notify",
        page: currPage,
        size: 5,
        search_type: selectValue?.code,
        search_keyword: searchKeyword,
        mna_manager_id: localStorage.getItem("manager_id"),
      };
      setCurrSearchData(body);
      return setCurrPage(0);
    }
    const res = await requestAPI("post", "notify/list", body);
    if (!res) return;
    setResData(res.data.data.result);
  };

  useEffect(() => {
    getData();
  }, [currPage, currSearchData]);

  return (
    <div className="container">
      <div className="inner">
        <Loading />
        <section className="page_handling_wrap">
          <form className="search_wrap">
            <SelectBox />
            <input
              type="text"
              onChange={(e) => {
                setSerchKeword(e.target.value);
              }}
            />
            <div className="btn_group">
              <button className="btn_default search" onClick={getData}>
                검색
              </button>
            </div>
          </form>
          <Pagination
            currPage={currPage}
            setCurrPage={setCurrPage}
            lastPage={resData?.totalPages || 0}
          />
        </section>
        <section className="board_wrap">
          <header>
            <h2>알림</h2>
            <div className="btn_group">
              <button className="btn_default guide">
                <a href={guide_notice} download={"에듀빌리_알림_사용가이드"}>
                  사용가이드
                </a>
              </button>
              <ReactHTMLTableToExcel
                className="btn_default excel"
                table="table"
                filename={formatExcelTitle("알림목록")}
                sheet="tableSheet"
                buttonText="엑셀다운로드"
              />
              <button
                className="btn_default add"
                onClick={() => {
                  navigate("/notification/add");
                }}
              >
                알림 등록
              </button>
            </div>
          </header>
          <table id="table">
            <thead>
              <tr className="mobile">
                <th>수업</th>
                <th>
                  <McBoardContent />
                </th>
                <th>담당</th>
              </tr>
              <tr className="desktop">
                <th>번호</th>
                <th>수업</th>
                <th>내용</th>
                <th>읽음</th>
                <th>담당</th>
                <th>발송일시</th>
                <th>메모</th>
              </tr>
            </thead>
            <tbody>
              {resData && !resData.content[0] && (
                <tr>
                  <td colSpan={7} className="desktop">
                    표시할 데이터가 없습니다.
                  </td>
                  <td colSpan={3} className="mobile">
                    표시할 데이터가 없습니다.
                  </td>
                </tr>
              )}
              {resData?.content.map((el, idx) => (
                <tr
                  key={idx}
                  className={el.true_count === el.total_count && "read_all"}
                  onClick={() => {
                    navigate("/notification/detail/" + el.id, {
                      state: { mna_manager_id: el.mna_manager_id },
                    });
                  }}
                >
                  <td className="desktop">
                    {resData.totalElements - currPage * 5 - idx}
                  </td>
                  <td>{listDataFormat("class_name", el)}</td>
                  {/* 모바일 */}
                  <td
                    className={
                      "mobile " +
                      (selectMcBoardContent.code === "desc_text" && "hide_text")
                    }
                  >
                    {listDataFormat(selectMcBoardContent.code, el)}
                  </td>
                  {/* 모바일 */}
                  <td className="hide_text desktop">
                    {listDataFormat("desc_text", el)}
                  </td>
                  <td className="desktop">
                    {listDataFormat("true_count", el)}
                  </td>
                  <td>{listDataFormat("teacher_name", el)}</td>
                  <td className="desktop">
                    {listDataFormat("created_at", el)}
                  </td>
                  <td className="hide_text desktop">
                    {listDataFormat("memo", el)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </div>
    </div>
  );
}
