import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "components/Pagination";
import useSelectBox from "hooks/useSelectBox";
import useModalHandler from "hooks/useModalHandler";
import useRequestAPI from "hooks/useRequestAPI";
import formatPhoneNumber from "utils/formatPhoneNumber";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import permissionCheck from "utils/permissionCheck";
import formatExcelTitle from "utils/formatExcelTitle";

export default function AcademyShuttle() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { ModalComponent } = useModalHandler();
  const [SelectBox, selectValue] = useSelectBox([
    { name: "전체", code: "all" },
    { name: "버스명", code: "bus_name" },
    { name: "차량번호", code: "bus_no" },
    { name: "운전자 휴대폰 번호", code: "driver_phone_number" },
    { name: "담당 선생님 휴대폰 번호", code: "manager_phone_number" },
  ]);

  const [McBoardContent, selectMcBoardContent] = useSelectBox([
    { name: "타입", code: "direction" },
    { name: "차량번호", code: "bus_no" },
    { name: "운전자명", code: "driver_name" },
    { name: "운전자 연락처", code: "driver_phone_number" },
    { name: "담당", code: "manager_name" },
    { name: "선생님 연락처", code: "manager_phone_number" },
    { name: "특징", code: "memo" },
  ]);

  const listDataFormat = (type, data) => {
    switch (type) {
      case "direction": {
        return data.direction === "0" ? "등원" : "하원";
      }
      case "driver_phone_number":
      case "manager_phone_number": {
        return formatPhoneNumber(data[type]);
      }
      default: {
        return data[type];
      }
    }
  };

  const [searchKeyword, setSerchKeword] = useState("");
  const [currPage, setCurrPage] = useState(id - 1);
  const { requestAPI, Loading } = useRequestAPI();
  const [resData, setResData] = useState();
  const [currSearchData, setCurrSearchData] = useState({
    page: currPage,
    size: 5,
    search_type: "all",
    search_keyword: "",
  });

  const getData = async (e) => {
    e?.preventDefault();
    let body = { ...currSearchData, page: currPage };
    if (e?.target.tagName === "BUTTON") {
      body = {
        page: currPage,
        size: 5,
        search_type: selectValue?.code,
        search_keyword: searchKeyword,
      };
      setCurrSearchData(body);
      return setCurrPage(0);
    }
    const res = await requestAPI("post", "academy/shuttle-bus", body);
    if (!res) return;
    setResData(res.data.data);
  };

  const goToDetail = (id) => {
    navigate("/academy/shuttle/detail/" + id);
  };

  useEffect(() => {
    getData();
  }, [currPage, currSearchData]);

  return (
    <div className="inner">
      <Loading />
      <section className="page_handling_wrap">
        <form className="search_wrap">
          <SelectBox />
          <input
            type="text"
            onChange={(e) => {
              setSerchKeword(e.target.value);
            }}
          />
          <div className="btn_group">
            <button className="btn_default search" onClick={getData}>
              검색
            </button>
          </div>
        </form>
        <Pagination
          currPage={currPage}
          setCurrPage={setCurrPage}
          lastPage={resData?.shuttle_bus_list.totalPages || 0}
        />
      </section>

      <section className="board_wrap">
        <header>
          <h2>셔틀버스 목록</h2>
          <div className="btn_group">
            <ReactHTMLTableToExcel
              className="btn_default excel"
              table="table"
              filename={formatExcelTitle("셔틀버스목록")}
              sheet="tableSheet"
              buttonText="엑셀다운로드"
            />
            {permissionCheck(1) && (
              <button
                className="btn_default add"
                onClick={() => {
                  navigate("/academy/shuttle/type");
                }}
              >
                셔틀버스 등록
              </button>
            )}
          </div>
        </header>
        <table id="table">
          <colgroup className="mobile">
            <col width={"30%"} />
            <col width={"auto"} />
            <col width={"30%"} />
          </colgroup>
          <colgroup className="desktop">
            <col width={"5%"} />
            <col width={"5%"} />
            <col width={"10%"} />
            <col width={"15%"} />
            <col width={"10%"} />
            <col width={"15%"} />
            <col width={"10%"} />
            <col width={"15%"} />
            <col width={"15%"} />
          </colgroup>
          <thead>
            <tr className="mobile">
              <th>버스명</th>
              <th className="mobile">{<McBoardContent />}</th>
              <th>담당</th>
            </tr>
            <tr className="desktop">
              <th>번호</th>
              <th>타입</th>
              <th>버스명</th>
              <th>차량번호</th>
              <th>운전자명</th>
              <th>운전자 연락처</th>
              <th>담당</th>
              <th>선생님 연락처</th>
              <th>특징 </th>
            </tr>
          </thead>
          <tbody>
            {resData && !resData.shuttle_bus_list.content[0] && (
              <tr>
                <td colSpan={11} className="desktop">
                  표시할 데이터가 없습니다.
                </td>
                <td colSpan={3} className="mobile">
                  표시할 데이터가 없습니다.
                </td>
              </tr>
            )}
            {resData?.shuttle_bus_list.content.map((el, idx) => (
              <tr
                key={idx}
                onClick={() => {
                  goToDetail(el.id);
                }}
              >
                <td className="desktop">
                  {resData?.shuttle_bus_list.totalElements - currPage * 5 - idx}
                </td>
                <td className="desktop">{listDataFormat("direction", el)}</td>
                <td className="hide_text">{listDataFormat("bus_name", el)}</td>
                {/* 모바일 */}
                <td className="mobile hide_text">
                  {listDataFormat(selectMcBoardContent.code, el)}
                </td>
                {/* 모바일 */}
                <td className="desktop">{listDataFormat("bus_no", el)}</td>
                <td className="desktop">{listDataFormat("driver_name", el)}</td>
                <td className="desktop">
                  {listDataFormat("driver_phone_number", el)}
                </td>
                <td>{listDataFormat("manager_name", el)}</td>
                <td className="desktop">
                  {listDataFormat("manager_phone_number", el)}
                </td>
                <td className="hide_text desktop">
                  {listDataFormat("memo", el)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      <ModalComponent />
    </div>
  );
}
