import { useState } from "react";
import { getCookie, setCookie } from "utils/cookieHandler";

export default function EventPopup({ closeModal, currentData }) {
  const [isHideForToday, setIsHideForToday] = useState(false);

  if (!getCookie("hide_popup_no_" + currentData.data.index))
    return (
      <aside id={"eventPopup" + currentData.data.index} className="modal dim">
        <div
          className="modal_content_wrap"
          //   style={{
          //     left:
          //       20 * (currentData.data.index + 1) + 464 * currentData.data.index,
          //   }}
        >
          <header>
            <label
              htmlFor={"hideForToday" + currentData.data.index}
              className=""
            >
              <input
                type="checkbox"
                className="mr_8"
                id={"hideForToday" + currentData.data.index}
                onChange={(e) => {
                  setIsHideForToday(e.target.checked);
                }}
              />
              하루동안 보지 않기
            </label>

            <div className="btn_group">
              <button
                className="close btn_default"
                onClick={() => {
                  if (isHideForToday) {
                    setCookie(
                      "hide_popup_no_" + currentData.data.index,
                      "true",
                      24
                    );
                  }
                  closeModal();
                }}
              >
                닫기
              </button>
            </div>
          </header>

          <section className="event_content">
            <img src={currentData.data.img} alt="" />
          </section>
        </div>
      </aside>
    );
}
