import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useRequestAPI from "hooks/useRequestAPI";
import ReturnToList from "components/ReturnToList";
import formatText from "utils/formatText";
import permissionCheck from "utils/permissionCheck";

export default function AcademyClassroomDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();
  const [resData, setResData] = useState();

  const getData = async () => {
    const res = await requestAPI("post", "academy/classroom/detail", {
      classroom_id: id,
    });

    if (!res) return;
    setResData(res.data.data);
  };

  const deleteData = async () => {
    setMessage({
      type: "confirm",
      text: "삭제하시겠습니까?",
      confirm: async () => {
        const res = await requestAPI("delete", "academy/classroom/delete", {
          classroom_id: id,
        });
        if (!res) return;
        setMessage({
          text: alertMsg.delete,
          confirm: () => navigate("/academy/classroom/1"),
        });
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="inner classroom_detail">
      <Loading />
      <header>
        <h2>강의실 상세</h2>
        <div className="btn_group">
          <ReturnToList />
        </div>
      </header>
      <section className="content_wrap">
        <table className="detail">
          <colgroup className="mobile">
            <col width={"30%"} />
          </colgroup>

          <tr className="desktop">
            <th>명칭</th>
            <td>{resData?.room_name}</td>
            <th>정원</th>
            <td>{resData?.capacity}명</td>
          </tr>

          <tr className="mobile">
            <th>명칭</th>
            <td>{resData?.room_name}</td>
          </tr>
          <tr className="mobile">
            <th>정원</th>
            <td>{resData?.capacity}명</td>
          </tr>

          <tr>
            <th>위치</th>
            <td colSpan={3}>{resData?.location}</td>
          </tr>
          <tr>
            <th>
              메모
              <br />
              (시설 및<br />
              특이사항)
            </th>
            <td colSpan={3}>{formatText(resData?.memo)}</td>
          </tr>
        </table>
        <div className="btn_group mt_48">
          <button
            className="btn_default half del permission"
            onClick={deleteData}
            disabled={!permissionCheck(1)}
          >
            삭제
          </button>
          <button
            className="btn_default half permission"
            onClick={() => {
              navigate("/academy/classroom/edit/" + id, { state: { resData } });
            }}
            disabled={!permissionCheck(1)}
          >
            수정
          </button>
        </div>
      </section>
    </div>
  );
}
