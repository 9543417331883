import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "components/Pagination";
import useModalHandler from "hooks/useModalHandler";
import useSelectBox from "hooks/useSelectBox";
import useRequestAPI from "hooks/useRequestAPI";
import formatDate from "utils/formatDate";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import formatExcelTitle from "utils/formatExcelTitle";

export default function AcademyPayment() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { ModalComponent, openModal, sendModalData } = useModalHandler();
  const [SelectBox, selectValue] = useSelectBox([
    { name: "전체", code: "all" },
    { name: "이름", code: "name" },
    { name: "수업", code: "class_name" },
  ]);

  const [McBoardContent, selectMcBoardContent] = useSelectBox([
    { name: "금액", code: "class_price" },
    { name: "타입", code: "type" },
    { name: "과목", code: "field" },
    { name: "수업명", code: "class_name" },
    { name: "발생일자", code: "paid_date" },
  ]);

  const listDataFormat = (type, data) => {
    switch (type) {
      case "student_name_attr": {
        return {
          onClick: () => {
            navigate("/student/detail/" + data.student_id);
          },
        };
      }
      case "class_price": {
        return Number(data.class_price).toLocaleString("ko-KR");
      }
      case "paid_date": {
        return data.paid_date?.split(" ").map((el, idx) => (
          <>
            {idx === 0 && formatDate(el, ".")}
            {idx === 1 && el.slice(0, 5)}
            <br />
          </>
        ));
      }
      default: {
        if (type.includes("_attr")) return;
        else return data[type];
      }
    }
  };

  const [searchKeyword, setSearchKeword] = useState("");
  const [currPage, setCurrPage] = useState(id - 1);
  const { requestAPI, Loading } = useRequestAPI();
  const [resData, setResData] = useState();
  const [currSearchData, setCurrSearchData] = useState({
    page: currPage,
    size: 5,
    search_type: "all",
    search_keyword: "",
  });
  const getData = async (e) => {
    e?.preventDefault();
    let body = { ...currSearchData, page: currPage };
    if (e?.target.tagName === "BUTTON") {
      body = {
        page: currPage,
        size: 5,
        search_type: selectValue?.code,
        search_keyword: searchKeyword,
      };

      setCurrSearchData(body);
      return setCurrPage(0);
    }
    const res = await requestAPI("post", "academy/payment", body);
    if (!res) return;
    setResData(res.data.data);
  };

  useEffect(() => {
    getData();
  }, [currPage, currSearchData, sendModalData]);

  return (
    <div className="inner">
      <Loading />
      <section className="board_wrap">
        <header>
          <h2>결제 계좌 관리</h2>
        </header>
        <div className="mt_28">
          <div className="btn_group">
            <button
              className="btn_default medium slim green3"
              onClick={() =>
                openModal("addPayment", "edit", {
                  bank_account: resData.bank_account,
                  bank_account_name: resData.bank_account_name,
                  bank_name: resData.bank_name,
                })
              }
            >
              수정
            </button>
          </div>
          <p className="mt_16 desktop">
            입금 : {resData?.bank_name} {resData?.bank_account} / CMS :{" "}
            {resData?.bank_check ? "동의" : "미동의"}
          </p>
          <p className="mt_12 fs_12 mobile">
            입금 : {resData?.bank_name} {resData?.bank_account} / CMS :{" "}
            {resData?.bank_check ? "동의" : "미동의"}
          </p>
        </div>
      </section>

      <section className="page_handling_wrap">
        <form className="search_wrap">
          <SelectBox />
          <input
            type="text"
            onChange={(e) => {
              setSearchKeword(e.target.value);
            }}
          />
          <div className="btn_group">
            <button className="btn_default search" onClick={getData}>
              검색
            </button>
          </div>
        </form>
        <Pagination
          currPage={currPage}
          setCurrPage={setCurrPage}
          lastPage={resData?.payment_list.totalPages || 0}
        />
      </section>

      <section className="board_wrap mb_52">
        <header>
          <h2>결제목록</h2>
          <div className="btn_group">
            <ReactHTMLTableToExcel
              className="btn_default excel"
              table="table"
              filename={formatExcelTitle("결제목록")}
              sheet="tableSheet"
              buttonText="엑셀다운로드"
            />
          </div>
        </header>
        <table id="table">
          <thead>
            <tr className="mobile">
              <th>상태</th>
              <th>
                <McBoardContent />
              </th>
              <th>학생명</th>
            </tr>
            <tr className="desktop">
              <th>번호</th>
              <th>상태</th>
              <th>타입</th>
              <th>과목</th>
              <th>수업명</th>
              <th>담당</th>
              <th>학생명</th>
              <th>금액</th>
              <th>발생일자</th>
            </tr>
          </thead>
          <tbody>
            {resData && !resData.payment_list.content[0] && (
              <tr>
                <td colSpan={9} className="desktop">
                  표시할 데이터가 없습니다.
                </td>
                <td colSpan={3} className="mobile">
                  표시할 데이터가 없습니다.
                </td>
              </tr>
            )}
            {resData?.payment_list.content.map((el, idx) => (
              <tr
                key={idx}
                className={{ 미납: "error", 납입: "point" }[el.status]}
              >
                <td className="desktop">
                  {resData.payment_list.totalElements - currPage * 5 - idx}
                </td>
                <td className={el.status === "미납" && "error"}>
                  {listDataFormat("status", el)}
                </td>
                {/* 모바일 */}
                <td className="mobile">
                  {listDataFormat(selectMcBoardContent.code, el)}
                </td>
                {/* 모바일 */}
                <td className="desktop">{listDataFormat("type", el)}</td>
                <td className="desktop">{listDataFormat("field", el)}</td>
                <td className="hide_text desktop" title={el.class_name}>
                  {listDataFormat("class_name", el)}
                </td>
                <td className="desktop">
                  {listDataFormat("teacher_name", el)}
                </td>
                <td
                  onClick={() => {
                    navigate("/student/detail/" + el.student_id);
                  }}
                >
                  {listDataFormat("student_name", el)}
                </td>
                <td className="desktop">{listDataFormat("class_price", el)}</td>
                <td className="desktop">{listDataFormat("paid_date", el)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      <ModalComponent />
    </div>
  );
}
