import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useRequestAPI from "hooks/useRequestAPI";
import ReturnToList from "components/ReturnToList";

export default function AcademyScheduleType() {
  const navigate = useNavigate();
  const { requestAPI, setMessage, Loading } = useRequestAPI();
  const [isClassroom, setIsClassRoom] = useState();

  const checkClassroom = async () => {
    const body = {
      page: 0,
      size: 5,
      search_type: "all",
      search_keyword: "",
    };
    const res = await requestAPI("post", "academy/classroom", body);
    if (!res) return;
    const classrooms = res.data.data.classroom_list;
    if (!classrooms[0]) {
      return setMessage({
        text: "등록된 강의실이 없습니다. 강의실 등록 후 시간표를 추가해 주세요.",
        confirm: () => navigate("/academy/schedule/1"),
      });
    }
    setIsClassRoom(classrooms[0]);
  };

  useEffect(() => {
    checkClassroom();
  }, []);

  return (
    <div className="inner select_type">
      <Loading />
      <header>
        <h2>수업타입</h2>
        <div className="btn_group">
          <ReturnToList />
        </div>
      </header>
      {isClassroom && (
        <section className="content_wrap">
          <div className="btn_group type">
            <button className="btn_default type half">
              <Link to="/academy/schedule/add/ga">일반</Link>
            </button>
            <button className="btn_default type half yellow">
              <Link to="/academy/schedule/add/sp">특강</Link>
            </button>
          </div>
        </section>
      )}
    </div>
  );
}
