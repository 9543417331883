import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isLoginAtom } from "atoms/atoms";
import axios from "axios";
import useInputHandler from "hooks/useInputHandler";
import useModalHandler from "hooks/useModalHandler";
import { url } from "variable/url";
import alertMsg from "variable/alertMsg";
import logo from "assets/img/icon/logo.png";
import show_pw from "assets/img/icon/show_pw.png";
import { useSetRecoilState } from "recoil";
import { messageAtom } from "atoms/atoms";
import bootChannelIO from "utils/bootChannelIO";
axios.defaults.withCredentials = true;

export default function Login() {
  const setMessage = useSetRecoilState(messageAtom);
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const [dataForm, setDataForm, attributes] = useInputHandler({});
  const { ModalComponent, openModal, sendModalData, currentModal } =
    useModalHandler();
  const [isShowPw, setIsShowPw] = useState("password");

  const ChannelTalkLogin = async () => {
    try {
      const res = await axios.get(url.server + "teacher/me");
      const data = res.data.data;
      bootChannelIO({
        memberId: data.id,
        profile: {
          mobileNumber: dataForm.mb_ph,
          email: data.email,
          학원코드: dataForm.academy_code,
        },
      });
    } catch {}
  };

  const login = async (body, link, state) => {
    localStorage.clear();
    try {
      await axios.post(url.server + "teacher/sign-in/confirm", {
        ...body,
      });
      const res = await axios.get(url.server + "teacher/me");
      const data = res.data.data;
      localStorage.setItem("teacher_id", data.id);
      localStorage.setItem("teacher_name", data.username);
      if (data.academyId) {
        localStorage.setItem("academy_id", data.academyId);
        localStorage.setItem("manager_id", data.managerId);
        localStorage.setItem("academy_name", data.academyName);
        localStorage.setItem("grade_code", data.gradeCode);
      }

      setIsLogin(true);
      ChannelTalkLogin();
      if (state) navigate(link, { state });
      else navigate(link);
    } catch (err) {
      let res = err?.response?.data.message || "error";
      if (res.length > 100) res = "error";
      setMessage(res);
    }
  };

  const submitData = async () => {
    try {
      const res = await axios.post(url.server + "teacher/sign-in", {
        ...dataForm,
      });
      const data = res.data.data;
      if (data.changed_password) {
        // 로그인 회원이 임시 비밀번호로 로그인 해서 비밀번호를 변경해야되는 경우
        return login(dataForm, "/auth/change-pw", { correctAccess: true });
      }

      if (data.academies) {
        //로그인 회원이 학원코드 입력 후 로그인 한 경우(학원 선생님or원장님 로그인)
        if (data.academies.length > 1) {
          // 입력한 학원코드로 1개 이상의 학원이 조회 됐을 경우 학원 선택 모달 오픈
          return openModal("selectAcademy", null, data.academies);
        } else {
          login(
            { ...dataForm, target_manager_id: data.academies[0].manager_id },
            "/home"
          );
        }
      } else {
        // 개인 회원으로 로그인 한 경우
        if (data.checkings.length > 1) {
          // 등록한 사업장 중 심사중인 사업장이 1개 이상 존재하는 경우 사업장 선택 모달 오픈
          return openModal("createAcademyList", null, data.checkings);
        } else if (data.checkings.length === 1) {
          // 등록한 사업장 중 심사중인 사업장이 1개 있을 경우 사업장 심사 페이지로 이동
          login(dataForm, "/auth/join/hold", {
            ocr_academy_id: data.checkings[0].ocr_academy_id,
          });
        } else {
          // 모두 아닌 경우 가입 스텝2 페이지로이동
          login({ ...dataForm }, "/auth/join/step2");
        }
      }
    } catch (err) {
      let res = err?.response?.data.message || alertMsg.error;
      if (res.length > 100) res = alertMsg.error;
      setMessage(res);
    }
  };

  const loginInputAuto = () => {
    setDataForm({
      mb_ph: "01089955676",
      mb_password: "Butter0508!",
      academy_code: "8754",
    });
  };

  useEffect(() => {
    switch (currentModal.type) {
      case "createAcademyList": {
        login(dataForm, "/auth/join/hold", {
          ocr_academy_id: sendModalData.ocr_academy_id,
        });
        break;
      }
      case "selectAcademy": {
        login(
          { ...dataForm, target_manager_id: sendModalData.manager_id },
          "/home"
        );
        break;
      }
      default: {
        return;
      }
    }
  }, [sendModalData]);

  useEffect(() => {
    if (isLogin) {
      setMessage("이미 로그인 되어있습니다.");
      if (localStorage.getItem("academy_id")) {
        return navigate("/home");
      }
      navigate(1);
      navigate(-1);
    }
  }, []);

  return (
    <main id="login" className="container">
      <section className="left_section desktop">
        <figure>
          <img src={logo} alt="" className="logo" />
          <h1 className="title">
            성공으로 이끄는
            <br />
            <span className="tit_point">학원운영</span> 에듀빌리
          </h1>
          <h2 className="sub_title">#원생관리 #일정알림 #스마트한 학원 관리</h2>
        </figure>
      </section>

      <section className="right_section">
        <h2 className="title color_main">Login</h2>

        {(window.location.href.includes("localhost") ||
          window.location.href.includes("192.168") ||
          window.location.href.includes("//dev.")) && (
          <div className="btn_group mb_24">
            <button></button>
            <button className="btn_default medium" onClick={loginInputAuto}>
              테스트계정
            </button>
          </div>
        )}

        <div className="input_wrap">
          <span className="input_wrap_tit">휴대폰 번호</span>
          <input
            type="text"
            {...attributes("mb_ph")}
            data-format="number"
            placeholder="특수 기호 없이 입력하세요."
          />
        </div>

        <div className="input_wrap">
          <span className="input_wrap_tit">비밀번호</span>
          <div className="password_wrap">
            <input
              type={isShowPw}
              {...attributes("mb_password")}
              placeholder="대문자/소문자/특수기호 포함 8자 이상"
            />
            <img
              src={show_pw}
              alt=""
              className="show_pw"
              onClick={() => {
                if (isShowPw === "password") setIsShowPw("text");
                else setIsShowPw("password");
              }}
            />
          </div>

          <Link to="/auth/find-pw" className="btn_find_pw">
            비밀번호를 잊어버리셨나요?
          </Link>
        </div>

        <div className="input_wrap">
          <span className="input_wrap_tit">학원 코드(선택)</span>
          <input
            type="text"
            placeholder="학원 전화번호 뒷자리(4자리)를 입력하세요."
            maxLength={4}
            {...attributes("academy_code")}
            data-format="number"
          />
        </div>

        <div className="btn_group">
          <button className="btn_default" onClick={submitData}>
            로그인
          </button>
        </div>

        <Link to="/auth/join/step1" className="btn_sign_up">
          무료로 시작하기
        </Link>
      </section>

      <ModalComponent />
    </main>
  );
}
