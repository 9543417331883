export default function SelectAcademy({ closeModal, changeData, currentData }) {
  return (
    <aside id="selectAcademy" className="modal dim">
      <div className="modal_content_wrap">
        <header>
          <h2>학원 선택(코드중복)</h2>
          <div className="btn_group">
            <button className="btn_default close" onClick={closeModal}>
              닫기
            </button>
          </div>
        </header>
        <section className="table_scroll_wrap">
          <table className="detail fixed desktop">
            <colgroup>
              <col width={"100%"} />
              <col width={"150px"} />
            </colgroup>
            {currentData.data.map((el) => {
              return (
                <>
                  <tr>
                    <th>{el.academy_name}</th>
                    <td rowSpan={2}>
                      <div className="btn_group short">
                        <button
                          className="btn_default slim small"
                          onClick={() => {
                            changeData(el);
                          }}
                        >
                          선택
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="academy_address">{el.academy_address}</td>
                  </tr>
                </>
              );
            })}
          </table>

          <table className="detail fixed mobile">
            {currentData.data.map((el) => {
              return (
                <>
                  <tr>
                    <th>{el.academy_name}</th>
                  </tr>
                  <tr>
                    <td>
                      {el.academy_address}
                      <div className="btn_group center mt_8 mb_8">
                        <button
                          className="btn_default slim small"
                          onClick={() => {
                            changeData(el);
                          }}
                        >
                          선택
                        </button>
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        </section>
      </div>
    </aside>
  );
}
