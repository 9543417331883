export default function DupClassList({ closeModal, changeData, currentData }) {
  return (
    <aside id="dupClassCheck" className="modal dim select">
      <div className="modal_content_wrap">
        <header>
          <h2>중복수업</h2>
          <div className="btn_group">
            <button className="close btn_default" onClick={closeModal}>
              닫기
            </button>
          </div>
        </header>
        <section className="table_scroll_wrap mt_52">
          <table className="detail fixed">
            <colgroup>
              <col width={"250px"} />
              <col width={"250px"} />
            </colgroup>
            <tr>
              <th>수업명</th>
              <th>수업시간</th>
            </tr>
            {currentData.data.map((el) => {
              return (
                <tr>
                  <td className="hide_text">{el.class_name}</td>
                  <td>
                    {el.start_time}-{el.end_time}
                  </td>
                </tr>
              );
            })}
          </table>
        </section>
      </div>
    </aside>
  );
}
