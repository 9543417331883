import { useEffect, useState } from "react";
import useRequestAPI from "hooks/useRequestAPI";

export default function JoinRegistration() {
  const [invitationCode, setInvitationCode] = useState("");
  const [academyData, setAcademyData] = useState(false);
  const { requestAPI, logout, alertMsg, setMessage } = useRequestAPI();

  const submitData = async () => {
    const res = await requestAPI("post", "academy/input-invitation", {
      invitation_code: invitationCode,
    });
    if (!res) return;
    setMessage(alertMsg.add);
    const data = res.data.data;
    localStorage.setItem("academy_id", data.academy_id);
    localStorage.setItem("manager_id", data.manager_id);
    setAcademyData(data);
  };

  useEffect(() => {
    if (invitationCode.length === 6) submitData();
    else setAcademyData(false);
  }, [invitationCode]);

  return (
    <main id="joinRegistration" className="container align_center nav_none">
      <section className="join_invitee_wrap">
        <section className="invitee_code_wrap">
          <header>
            <h1>초대 코드를 입력해 주세요.</h1>
            <figure>
              {[...new Array(6)].map((el, idx) => {
                return (
                  <label key={idx} htmlFor="invitationCode">
                    {invitationCode[idx]}
                  </label>
                );
              })}
              <input
                type="text"
                className="hidden"
                id="invitationCode"
                value={invitationCode}
                maxLength={6}
                onChange={(e) => {
                  if (isNaN(e.target.value)) return;
                  setInvitationCode(e.target.value);
                }}
                readOnly={academyData}
              />
            </figure>
          </header>
        </section>
        <section
          className={"invitee_academy_info_wrap " + (academyData && "pass")}
        >
          <header>
            <h1>{academyData.academy_name}</h1>
            <p>
              학원코드 : {academyData.academy_code}
              <br />
              <span>( 사업장 전화번호 뒷 4자리 )</span>
              <br />
            </p>
          </header>
          <div className="btn_group pt_40">
            <button className="btn_default" onClick={logout}>
              로그인 페이지로 이동
            </button>
          </div>
        </section>
      </section>
    </main>
  );
}
