import { useEffect, useState } from "react";
import axios from "axios";
import useRequestAPI from "hooks/useRequestAPI";
import useSelectBox from "hooks/useSelectBox";
import { url } from "variable/url";
import { useNavigate } from "react-router-dom";

export default function ChangeTeacherState({
  closeModal,
  changeData,
  currentData,
}) {
  const navigate = useNavigate();
  const [SelectBox, selectValue, setSelectValue, setSelectList] = useSelectBox(
    []
  );
  const { requestAPI, alertMsg, setMessage } = useRequestAPI();
  const [, setResData] = useState();

  const getData = async () => {
    try {
      const res = await axios.get(url.server + "manager/grade");
      const data = res.data.data;
      setSelectList(data.manager_grade);
      setSelectValue(
        data.manager_grade.find((el) => {
          return el.name === currentData.data.info.mna_grade;
        })
      );
      setResData(currentData.data.info.mna_grade);
    } catch (err) {
      setMessage(err);
    }
  };

  const submitData = async () => {
    const body = {
      mna_teacher_id: currentData.data.auth.mna_teacher_id,
      mna_manager_id: currentData.data.auth.mna_manager_id,
      code: selectValue.code,
    };
    const res = await requestAPI("post", "academy/managers/grade", body);
    if (!res) return;
    setMessage(alertMsg.change);
    if (
      localStorage.getItem("manager_id") == currentData.data.auth.mna_manager_id
    ) {
      localStorage.setItem("grade_code", selectValue.code);
      if (selectValue.name === "선생님") {
        return navigate("/academy/teachers/1");
      }
    }
    changeData(res);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <aside id="changeTeacherState" className="modal dim">
      <div className="modal_content_wrap">
        <header>
          <h2>권한변경</h2>
          <div className="btn_group">
            <button className="close btn_default" onClick={closeModal}>
              닫기
            </button>
          </div>
        </header>

        <table>
          <thead>
            <tr>
              <th>등급</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="cell_content_wrap">
                  <SelectBox />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="btn_group mt_52">
          <button className="btn_default" onClick={submitData}>
            저장하기
          </button>
        </div>
      </div>
    </aside>
  );
}
