import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Pagination from "components/Pagination";
import useModalHandler from "hooks/useModalHandler";
import useSelectBox from "hooks/useSelectBox";
import useRequestAPI from "hooks/useRequestAPI";
import formatPhoneNumber from "utils/formatPhoneNumber";
import formatExcelTitle from "utils/formatExcelTitle";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export default function AcademyRetireeTeachers() {
  const { id } = useParams();
  const { ModalComponent } = useModalHandler();
  const [SelectBox, selectValue] = useSelectBox([
    { name: "전체", code: "all" },
    { name: "이름", code: "name" },
    { name: "수업", code: "class_name" },
  ]);
  const [searchKeyword, setSearchKeword] = useState("");
  const [currPage, setCurrPage] = useState(id - 1);
  const { requestAPI, Loading } = useRequestAPI();
  const [resData, setResData] = useState();
  const [currSearchData, setCurrSearchData] = useState({
    page: currPage,
    size: 5,
    search_type: "all",
    search_keyword: "",
  });

  const [McBoardContent, selectMcBoardContent] = useSelectBox([
    { name: "휴대폰 번호", code: "qt_phone_number" },
    { name: "생년월일", code: "qt_birth_date" },
    { name: "성별", code: "qt_gender" },
    { name: "수업명", code: "qt_class_name" },
  ]);

  const listDataFormat = (type, data) => {
    switch (type) {
      case "qt_phone_number": {
        return formatPhoneNumber(data.qt_phone_number);
      }
      case "qt_class_name": {
        return data.qt_class_name.map((el) => (
          <>
            {el}
            <br />
          </>
        ));
      }
      default: {
        return data[type];
      }
    }
  };

  const getData = async (e) => {
    e?.preventDefault();
    let body = { ...currSearchData, page: currPage };
    if (e?.target.tagName === "BUTTON") {
      body = {
        page: currPage,
        size: 5,
        search_type: selectValue?.code,
        search_keyword: searchKeyword,
      };
      setCurrSearchData(body);
      return setCurrPage(0);
    }
    const res = await requestAPI("post", "academy/managers/quit", body);
    if (!res) return;
    setResData(res.data.data);
  };

  useEffect(() => {
    getData();
  }, [currPage, currSearchData]);

  return (
    <div className="inner">
      <Loading />
      <section className="page_handling_wrap">
        <form className="search_wrap">
          <SelectBox />
          <input
            type="text"
            onChange={(e) => {
              setSearchKeword(e.target.value);
            }}
          />
          <div className="btn_group">
            <button className="btn_default search" onClick={getData}>
              검색
            </button>
          </div>
        </form>
        <Pagination
          currPage={currPage}
          setCurrPage={setCurrPage}
          lastPage={resData?.managers.totalPages || 0}
        />
      </section>

      <section className="board_wrap">
        <header>
          <h2>퇴직자</h2>
          <div className="btn_group">
            <ReactHTMLTableToExcel
              className="btn_default excel"
              table="table"
              filename={formatExcelTitle("퇴직자목록")}
              sheet="tableSheet"
              buttonText="엑셀다운로드"
            />
          </div>
        </header>
        <table id="table">
          <colgroup className="mobile">
            <col width={"30%"} />
            <col width={"auto"} />
            <col width={"30%"} />
          </colgroup>
          <colgroup className="desktop">
            <col width={"15%"} />
            <col width={"10%"} />
            <col width={"20%"} />
            <col width={"10%"} />
            <col width={"10%"} />
            <col width={"20%"} />
            <col width={"15%"} />
          </colgroup>
          <thead>
            <tr className="mobile">
              <th>이름</th>
              <th>
                <McBoardContent />
              </th>
              <th>퇴직일</th>
            </tr>
            <tr className="desktop">
              <th>번호</th>
              <th>이름</th>
              <th>휴대폰번호</th>
              <th>생년월일</th>
              <th>성별</th>
              <th>수업</th>
              <th>퇴직일</th>
            </tr>
          </thead>
          <tbody>
            {resData && !resData.managers.content[0] && (
              <tr>
                <td colSpan={8} className="desktop">
                  표시할 데이터가 없습니다.
                </td>
                <td colSpan={3} className="mobile">
                  표시할 데이터가 없습니다.
                </td>
              </tr>
            )}
            {resData?.managers.content.map((el, idx) => (
              <tr key={idx}>
                <td className="desktop">
                  {resData.managers.totalElements - currPage * 5 - idx}
                </td>
                <td>{listDataFormat("qt_name", el)}</td>
                {/* 모바일 */}
                <td className="mobile">
                  {listDataFormat(selectMcBoardContent.code, el)}
                </td>
                {/* 모바일 */}
                <td className="desktop">
                  {listDataFormat("qt_phone_number", el)}
                </td>
                <td className="desktop">
                  {listDataFormat("qt_birth_date", el)}
                </td>
                <td className="desktop">{listDataFormat("qt_gender", el)}</td>
                <td className="desktop">
                  {listDataFormat("qt_class_name", el)}
                </td>
                <td>{listDataFormat("qt_end_date", el)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      <ModalComponent />
    </div>
  );
}
