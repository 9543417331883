import { useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import useRequestAPI from "./useRequestAPI";
import formatDate from "utils/formatDate";
import axios from "axios";

export default function useFileHandler(type, size = 1, length = 1) {
  // (확장자, 용량(메가단위), 파일개수)
  const fileInputRef = useRef(null);
  const { requestAPI, alertMsg, setMessage } = useRequestAPI();
  const [fileData, setFileData] = useState([]); // 업로드한 파일을 API 리퀘스트 양식에 맞게 변환한 데이터의 배열

  const uploadFile = (e) => {
    // 파일 인풋의 onChange 이벤트에 연결
    let copy = [...fileData];
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files[i];
      const Location = URL.createObjectURL(file);
      if (type && !type.includes(file.type.split("/")[1].toLowerCase())) {
        // 확장자 검사
        return setMessage(
          `${type.join(",")} 형식의 파일만 업로드 할 수 있습니다.`
        );
      }
      if (file.size > size * 1024000) {
        // 파일 용량 검사
        return setMessage(`하나의 파일당 ${size}MB까지 첨부 할 수 있습니다.`);
      }
      if (length !== 1 && copy.length >= length) {
        // 파일 개수 검사
        return setMessage(`파일은 최대 ${length}개까지 첨부 할 수 있습니다.`);
      }
      if (length === 1) {
        // 파일 개수가 1개로 제한되어있다면
        return setFileData([{ file, name: file.name, Location }]);
      }
      copy.push({
        // 파일 데이터 리퀘스트 양식
        file,
        name: file.name,
        Location,
        index: fileData.length,
      });
    }
    setFileData(copy);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const deleteFile = (e) => {
    // 업로드한 파일을 삭제할 때 (fileData 배열에서 지울 때) 사용
    // 삭제버튼 태그의 onClick 이벤트에 연결하며 이 때 태그의 data-index 속성이 해당 파일의 index와 일치 해야함
    // (어차피 선택된 파일목록은 반복문으로 생성할 테니 반복문 함수의 index를 그대로 걸어주면 매칭 된다.)

    let copy = [...fileData];
    copy.splice(e.target.dataset.index, 1);
    setFileData(
      copy.map((el, idx) => {
        return { ...el, index: idx };
      })
    );
  };

  const uploadS3 = async (type, target = fileData, url) => {
    // 파일을 s3로 업로드하는 함수
    const dataArray = [];
    const date = formatDate(new Date(), "").slice(0, -2);
    let index = 0;

    target = target.map((el) => {
      const uniqueKey = uuidv4();
      const fileExtension = el.name.split(".").pop().toLowerCase();
      const root = type === "teacher" ? "teacher" : "academy";
      return { ...el, uuid: `${root}/${date}/${uniqueKey}.${fileExtension}` };
    });

    const fileNamaList = target.filter((el) => el.file).map((el) => el.uuid);
    const apiURL = url || "common/s3/" + type + "/signed-upload";
    const res = fileNamaList[0]
      ? await requestAPI("post", apiURL, {
          file_name: target.filter((el) => el.file).map((el) => el.uuid),
        })
      : false;

    for (let el of target) {
      if (el.file) {
        try {
          await axios.put(res.data.data.signed_url[index], el.file);
          index++;
        } catch (err) {
          setMessage(alertMsg.error_file);
          return false;
        }
      }
      dataArray.push({
        name: el.name,
        Key: el.Key || el.uuid,
        index,
      });
    }
    // console.log(dataArray);
    return dataArray;
  };

  return {
    fileData,
    setFileData,
    fileInputRef,
    uploadFile,
    deleteFile,
    uploadS3,
  };
}
