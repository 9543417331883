import { Link } from "react-router-dom";
import ReturnToList from "components/ReturnToList";

export default function AcademyShuttleType() {
  return (
    <div className="inner select_type">
      <header>
        <h2>셔틀버스 등록</h2>
        <div className="btn_group">
          <ReturnToList />
        </div>
      </header>
      <section className="content_wrap">
        <div className="btn_group type mt_80">
          <button className="btn_default type half">
            <Link to="/academy/shuttle/add/reg">정기</Link>
          </button>
          <button className="btn_default type half yellow">
            <Link to="/academy/shuttle/add/temp">임시</Link>
          </button>
        </div>
      </section>
    </div>
  );
}
