import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import axios from "axios";
import { isLoginAtom, messageAtom, headerTitleAtom } from "atoms/atoms";
import { url } from "variable/url";
import ic_side_menu from "assets/img/icon/ic_side_menu.png";
import ic_back from "assets/img/icon/ic_back.png";
import ic_side_home from "assets/img/icon/ic_side_home.png";
import ic_side_my_page from "assets/img/icon/ic_side_my_page.png";
import ic_my_page_active from "assets/img/icon/ic_my_page_active.png";
import ic_side_academy_setting from "assets/img/icon/ic_side_academy_setting.png";
import ic_academy_setting_active from "assets/img/icon/ic_academy_setting_active.png";
import ic_side_student from "assets/img/icon/ic_side_student.png";
import ic_side_logout from "assets/img/icon/ic_side_logout.png";
import ic_siic_side_student_acitvede_menu from "assets/img/icon/ic_side_student_acitve.png";

export default function Header() {
  const setMessage = useSetRecoilState(messageAtom);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const [headerTitle] = useRecoilState(headerTitleAtom);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const mobileSideMenu = () => {
    if (
      ["/auth", "/add", "/edit", "/detail/", "type"].some((el) =>
        pathname.includes(el)
      )
    ) {
      return false;
    } else return true;
  };

  const mobileHeaderTitle = () => {
    // 디테일,에딧,에드 라는 문자가 URL에 포함되면 헤더 타이틀을 학원명>페이지 제목으로 변경
    if (
      ["/auth", "/add", "/edit", "/detail/", "type", "/mypage"].some((el) =>
        pathname.includes(el)
      )
    ) {
      return headerTitle;
    } else if (localStorage.getItem("academy_name"))
      return (
        <span
          onClick={() => {
            navigate("/home");
          }}
        >
          {localStorage.getItem("academy_name")}
        </span>
      );
    else return "";
  };

  const exceptPage = ["/", "/qna", "/login"];
  const navPage = [
    "home",
    "notification",
    "homework",
    "material",
    "student",
    "academy",
  ];

  const icons = [ic_side_my_page, ic_side_student, ic_side_academy_setting];
  const iconsActive = [
    ic_my_page_active,
    ic_academy_setting_active,
    ic_siic_side_student_acitvede_menu,
  ];

  const headerMenu = [
    {
      name: "홈",
      path: "/home",
      mobile: true,
    },
    {
      name: "알림",
      path: "/notification/1",
      mobile: true,
    },
    {
      name: "숙제",
      path: "/homework/1",
      mobile: true,
    },
    {
      name: "준비물",
      path: "/material/1",
      mobile: true,
    },
    {
      name: "학생관리",
      path: "/student/1",
      mobile: false,
    },
    {
      name: "학원정보관리",
      path: "/academy/info",
      mobile: false,
    },
  ];

  const sideMenuList = [
    {
      name: "내정보관리",
      id: "/mypage",
      path: "/mypage",
      isView: true,
    },
    {
      name: "학생관리",
      id: "/student",
      path: "/student/1",
      isView: localStorage.getItem("academy_id"),
    },
    {
      name: "학원정보관리",
      id: "/academy",
      path: "/academy/info",
      isView: localStorage.getItem("academy_id"),
    },
  ];

  const logout = async () => {
    await axios.put(url.server + "teacher/logout");
    setIsSideMenuOpen(false);
    setIsLogin(false);
    localStorage.clear();
    return navigate("/login");
  };

  if (!exceptPage.includes(pathname.toLowerCase()))
    return (
      <header
        id="header"
        className={mobileSideMenu() && pathname !== "/mypage" ? "show_nav" : ""}
      >
        <section className="login_info desktop">
          <h1 className="title desktop">
            {localStorage.getItem("academy_name") ? (
              <Link to="/home">
                <strong>{localStorage.getItem("academy_name", true)} _ </strong>
                <span> in 에듀빌리</span>
              </Link>
            ) : (
              <Link to={!isLogin ? "/" : "/auth/join/step2"}>
                <strong>에듀빌리</strong>
              </Link>
            )}
          </h1>
          {localStorage.getItem("teacher_name") && (
            <ul>
              <li className="my_wrap">
                <span
                  className="pointer"
                  onClick={() => {
                    if (pathname === "/auth/change-pw") {
                      return setMessage("임시 비밀번호를 변경해 주세요.");
                    }
                    navigate("/mypage");
                  }}
                >
                  {localStorage.getItem("teacher_name")} 선생님
                </span>
                &nbsp;안녕하세요!
              </li>
              <li className="logout_wrap">
                <button type="button" onClick={logout}>
                  로그아웃
                </button>
              </li>
            </ul>
          )}
        </section>

        <section className="login_info mobile">
          <h1 className="side_menu_icon">
            {mobileSideMenu() ? (
              <img
                src={ic_side_menu}
                alt=""
                onClick={() => {
                  setIsSideMenuOpen(true);
                }}
              />
            ) : (
              <img
                src={ic_back}
                alt=""
                className="back"
                onClick={() => {
                  navigate(-1);
                }}
              />
            )}
          </h1>
          <b className="header_title">{mobileHeaderTitle()}</b>
        </section>

        {isSideMenuOpen && (
          <ul className="side_menu mobile">
            <li
              className="dim"
              onClick={() => {
                setIsSideMenuOpen(false);
              }}
            ></li>

            <li className="home_btn">
              <div className="icon_wrap">
                <img src={ic_side_home} alt="" />
              </div>
              <span
                onClick={() => {
                  if (!localStorage.getItem("academy_id"))
                    return setMessage("로그인 시 학원코드를 입력해주세요.");
                  navigate("/home");
                  setIsSideMenuOpen(false);
                }}
              >
                학원 홈
              </span>
              <button
                className="close"
                onClick={() => {
                  setIsSideMenuOpen(false);
                }}
              >
                &times;
              </button>
            </li>
            {sideMenuList.map(
              (el, idx) =>
                el.isView && (
                  <li
                    key={idx}
                    className={pathname.includes(el.id) && "active"}
                    onClick={() => {
                      navigate(el.path);
                      setIsSideMenuOpen(false);
                    }}
                  >
                    <div className="icon_wrap">
                      <img
                        src={
                          pathname.includes(el.id)
                            ? iconsActive[idx]
                            : icons[idx]
                        }
                        alt=""
                      />
                    </div>
                    <span>{el.name}</span>
                  </li>
                )
            )}
            <li className="gap"></li>
            <li className="logout" onClick={logout}>
              <div className="icon_wrap">
                <img src={ic_side_logout} alt="" />
              </div>
              <span>로그아웃</span>
            </li>
          </ul>
        )}

        {navPage.some((el) => pathname.includes(el)) && (
          <nav className="gnb desktop">
            <ul>
              {headerMenu.map((el, idx) => (
                <li
                  className={
                    el.path.split("/")[1] === pathname.split("/")[1] && "active"
                  }
                >
                  <Link key={idx} to={el.path}>
                    {el.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        )}

        {navPage.some((el) => pathname.includes(el)) && (
          <nav className="gnb mobile">
            <ul>
              {headerMenu
                .filter((el) => el.mobile)
                .map((el, idx) => (
                  <li
                    className={
                      el.path.split("/")[1] === pathname.split("/")[1] &&
                      "active"
                    }
                  >
                    <Link key={idx} to={el.path}>
                      {el.name}
                    </Link>
                  </li>
                ))}
            </ul>
          </nav>
        )}
      </header>
    );
}
