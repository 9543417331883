import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Messenger from "routes/messenger/Messenger";
import useInputHandler from "hooks/useInputHandler";
import useStudentCheckBox from "hooks/useStudentCheckBox";
import useFileHandler from "hooks/useFileHandler";
import useDatePicker from "hooks/useDatePicker";
import useRequestAPI from "hooks/useRequestAPI";
import ReturnToList from "components/ReturnToList";
import formatDate from "utils/formatDate";

export default function HomeworkEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [dataForm, setDataForm, attributes] = useInputHandler({});
  const { listData, StudentList, SelectList, submitDataFormat } =
    useStudentCheckBox(state.resData);
  const {
    fileData,
    setFileData,
    fileInputRef,
    uploadFile,
    deleteFile,
    uploadS3,
  } = useFileHandler(["jpg", "jpeg", "png", "gif"], 1, 3);
  const { DatePickerModule, specificDate } = useDatePicker({
    specific: new Date(state.resData.due_date),
  });
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();
  const [isMessengerMode, setIsMessengerMode] = useState({
    state: false,
    mode: "",
  });

  const submitConditionCheck = () => {
    if (!submitDataFormat[0]) {
      setMessage("대상을 선택해주세요.");
      return false;
    }
    if (50 > dataForm.reward || dataForm.reward > 100) {
      setMessage("포인트는 50P~100P 까지 지급 가능합니다.");
      return false;
    }
    if (!dataForm.desc && !fileData[0]) {
      setMessage(alertMsg.null_contetn_file);
      return false;
    }
    let checkDueDate = listData
      .map((el) => {
        return el.class_times.map((el2) => el2.week);
      })
      .flat();
    checkDueDate = [...new Set(checkDueDate)];
    const days = ["일", "월", "화", "수", "목", "금", "토"];
    if (!checkDueDate.includes(days[specificDate.getDay()])) {
      setMessage({
        type: "confirm",
        text: "숙제 마감일의 요일이 선택된 수업들의 수업 요일에 해당되지 않습니다. 그래도 등록하시겠습니까?",
        confirm: () => setIsMessengerMode({ state: true, mode: "submit" }),
      });
    } else setIsMessengerMode({ state: true, mode: "submit" });
  };

  const submitData = async () => {
    const homeworkFileData = fileData[0] ? await uploadS3("manager") : [];
    const deleteStudents = state.resData.student_list
      .filter((el) => {
        return !submitDataFormat[0]?.student_list.find(
          (el2) => el2.student_id === el.student_id
        );
      })
      .map((el) => {
        return { ...el, is_deleted: true };
      });

    const newStudents = submitDataFormat[0].student_list
      .filter((el) => {
        return !state.resData.student_list.find(
          (el2) => el2.student_id === el.student_id
        );
      })
      .map((el) => el.student_id);

    const body = {
      ...dataForm,
      id,
      class_id: state.resData.class_id,
      type: "homework",
      notification_list: [
        ...submitDataFormat[0].student_list,
        ...deleteStudents,
      ],
      new_student_list: newStudents,
      file_list: homeworkFileData,
      due_date: formatDate(specificDate),
      desc: `[숙제]%&${dataForm.desc.replace(
        /\r?\n/g,
        "%&"
      )}%&기한 : ${formatDate(specificDate)} 까지`,
      desc_text: dataForm.desc.replace(/\r?\n/g, "%&"),
    };
    // console.log(body);
    const res = await requestAPI("patch", "notify/update", body);
    if (!res) return;
    setMessage({ text: alertMsg.edit, confirm: () => navigate("/homework/1") });
  };

  useEffect(() => {
    setDataForm({
      desc: state.resData.desc_text,
      memo: state.resData.memo,
      reward: state.resData.reward,
    });
    if (state.resData.attach_files) setFileData(state.resData.attach_files);
  }, []);

  return (
    <>
      <Loading />
      {isMessengerMode.state ? (
        <Messenger
          mode={isMessengerMode.mode}
          setIsMessengerMode={setIsMessengerMode}
          resData={{
            student_list: submitDataFormat.map((el) => el.student_list).flat(1),
            attach_files: fileData[0] ? fileData : null,
            desc: dataForm.desc,
            due_date: formatDate(specificDate, "."),
          }}
          submitData={submitData}
        />
      ) : (
        <div id="homework" className="container background_white">
          <div className="inner">
            <header>
              <h2>숙제 수정</h2>
              <div className="btn_group short mobile">
                <button
                  className="btn_default small fs_12"
                  onClick={() => {
                    setIsMessengerMode({ state: true, mode: "view" });
                  }}
                >
                  메시지형태로 보기
                </button>
              </div>
              <div className="btn_group desktop">
                <button
                  className="btn_default"
                  onClick={() => {
                    setIsMessengerMode({ state: true, mode: "view" });
                  }}
                >
                  메시지형태로 보기
                </button>
                <ReturnToList />
              </div>
            </header>
            <div className="page_content_wrap">
              <section className="input_main_wrap">
                <div className="input_select_wrap">
                  <h3>학생목록</h3>
                  <StudentList />
                </div>
                <div className="input_select_wrap">
                  <h3>대상</h3>
                  <SelectList />
                </div>
                <div className="input_select_wrap content">
                  <h3>내용</h3>
                  <div className="input_select_item">
                    <textarea
                      {...attributes("desc")}
                      placeholder="내용을 입력해주세요."
                    ></textarea>
                  </div>
                </div>
              </section>

              <section className="input_sub_wrap">
                <table className="detail">
                  <tr className="mobile">
                    <th>마감일</th>
                    <td colSpan={3}>
                      <div className="cell_content_wrap">
                        <DatePickerModule type="specific" />
                      </div>
                    </td>
                  </tr>

                  <tr className="mobile reward">
                    <th>포인트</th>
                    <td colSpan={3}>
                      <div className="cell_content_wrap">
                        <input
                          type="text"
                          className={dataForm.reward < 50 && "error"}
                          {...attributes("reward")}
                          data-format="number"
                        />
                        P<span className="fs_12">※50~100 사이</span>
                      </div>
                    </td>
                  </tr>

                  <tr className="desktop">
                    <th>마감일</th>
                    <td>
                      <div className="cell_content_wrap">
                        <DatePickerModule type="specific" />
                      </div>
                    </td>
                    <th>포인트</th>
                    <td>
                      <div className="cell_content_wrap">
                        <input
                          type="text"
                          className={
                            "small " + (dataForm.reward < 50 && "error")
                          }
                          {...attributes("reward")}
                          data-format="number"
                        />
                        P<span className="fs_16">※50~100 사이</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>첨부파일</th>
                    <td colSpan={3}>
                      <div className="cell_content_wrap">
                        <div className="btn_group">
                          <button
                            htmlFor="file"
                            className="btn_default slim green3"
                          >
                            <label htmlFor="file">파일선택</label>
                          </button>
                          <input
                            ref={fileInputRef}
                            type="file"
                            className="hidden"
                            id="file"
                            onChange={uploadFile}
                            multiple
                          />
                        </div>
                        <ul className="file_list">
                          {fileData[0] &&
                            fileData.map((el) => {
                              return (
                                <li>
                                  <span>{el.name}</span>{" "}
                                  <button onClick={deleteFile}>x</button>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>메모</th>
                    <td colSpan={3}>
                      <div className="cell_content_wrap">
                        <input type="text" {...attributes("memo")} />
                      </div>
                    </td>
                  </tr>
                </table>
              </section>

              <div className="btn_group">
                <button></button>
                <button
                  className="btn_default half"
                  onClick={submitConditionCheck}
                >
                  재발송
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
