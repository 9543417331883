function getCookie(name, decode) {
  var value = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  value = value ? value[2] : null;
  if (decode) return decodeURIComponent(value);
  else return value;
}

function setCookie(name, value, hours) {
  var date = new Date();
  date.setTime(date.getTime() + hours * 60 * 60 * 1000); // 현재 시간에 시간 추가
  document.cookie =
    encodeURIComponent(name) +
    "=" +
    encodeURIComponent(value) +
    ";expires=" +
    date.toUTCString() +
    ";path=/";
}

function deleteCookie(name) {
  document.cookie =
    encodeURIComponent(name) + "=; expires=Thu, 01 JAN 1999 00:00:10 GMT";
}

function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;

    // "hide_popup_no"로 시작하는 쿠키는 삭제하지 않음
    if (!name.trim().startsWith("hide_popup_no")) {
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  }
}

export { getCookie, setCookie, deleteCookie, deleteAllCookies };
