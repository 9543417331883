import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isLoginAtom } from "atoms/atoms";
import useRequestAPI from "hooks/useRequestAPI";
import formatDate from "utils/formatDate";
import stamp from "assets/img/icon/stamp.png";
import axios from "axios";
import { url } from "variable/url";

export default function JoinCreateConfirm() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [, setIsLogin] = useRecoilState(isLoginAtom);
  const { requestAPI } = useRequestAPI();
  const [fields, setFields] = useState();

  const getData = async () => {
    const res = await requestAPI("post", "academy/field", {});
    if (!res) return;
    setFields(
      res.data.data
        .filter((el) => state.data.fields.includes(el.fd_code))
        .map((el) => el.fd_name)
        .join(", ")
    );
  };

  const logout = async () => {
    await axios.put(url.server + "teacher/logout");
    localStorage.clear();
    setIsLogin(false);
    return navigate("/login");
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <main id="joinCreateConfirm" className="container">
      <section>
        <figure>
          <header>
            <h1>
              <strong>밀리빌리 수학학원</strong>
              <br />
              에듀빌리에 입주를 환영합니다.
            </h1>
          </header>
          <table className="">
            <tr>
              <th>대표자</th>
              <td>{state?.data.ceo_name}</td>
            </tr>
            <tr>
              <th>개업일자</th>
              <td>{formatDate(state?.data.opening_date, ".")}</td>
            </tr>
            <tr>
              <th>사업장 주소</th>
              <td>
                {state?.data.address}
                {state?.data?.address_detail}
              </td>
            </tr>
            <tr>
              <th>학원코드</th>
              <td>{state?.data.code}</td>
            </tr>
            <tr>
              <th>분야</th>
              <td>{fields || ""}</td>
            </tr>
          </table>

          <img src={stamp} alt="" />
        </figure>
        <div className="btn_group">
          <button className="btn_default" onClick={logout}>
            로그인 화면으로 돌아가기
          </button>
        </div>
      </section>
    </main>
  );
}
