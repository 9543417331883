import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReturnToList from "components/ReturnToList";
import useRequestAPI from "hooks/useRequestAPI";
import formatPhoneNumber from "utils/formatPhoneNumber";
import formatText from "utils/formatText";
import permissionCheck from "utils/permissionCheck";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

export default function AcademyShuttleAdd() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { requestAPI, Loading } = useRequestAPI();
  const [resData, setResData] = useState();

  const getData = async () => {
    const res = await requestAPI("post", "academy/shuttle-bus/detail", {
      bus_id: id,
    });
    if (!res) return;
    setResData(res.data.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="inner shuttle_add">
      <Loading />
      <header>
        <h2>
          셔틀버스 상세({{ 0: "정기", 1: "임시" }[resData?.bus.schedule_type]})
        </h2>
        <div className="btn_group">
          <ReturnToList />
        </div>
      </header>
      <section className="content_wrap">
        <table className="detail desktop">
          <tr>
            <th>버스이름</th>
            <td colSpan={2}>
              <div className="cell_content_wrap">{resData?.bus.bus_name}</div>
            </td>
            <th>차량번호</th>
            <td>
              <div className="cell_content_wrap">{resData?.bus.bus_no}</div>
            </td>
          </tr>
          <tr>
            <th>운전자명</th>
            <td colSpan={2}>
              <div className="cell_content_wrap">
                {resData?.bus.driver_name}
              </div>
            </td>
            <th>
              운전자
              <br />
              전화번호
            </th>
            <td>
              <div className="cell_content_wrap">
                {formatPhoneNumber(resData?.bus.driver_phone_number)}
              </div>
            </td>
          </tr>
          <tr>
            <th rowSpan={3}>운행정보</th>
            <td>종류</td>
            <td className="shuttle_type">
              <div className="cell_content_wrap">
                {resData?.bus.direction === "0" ? "등원" : "하원"}
              </div>
            </td>
            <th rowSpan={3}>운행요일</th>
            <td rowSpan={3}>
              <div className="cell_content_wrap pt_12 pb_12">
                {resData?.bus.operation_date
                  .sort((a, b) => a - b)
                  .map((el) => {
                    return ["월", "화", "수", "목", "금", "토", "일"][el];
                  })
                  .join(", ")}
              </div>
            </td>
          </tr>
          <tr>
            <td>출발시간</td>
            <td className="start_time">
              <div className="cell_content_wrap">
                {resData?.bus.departure_time?.slice(0, -3)}
              </div>
            </td>
          </tr>
          <tr>
            <td>도착시간</td>
            <td className="start_time">
              <div className="cell_content_wrap">
                {resData?.bus.arrival_time?.slice(0, -3)}
              </div>
            </td>
          </tr>
          <tr>
            <th>정류장 정보</th>
            <td colSpan={4} className="station_list">
              <div className="cell_content_wrap">
                <table className="detail border_none">
                  <colgroup>
                    <col width="15%" />
                    <col width="40%" />
                    <col width="auto" />
                    <col width="auto" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>명칭</th>
                      <th>주소 (클릭하여 조회)</th>
                      <th>상세위치</th>
                      <th>탑승시간</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resData?.bus_stop_list.map((el, idx) => (
                      <tr key={idx}>
                        <td className="hide_text">{el.station}</td>
                        <td className="hide_text" title={el.address}>
                          {el.address}
                        </td>
                        <td className="hide_text">{el.location}</td>
                        <td>{el.boarding_time?.slice(0, -3)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <th>담당 선생님</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                {resData?.bus.manager_name} (
                {formatPhoneNumber(resData?.bus.manager_phone_number)})
              </div>
            </td>
          </tr>
          {resData?.direction === "0" && (
            <tr>
              <th>적용일정</th>
              <td colSpan={4}>
                <div className="cell_content_wrap">
                  {resData.bus.start_date} ~ {resData.bus.end_date}
                </div>
              </td>
            </tr>
          )}

          <tr>
            <th>메모</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                {formatText(resData?.bus.memo)}
              </div>
            </td>
          </tr>
        </table>

        <table className="detail mobile fixed">
          <tr>
            <th>버스이름</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">{resData?.bus.bus_name}</div>
            </td>
          </tr>

          <tr>
            <th>차량번호</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">{resData?.bus.bus_no}</div>
            </td>
          </tr>

          <tr>
            <th>운전자명</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                {resData?.bus.driver_name}
              </div>
            </td>
          </tr>

          <tr>
            <th>
              운전자
              <br />
              전화번호
            </th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                {formatPhoneNumber(resData?.bus.driver_phone_number)}
              </div>
            </td>
          </tr>

          <tr>
            <th rowSpan={3}>운행정보</th>
            <td>종류</td>
            <td colSpan={3} className="shuttle_type">
              <div className="cell_content_wrap">
                {resData?.bus.direction === "0" ? "등원" : "하원"}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              출발
              <br />
              시간
            </td>
            <td colSpan={3} className="start_time">
              <div className="cell_content_wrap">
                {resData?.bus.departure_time?.slice(0, -3)}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              도착
              <br />
              시간
            </td>
            <td colSpan={3} className="start_time">
              <div className="cell_content_wrap">
                {resData?.bus.arrival_time?.slice(0, -3)}
              </div>
            </td>
          </tr>

          <tr>
            <th>운행요일</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                {resData?.bus.operation_date
                  .sort((a, b) => a - b)
                  .map((el) => {
                    return ["월", "화", "수", "목", "금", "토", "일"][el];
                  })
                  .join(", ")}
              </div>
            </td>
          </tr>

          <tr>
            <td colSpan={5} className="station_list" style={{ border: "none" }}>
              <div className="flex jc_sp_bw ai_st">
                <Swiper pagination={true} modules={[Pagination]}>
                  {resData?.bus_stop_list.map((el, idx) => (
                    <SwiperSlide>
                      <div key={idx} className="table_wrap">
                        <table className={"detail border_none"}>
                          <colgroup>
                            <col width={"25%"} />
                            <col width={""} />
                          </colgroup>
                          <tr>
                            <th colSpan={2} className="title">
                              <div className="flex jc_sp_bw">
                                <strong>정류장 정보</strong>
                              </div>
                            </th>
                          </tr>
                          <tr>
                            <th>명칭</th>
                            <td>{el.station}</td>
                          </tr>

                          <tr>
                            <th>주소</th>
                            <td>{el.address}</td>
                          </tr>

                          <tr>
                            <th>상세위치</th>
                            <td>{el.location}</td>
                          </tr>

                          <tr>
                            <th>탑승시간</th>
                            <td>{el.boarding_time.slice(0, -3)}</td>
                          </tr>
                        </table>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </td>
          </tr>

          <tr>
            <th>담당 선생님</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                {resData?.bus.manager_name} (
                {formatPhoneNumber(resData?.bus.manager_phone_number)})
              </div>
            </td>
          </tr>
          {resData?.direction === "0" && (
            <tr>
              <th>적용일정</th>
              <td colSpan={4}>
                <div className="cell_content_wrap">
                  {resData.bus.start_date} ~ {resData.bus.end_date}
                </div>
              </td>
            </tr>
          )}

          <tr>
            <th>메모</th>
            <td colSpan={4}>
              <div className="cell_content_wrap">
                {formatText(resData?.bus.memo)}
              </div>
            </td>
          </tr>
        </table>

        <div className="btn_group">
          <button></button>
          <button
            className="btn_default half permission"
            onClick={() => {
              navigate("/academy/shuttle/edit/" + id, { state: { resData } });
            }}
            disabled={!permissionCheck(1)}
          >
            수정
          </button>
        </div>
      </section>
    </div>
  );
}
