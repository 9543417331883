import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo_mc from "assets/img/icon/logo_mc.png";
import ic_scroll_arrow from "assets/img/icon/ic_scroll_arrow.png";
import intro_tab_con1_mc from "assets/img/intro_tab_con1_mc.png";
import intro_tab_con2_mc from "assets/img/intro_tab_con2_mc.png";
import intro_tab_con3_mc from "assets/img/intro_tab_con3_mc.png";
import intro_tab_con4_mc from "assets/img/intro_tab_con4_mc.png";
import intro_tab_con5_mc from "assets/img/intro_tab_con5_mc.png";
import intro_message_owner_mc from "assets/img/intro_message_owner_mc.png";
import intro_message_teacher_mc from "assets/img/intro_message_teacher_mc.png";
import intro_service_list_mc from "assets/img/intro_service_list_mc.png";
import useModalHandler from "hooks/useModalHandler";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

export default function IntroMobile() {
  const navigate = useNavigate();
  const { ModalComponent, openModal, currentModal } = useModalHandler();
  const [startY, setStartY] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleTouchStart = (e) => {
    if (currentModal.type) return;
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    if (currentModal.type) return;
    if (!startY) return;
    const deltaY = e.touches[0].clientY - startY;
    if (deltaY > 50 && currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
      setStartY(null);
    } else if (deltaY < -50 && currentSlide < 2) {
      setCurrentSlide(currentSlide + 1);
      setStartY(null);
    }
  };

  return (
    <main
      id="intro"
      className="container mobile"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <ModalComponent />
      <section
        className="intro_wrap"
        style={{
          transform: `translate(0,-${currentSlide * window.innerHeight}px`,
        }}
      >
        <section className="intro_main">
          <section className="intro_tit">
            <img src={logo_mc} alt="" className="icon" />
            <p className="top_title">
              하나부터 열까지 에듀빌리
              <strong> 하나로</strong> 해결하세요.
            </p>
            <h1 className="title">
              성공으로 이끄는
              <br />
              <span className="tit_point">학원운영</span> 에듀빌리
            </h1>
            <h2 className="sub_title">
              #원생관리 #일정알림 #스마트한 학원 관리
            </h2>

            <div className="btn_group">
              <button className="login_btn" onClick={() => navigate("/login")}>
                로그인
              </button>
              <button
                className="sign_btn"
                onClick={() => navigate("/auth/join/step1")}
              >
                무료시작
              </button>
            </div>
            <div className="arrow_wrap">
              <img src={ic_scroll_arrow} alt="" className="arrow" />
            </div>
          </section>
        </section>

        <section className="intro_sub">
          <div className="">
            <Swiper
              className="mySwiper"
              pagination={true}
              modules={[Pagination]}
            >
              <SwiperSlide>
                <section className="intro_tab_con con1">
                  <header>
                    <h3>
                      손쉽고 유연한
                      <br />
                      <strong>결제 수납 관리</strong>
                    </h3>
                    <div className="bar"></div>
                    <p>
                      클릭 한번으로 복잡했던
                      <br />
                      결제관리를 해결할 수 있어요!
                    </p>
                  </header>
                  <img src={intro_tab_con1_mc} alt="" />
                </section>
              </SwiperSlide>
              <SwiperSlide>
                <section className="intro_tab_con con2">
                  <header>
                    <h3>
                      학생 개별 공지 발송
                      <br />
                      <strong>공지사항 알림</strong>
                    </h3>
                    <div className="bar"></div>
                    <p>
                      학생 개별에게 숙제, 알림, 준비물 등
                      <br />
                      클릭 한번에 전원 공지 발송이 가능해요!
                    </p>
                  </header>
                  <img src={intro_tab_con2_mc} alt="" />
                </section>
              </SwiperSlide>
              <SwiperSlide>
                <section className="intro_tab_con con2">
                  <header>
                    <h3>
                      한눈에 확인하는
                      <br />
                      <strong>다가오는 수업관리</strong>
                    </h3>
                    <div className="bar"></div>
                    <p>
                      학원의 모든 수업 데이터를 실시간으로
                      <br />
                      확인 가능하여 원활한 수업 운영이 가능해요!
                    </p>
                  </header>
                  <img src={intro_tab_con3_mc} alt="" />
                </section>
              </SwiperSlide>
              <SwiperSlide>
                <section className="intro_tab_con con2">
                  <header>
                    <h3>
                      학생관리 자동화
                      <br />
                      <strong>출결 및 학습현황</strong>
                    </h3>
                    <div className="bar"></div>
                    <p>
                      한눈에 들어오는 출결 및 학습현황 그래프로
                      <br />
                      효율적인 학생관리가 가능합니다.
                    </p>
                  </header>
                  <img src={intro_tab_con4_mc} alt="" />
                </section>
              </SwiperSlide>
              <SwiperSlide>
                <section className="intro_tab_con con2">
                  <header>
                    <h3>
                      안전한 등하원
                      <br />
                      <strong>셔틀버스 현황</strong>
                    </h3>
                    <div className="bar"></div>
                    <p>
                      셔틀버스 위치를 실시간으로 확인할 수 있고
                      <br />
                      아이들의 등하원 현황도 확인 가능해요.
                    </p>
                  </header>
                  <img src={intro_tab_con5_mc} alt="" />
                </section>
              </SwiperSlide>
            </Swiper>
          </div>

          <div className="intro_tab_con intro_service_list">
            <header>
              <h3>
                학원운영에만 집중할 수 있도록
                <br />
                <strong className="">꼭 필요한 기능만 담았습니다.</strong>
              </h3>
              <div className="bar"></div>
              <p>
                <img
                  src={intro_message_teacher_mc}
                  alt=""
                  className="speech_bubble"
                />
                <img
                  src={intro_message_owner_mc}
                  alt=""
                  className="speech_bubble"
                />
              </p>
            </header>

            <img src={intro_service_list_mc} alt="" />

            <div className="btn_group">
              <button
                className="btn_default"
                onClick={() => {
                  openModal("contactus");
                }}
              >
                문의하기
              </button>
            </div>
          </div>
        </section>
      </section>
      <script src="https://cdn.jsdelivr.net/npm/swiper@11/swiper-element-bundle.min.js"></script>
    </main>
  );
}
