import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "components/Pagination";
import ToggleButton from "components/ToggleButton";
import useModalHandler from "hooks/useModalHandler";
import useSelectBox from "hooks/useSelectBox";
import useRequestAPI from "hooks/useRequestAPI";
import formatPhoneNumber from "utils/formatPhoneNumber";
import formatDate from "utils/formatDate";
import permissionCheck from "utils/permissionCheck";
import formatExcelTitle from "utils/formatExcelTitle";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { useSetRecoilState } from "recoil";
import { messageAtom } from "atoms/atoms";

export default function AcademyTeachers() {
  const { id } = useParams();
  const { ModalComponent, openModal } = useModalHandler();
  const [SelectBox, selectValue] = useSelectBox([
    { name: "전체", code: "all" },
    { name: "수업", code: "class_name" },
    { name: "선생님명", code: "name" },
  ]);
  const [searchKeyword, setSerchKeword] = useState("");
  const [currPage, setCurrPage] = useState(id - 1);
  const { requestAPI, Loading } = useRequestAPI();
  const [resData, setResData] = useState();
  const [currSearchData, setCurrSearchData] = useState({
    page: currPage,
    size: 5,
    search_type: "all",
    search_keyword: "",
  });

  const [McBoardContent, selectMcBoardContent] = useSelectBox([
    { name: "휴대폰번호", code: "mna_phone_number" },
    { name: "생년월일", code: "mna_birth" },
    { name: "성별", code: "mna_gender" },
    { name: "수업", code: "mna_class_names" },
    { name: "상태", code: "mna_status" },
    { name: "권한", code: "mna_grade" },
  ]);

  const getData = async (e) => {
    e?.preventDefault();
    let body = { ...currSearchData, page: currPage };
    if (e?.target.tagName === "BUTTON") {
      body = {
        page: currPage,
        size: 5,
        search_type: selectValue?.code,
        search_keyword: searchKeyword,
      };
      setCurrSearchData(body);
      return setCurrPage(0);
    }
    const res = await requestAPI("post", "academy/managers", body);
    if (!res) return;
    setResData(res.data.data);
  };

  const submitData = async (e, mna_teacher_id, mna_manager_id) => {
    const body = {
      mna_is_view: e.target.checked,
      mna_teacher_id,
      mna_manager_id,
    };
    const res = await requestAPI("post", "academy/managers/is-view", body);
    if (!res) return;
    getData();
  };

  useEffect(() => {
    getData();
  }, [currPage, currSearchData]);

  return (
    <div className="inner">
      <Loading />
      <section className="page_handling_wrap">
        <form className="search_wrap">
          <SelectBox />
          <input
            type="text"
            onChange={(e) => {
              setSerchKeword(e.target.value);
            }}
          />
          <div className="btn_group">
            <button className="btn_default search" onClick={getData}>
              검색
            </button>
          </div>
        </form>
        <Pagination
          currPage={currPage}
          setCurrPage={setCurrPage}
          lastPage={resData?.managers.totalPages || 0}
        />
      </section>

      <section className="board_wrap">
        <header>
          <h2>재직자</h2>
          <div className="btn_group">
            <ReactHTMLTableToExcel
              className="btn_default excel"
              table="table"
              filename={formatExcelTitle("선생님목록")}
              sheet="tableSheet"
              buttonText="엑셀다운로드"
            />
            {permissionCheck(1) && (
              <button
                className="btn_default add"
                onClick={() => {
                  openModal("addTeacher");
                }}
              >
                선생님 등록
              </button>
            )}
          </div>
        </header>

        <table id="table">
          <colgroup className="mobile">
            <col width={"30%"} />
            <col width={"auto"} />
            <col width={"25%"} />
          </colgroup>
          <colgroup className="desktop">
            <col width={"5%"} />
            <col width={"10%"} />
            <col width={"15%"} />
            <col width={"10%"} />
            <col width={"5%"} />
            <col width={"auto"} />
            <col width={"5%"} />
            <col width={"10%"} />
            <col width={"10%"} />
          </colgroup>
          <thead>
            <tr className="mobile">
              <th>이름</th>
              <th>
                <McBoardContent />
              </th>
              <th>공개여부</th>
            </tr>
            <tr className="desktop">
              {[
                "번호",
                "이름",
                "휴대폰번호",
                "생년월일",
                "성별",
                "수업",
                "상태",
                "권한",
                "공개여부",
              ].map((item) => (
                <th className="hide_text">{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {resData && !resData.managers.content[0] && (
              <tr>
                <td colSpan={9} className="desktop">
                  표시할 데이터가 없습니다.
                </td>
                <td colSpan={3} className="mobile">
                  표시할 데이터가 없습니다.
                </td>
              </tr>
            )}

            {resData?.managers.content.map((el, idx) => (
              <TeacherItem
                key={idx}
                data={{
                  ...el,
                  mna_class_names: JSON.parse(el.mna_class_names),
                }}
                idx={idx}
                currPage={currPage}
                submitData={submitData}
                number={resData?.managers.totalElements - currPage * 5 - idx}
                selectMcBoardContent={selectMcBoardContent}
              />
            ))}
          </tbody>
        </table>
      </section>
      <ModalComponent />
    </div>
  );
}

function TeacherItem({ data, idx, submitData, number, selectMcBoardContent }) {
  const setMessage = useSetRecoilState(messageAtom);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(data.mna_is_view);
  useEffect(() => {
    setIsChecked(data.mna_is_view);
  }, [data]);

  const goToDetail = () => {
    if (!permissionCheck(1)) return setMessage("권한이 없습니다.");
    navigate("/academy/teachers/detail/" + data.mna_teacher_id, {
      state: {
        mna_teacher_id: data.mna_teacher_id,
        mna_manager_id: data.mna_manager_id,
      },
    });
  };
  const listDataFormat = (type, data) => {
    switch (type) {
      case "mna_phone_number": {
        return formatPhoneNumber(data.mna_phone_number);
      }
      case "mna_birth": {
        return formatDate(data.mna_birth, ".");
      }
      case "mna_class_names": {
        return !data.mna_class_names[0]
          ? "-"
          : data.mna_class_names.map((el, idx, arr) => {
              if (!arr[0]) return "_";
              return (
                <>
                  {el}
                  <br key={idx} />
                </>
              );
            });
      }
      default: {
        return data[type];
      }
    }
  };

  return (
    <tr>
      <td onClick={goToDetail} className="desktop">
        {number}
      </td>
      <td onClick={goToDetail}>{listDataFormat("mna_name", data)}</td>
      {/* 모바일 */}
      <td className="mobile" onClick={goToDetail}>
        {listDataFormat(selectMcBoardContent.code, data)}
      </td>
      {/* 모바일 */}
      <td onClick={goToDetail} className="desktop hide_text">
        {listDataFormat("mna_phone_number", data)}
      </td>
      <td onClick={goToDetail} className="desktop hide_text">
        {listDataFormat("mna_birth", data)}
      </td>
      <td onClick={goToDetail} className="desktop">
        {listDataFormat("mna_gender", data)}
      </td>
      <td onClick={goToDetail} className="desktop">
        {listDataFormat("mna_class_names", data)}
      </td>
      <td onClick={goToDetail} className="desktop hide_text">
        {listDataFormat("mna_status", data)}
      </td>
      <td onClick={goToDetail} className="desktop hide_text">
        {listDataFormat("mna_grade", data)}
      </td>
      <td>
        <ToggleButton
          index={idx}
          checked={isChecked}
          onChange={(e) => {
            if (!permissionCheck(1)) return alert("권한이 없습니다.");
            submitData(e, data.mna_teacher_id, data.mna_manager_id);
          }}
        />
      </td>
    </tr>
  );
}
