import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { isLoginAtom } from "atoms/atoms";
import useValidation from "hooks/useValidation";
import useNiceCert from "hooks/useNiceCert";
import logo from "assets/img/icon/logo.png";
import logo_mc from "assets/img/icon/logo_mc.png";
import { url } from "variable/url";
import axios from "axios";
import show_pw from "assets/img/icon/show_pw.png";
import { useSetRecoilState } from "recoil";
import { messageAtom } from "atoms/atoms";
import loginCheck from "utils/loginCheck";

export default function JoinStep_1() {
  const setMessage = useSetRecoilState(messageAtom);
  const navigate = useNavigate();
  const {
    form,
    validPass,
    errorCheck,
    isShowPw,
    visibilityPw,
    validAttributes,
  } = useValidation();
  const { openNicePopup, niceData } = useNiceCert();
  const [, setIsLogin] = useRecoilState(isLoginAtom);
  const [currentAgreement, setCurrentAgreement] = useState();
  const [agreement, setAgreement] = useState([]);
  const [checked, setChecked] = useState([]);

  const loadAgreementData = async () => {
    try {
      const res = await axios.get(url.server + "teacher/agreement");
      setAgreement(res.data.data);
    } catch {
      setMessage({
        text: "약관 정보를 불러오는데 실패했습니다. 잠시 후 다시 시도해주세요.",
        confirm: () => navigate(-1),
      });
    }
  };

  const checkAgreement = (e) => {
    if (e.target.checked) setChecked([...checked, e.target.id]);
    else setChecked([...checked].filter((el) => el !== e.target.id));
  };

  const checkRequiredItems = () => {
    const requiredItems = agreement
      .filter((el) => el.ag_required)
      .map((el) => el.ag_code);
    return requiredItems.every((el) => checked.includes(el));
  };

  const submitData = async () => {
    const formValue = {
      mb_email: form.mb_email.val,
      mb_password: form.mb_password.val,
      mb_confirm_password: form.mb_confirm_password.val,
    };
    const joinData = {
      ...niceData,
      ...formValue,
      agreements: checked,
    };
    try {
      const res = await axios.post(url.server + "teacher/sign-up", {
        ...joinData,
      });
      // 로그인 시키기
      await axios.post(url.server + "teacher/sign-in/confirm", {
        mb_ph: niceData.mb_ph,
        mb_password: form.mb_password.val,
      });
      const data = res.data.data;
      localStorage.setItem("teacher_id", data?.id);
      localStorage.setItem("teacher_name", joinData.mb_name);
      setIsLogin(true);
      setMessage({
        text: "회원가입이 완료되었습니다. 사업장 생성 혹은 선생님 등록을 해주세요.",
        confirm: () => navigate("/auth/join/step2"),
      });
    } catch (err) {
      let res = err?.response?.data.message || "error";
      if (res.length > 100) res = "error";
      setMessage({
        text: res,
        confirm: () => {
          if (err?.response?.data.status == 409) navigate("/login");
        },
      });
    }
  };

  useEffect(() => {
    loginCheck(
      () => {
        return setMessage({
          text: "로그아웃 후 다시 시도해 주세요.",
          confirm: () => navigate(-1),
        });
      },
      () => {
        openNicePopup();
        loadAgreementData();
      }
    );
  }, []);

  return (
    <main id="joinStep1" className="container auth">
      <section className="top_section">
        <img src={logo} alt="" className="desktop" />
        <img src={logo_mc} alt="" className="mobile" />
        <h1 className="desktop">
          성공으로 이끄는
          <br />
          <strong>학원운영</strong> 에듀빌리
        </h1>
      </section>
      {!niceData && (
        <section className="pending_nice center">
          휴대폰 인증 진행중입니다.
          <br />
          <br />
          인증 완료 전 팝업 종료 시, 좌측 상단의 에듀빌리를 클릭하며 처음부터
          진행 바랍니다.
        </section>
      )}
      {niceData && (
        <>
          <section className="input_group">
            <div className="input_item_group">
              <dl>
                <dt>이름</dt>
                <dd>
                  <input
                    type="text"
                    className="input"
                    value={niceData.mb_name}
                    readOnly
                  />
                </dd>
              </dl>
            </div>
            <div className="input_item_group">
              <dl>
                <dt>성별</dt>
                <dd>
                  <div className="btn_group">
                    {["남", "여"].map((el, idx) => (
                      <button
                        key={idx}
                        className={
                          "btn_default half " +
                          (niceData.mb_gender !== el && "border")
                        }
                      >
                        {el}
                      </button>
                    ))}
                  </div>
                </dd>
              </dl>
            </div>
            <div className="input_item_group">
              <dl>
                <dt>생년월일</dt>
                <dd>
                  <input
                    type="text"
                    className="input"
                    value={niceData.mb_birth}
                    readOnly
                  />
                </dd>
              </dl>
            </div>
            <div className="input_item_group">
              <dl>
                <dt>휴대폰 번호</dt>
                <dd>
                  <input
                    type="text"
                    className="input"
                    value={niceData.mb_ph}
                    readOnly
                  />
                </dd>
              </dl>
            </div>
            <div className="input_item_group">
              <dl>
                <dt>이메일 주소</dt>
                <dd>
                  <input
                    type="text"
                    className="input"
                    {...validAttributes("mb_email")}
                  />
                </dd>
                <dd>{errorCheck("mb_email")?.alert}</dd>
              </dl>
            </div>
            <div className="input_item_group">
              <dl>
                <dt>비밀번호</dt>
                <dd className="pw">
                  <input
                    type={isShowPw}
                    className="input"
                    {...validAttributes("mb_password")}
                  />
                  <img
                    src={show_pw}
                    alt=""
                    className="show_pw"
                    onClick={visibilityPw}
                  />
                </dd>
                <dd>{errorCheck("mb_password")?.alert}</dd>
              </dl>
            </div>
            <div className="input_item_group">
              <dl>
                <dt>비밀번호 확인</dt>
                <dd>
                  <input
                    type={isShowPw}
                    className="input"
                    {...validAttributes("mb_confirm_password")}
                  />
                </dd>
                <dd>{errorCheck("mb_confirm_password")?.alert}</dd>
              </dl>
            </div>
            <div className="input_item_group">
              <dl className="agree">
                {agreement.map((el, idx) => (
                  <dd key={idx}>
                    <label htmlFor={el.ag_code}>
                      <input
                        type="checkbox"
                        id={el.ag_code}
                        onChange={checkAgreement}
                      />
                      {el.ag_required ? <strong>[필수]</strong> : "[선택] "}
                      {el.ag_title}
                    </label>
                    <button
                      className="line"
                      onClick={() => {
                        setCurrentAgreement(el);
                      }}
                    >
                      {el.ag_required && "상세보기"}
                    </button>
                  </dd>
                ))}
              </dl>
            </div>
            <div className="btn_group join ">
              <button
                className="btn_default"
                onClick={submitData}
                disabled={
                  !checkRequiredItems() ||
                  !validPass(["mb_password", "mb_confirm_password", "mb_email"])
                }
              >
                회원가입
              </button>
            </div>
          </section>
          <AgreementModal
            currentAgreement={currentAgreement}
            setCurrentAgreement={setCurrentAgreement}
          />
        </>
      )}
    </main>
  );
}

function AgreementModal({ currentAgreement, setCurrentAgreement }) {
  if (currentAgreement)
    return (
      <aside className="modal dim" id="agreeDetail">
        <div className="modal_content_wrap">
          <header>
            <h2>{currentAgreement.ag_title}</h2>
            <div className="btn_group">
              <button
                className="close btn_default"
                onClick={() => {
                  setCurrentAgreement();
                }}
              >
                닫기
              </button>
            </div>
          </header>
          <textarea
            className="content_wrap"
            value={currentAgreement.ag_contents}
          ></textarea>
        </div>
      </aside>
    );
}
