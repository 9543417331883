import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";

// react date picker 를 활용하여 커스텀 기능을 추가한 훅
export default function useDatePicker(setting) {
  //settings 파라미터로 초기값을 설정할 수 있다.(옵션)
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [specificDate, setSpecificDate] = useState(new Date());

  useEffect(() => {
    //settings로 초기값이 설정된 경우 설정값으로 date 상태 업데이트
    if (setting?.startDate) setStartDate(setting.startDate);
    if (setting?.endDate) setEndDate(setting.endDate);
    if (setting?.specific) setSpecificDate(setting.specific);
  }, []);

  const DatePickerModule = ({
    type,
    minDate,
    maxDate,
    className,
    readOnly,
  }) => {
    const selectedDate =
      type === "specific" ? specificDate : type === "end" ? endDate : startDate;

    const handleChange = (date) => {
      switch (type) {
        case "start":
          setStartDate(date);
          if (date > endDate) setEndDate(date);
          //만약 컴포넌트타입이 시작날짜이고, 종료날짜보다 이후날짜 선택 시 종료날짜도 시작날짜와 똑같이 변경
          break;
        case "end":
          setEndDate(date);
          if (date < startDate) setStartDate(date);
          //만약 컴포넌트타입이 종료날짜이고, 시작날짜보다 이전날짜 선택 시 시작날짜도 종료날짜와 똑같이 변경
          break;
        case "specific":
          setSpecificDate(date);
          break;
        default:
          break;
      }
    };

    return (
      <DatePicker
        showYearDropdown
        yearDropdownItemNumber={100}
        dateFormat="yyyy.MM.dd"
        locale={ko}
        shouldCloseOnSelect
        minDate={minDate ? false : new Date()}
        maxDate={maxDate ? new Date() : false}
        selected={selectedDate}
        onChange={handleChange}
        className={className + " pointer"}
        readOnly={readOnly}
      />
    );
  };

  return {
    DatePickerModule,
    startDate,
    endDate,
    specificDate,
    setStartDate,
    setEndDate,
    setSpecificDate,
  };
}
