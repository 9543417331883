import { useLocation, useNavigate } from "react-router-dom";
import useInputHandler from "hooks/useInputHandler";
import useRequestAPI from "hooks/useRequestAPI";
import ReturnToList from "components/ReturnToList";

export default function AcademyClassroomDetail() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [dataForm, , attributes] = useInputHandler({
    room_name: state.resData.room_name,
    capacity: state.resData.capacity,
    location: state.resData.location,
    memo: state.resData.memo,
  });
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();

  const submitData = async () => {
    const body = {
      classroom_id: state.resData.id,
      ...dataForm,
    };
    const res = await requestAPI("patch", "academy/classroom/update", body);
    if (!res) return;
    setMessage({
      text: alertMsg.edit,
      confirm: () => navigate("/academy/classroom/1"),
    });
  };

  return (
    <div className="inner classroom_edit">
      <Loading />
      <header>
        <h2>강의실 수정</h2>
        <div className="btn_group">
          <ReturnToList />
        </div>
      </header>
      <section className="content_wrap">
        <p></p>
        {state.resData.is_class && (
          <p className="classroom_alert">
            ※해당 강의실을 사용중인 수업이 있으므로 정원을 수정할 수 없습니다.
          </p>
        )}
        <table className="detail">
          <tr className="desktop">
            <th>명칭</th>
            <td>
              <div className="cell_content_wrap">
                <input type="text" {...attributes("room_name")} />
              </div>
            </td>
            <th>정원</th>
            <td>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  {...attributes("capacity")}
                  className="small"
                  readOnly={state.resData.is_class}
                />
                명
              </div>
            </td>
          </tr>

          <tr className="mobile">
            <th>명칭</th>
            <td>
              <div className="cell_content_wrap">
                <input type="text" {...attributes("room_name")} />
              </div>
            </td>
          </tr>
          <tr className="mobile">
            <th>정원</th>
            <td>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  {...attributes("capacity")}
                  readOnly={state.resData.is_class}
                  className="small"
                />
                명
              </div>
            </td>
          </tr>

          <tr>
            <th>위치</th>
            <td colSpan={3}>
              <div className="cell_content_wrap">
                <textarea {...attributes("location")} />
              </div>
            </td>
          </tr>
          <tr>
            <th>
              메모
              <br />
              (시설 및<br />
              특이사항)
            </th>
            <td colSpan={3}>
              <div className="cell_content_wrap">
                <textarea {...attributes("memo")} />
              </div>
            </td>
          </tr>
        </table>
        <div className="btn_group mt_48">
          <button className=""></button>
          <button className="btn_default half" onClick={submitData}>
            수정
          </button>
        </div>
      </section>
    </div>
  );
}
