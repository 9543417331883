import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { messageAtom } from "atoms/atoms";

export default function useInputHandler(data) {
  // 인풋필드의 데이터로 리퀘스트 데이터 양식을 만드는 훅
  const setMessage = useSetRecoilState(messageAtom);
  const [dataForm, setDataForm] = useState(data);

  const entryData = (e) => {
    //인풋필드의 data-type 속성은 필수이다. property name으로 지정한다.

    const type = e.target.dataset.type;
    const format = e.target.dataset.format;
    let val = e.target.dataset.value || e.target.value;
    if (format === "number") val = val.replace(/[^0-9]/g, "");
    if (format === "float")
      val = val.replace(/[^0-9.]/g, "").replace(/\.+/g, ".");

    switch (
      type // property별 예외 동작
    ) {
      case "owner_rating": {
        if (val > 5) return setMessage("최대 5점까지 입력할 수 있습니다.");
        else setDataForm({ ...dataForm, [type]: val });
        break;
      }
      case "operation_date":
      case "week": {
        if (e.target.dataset.existingData === "true") {
          return setMessage("기존 운행 요일은 해제할 수 없습니다.");
        }
        return setDataForm((prevDataForm) => {
          let copy = { ...prevDataForm };
          const updatedDay = copy[type].includes(val)
            ? copy[type].filter((el) => el !== val)
            : [...copy[type], val];
          if (copy?.manager_name) {
          }
          return { ...copy, [type]: updatedDay };
        });
      }
      case "is_working": {
        const val = e.target.checked;
        return setDataForm({
          ...dataForm,
          is_working: val,
        });
      }
      case "departure_time": {
        if ("0500" > val.replace(":", "")) {
          setMessage("최소 출발시간은 05:00 입니다.");
          return setDataForm({
            ...dataForm,
            [type]: "05:00",
          });
        }
        if (val.replace(":", "") > dataForm.arrival_time.replace(":", "")) {
          setMessage(
            "출발시간이 도착시간을 초과하여 도착시간을 초기값(23:59)으로 설정합니다."
          );
          return setDataForm({
            ...dataForm,
            [type]: val,
            arrival_time: "23:59",
          });
        }
        return setDataForm({
          ...dataForm,
          [type]: val,
        });
      }
      case "arrival_time": {
        if (val.replace(":", "") < dataForm.departure_time.replace(":", ""))
          return setMessage("도착시간은 출발시간보다 이전일 수 없습니다.");
        return setDataForm({
          ...dataForm,
          [type]: val,
        });
      }
      case "reward": {
        if (val > 100) {
          return setMessage("포인트는 50P~100P 까지 지급 가능합니다.");
        }
        return setDataForm({ ...dataForm, [type]: val });
      }
      default: {
        //기본 동작은 인풋필드의 입력값을 그대로 dataForm 의 property로 업데이트
        setDataForm({ ...dataForm, [type]: val });
      }
    }
  };

  const attributes = (type, dataValue, dataIndex) => {
    // 인풋필드에 필수로 설정돼야하는 속성들을 담은 객체
    let value = dataForm && dataForm[type] ? dataForm[type] : "";
    const attributeData = {
      value,
      "data-type": type,
      "data-value": dataValue,
      "data-index": dataIndex,
      onChange: entryData,
    };

    const ph = ["mb_ph", "teacher_hp", "customer_ph"];
    const price = ["salary", "bonus"];
    if (ph.includes(type)) {
      attributeData.value = value.replace(
        /(\d{3})(\d{3,4})(\d{4})/,
        "$1-$2-$3"
      );
      attributeData.maxLength = 13;
    }
    if (price.includes(type)) {
      attributeData.value = Number(value).toLocaleString("ko-KR");
    }

    return attributeData;
  };

  return [dataForm, setDataForm, attributes];
}
