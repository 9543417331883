import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSelectBox from "hooks/useSelectBox";
import useRequestAPI from "hooks/useRequestAPI";
import Pagination from "components/Pagination";
import ToggleButton from "components/ToggleButton";
import formatDate from "utils/formatDate";
import formatPhoneNumber from "utils/formatPhoneNumber";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import formatExcelTitle from "utils/formatExcelTitle";
import guide_student from "assets/file/guide_student.pdf";

export default function Student() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [SelectBox, selectValue] = useSelectBox([
    { name: "전체", code: "all" },
    { name: "수업", code: "class_name" },
    { name: "학생명", code: "name" },
    { name: "휴대폰 번호", code: "phone_number" },
    { name: "학생 상태", code: "status" },
  ]);
  const [searchKeyword, setSerchKeword] = useState("");
  const { requestAPI, Loading } = useRequestAPI();
  const [currPage, setCurrPage] = useState(id - 1);
  const [resData, setResData] = useState();
  const [currSearchData, setCurrSearchData] = useState({
    page: currPage,
    size: 5,
    search_type: "all",
    search_keyword: "",
    mna_manager_id: localStorage.getItem("manager_id"),
  });

  const [McBoardContent, selectMcBoardContent] = useSelectBox([
    { name: "휴대폰번호", code: "student_phone_number" },
    { name: "생년월일", code: "student_birth" },
    { name: "성별", code: "student_gender" },
    { name: "상태", code: "status" },
    { name: "수업", code: "class_name" },
    { name: "수납일", code: "payment_date" },
    { name: "수납확인", code: "updated_at" },
  ]);

  const getData = async (e) => {
    e?.preventDefault();
    let body = { ...currSearchData, page: currPage };
    if (e?.target.tagName === "BUTTON") {
      body = {
        page: currPage,
        size: 5,
        search_type: selectValue?.code,
        search_keyword: searchKeyword,
        mna_manager_id: localStorage.getItem("manager_id"),
      };
      setCurrSearchData(body);
      return setCurrPage(0);
    }
    const res = await requestAPI("post", "student/list", body);
    if (!res) return;
    let copy = { ...res.data.data.students };
    copy.content = copy.content.map((el) => {
      if (!el.class_info_list) return { ...el };
      else
        return {
          ...el,
          class_info_list: JSON.parse(el.class_info_list).sort((a, b) =>
            a.class_name.localeCompare(b.class_name)
          ),
        };
    });
    setResData(copy);
  };

  const submitData = async (e, data) => {
    const body = {
      mna_manager_id: data.mna_manager_id,
      student_id: data.student_id,
      payment_id: data.payment_id,
      classhistory_id: data.classhistory_id,
      is_paid: e.target.checked,
    };
    const res = await requestAPI("patch", "student/payment", body);
    if (!res) return;
    getData();
  };

  useEffect(() => {
    getData();
  }, [currPage, currSearchData]);

  return (
    <div className="container">
      <div className="inner">
        <Loading />
        <section className="page_handling_wrap">
          <form className="search_wrap">
            <SelectBox />
            <input
              type="text"
              onChange={(e) => {
                setSerchKeword(e.target.value);
              }}
            />
            <div className="btn_group">
              <button className="btn_default search" onClick={getData}>
                검색
              </button>
            </div>
          </form>
          <Pagination
            currPage={currPage}
            setCurrPage={setCurrPage}
            lastPage={resData?.totalPages || 0}
          />
        </section>
        <section className="board_wrap">
          <header>
            <h2>학생관리</h2>
            <div className="btn_group">
              <button className="btn_default guide">
                <a
                  href={guide_student}
                  download={"에듀빌리_학생관리_사용가이드"}
                >
                  사용가이드
                </a>
              </button>
              <ReactHTMLTableToExcel
                className="btn_default excel"
                table="table"
                filename={formatExcelTitle("학생목록")}
                sheet="tableSheet"
                buttonText="엑셀다운로드"
              />
              <button
                className="btn_default add"
                onClick={() => {
                  navigate("/student/add");
                }}
              >
                학생등록
              </button>
            </div>
          </header>
          <table id="table">
            <colgroup className="desktop">
              <col width={"5%"} />
              <col width={"10%"} />
              <col width={"15%"} />
              <col width={"10%"} />
              <col width={"5%"} />
              <col width={"5%"} />
              <col width={"20%"} />
              <col width={"10%"} />
              <col width={"10%"} />
              <col width={"10%"} />
            </colgroup>
            <thead>
              <tr className="mobile">
                <th>이름</th>
                <th>
                  <McBoardContent />
                </th>
                <th>수납여부</th>
              </tr>
              <tr className="desktop">
                <th>번호</th>
                <th>이름</th>
                <th>휴대폰번호</th>
                <th>생년월일</th>
                <th>성별</th>
                <th>상태</th>
                <th>수업</th>
                <th>수납일 </th>
                <th>수납여부 </th>
                <th>수납확인</th>
              </tr>
            </thead>
            <tbody>
              {resData && !resData.content[0] && (
                <tr>
                  <td colSpan={10}>표시할 데이터가 없습니다.</td>
                </tr>
              )}
              {resData?.content.map((el, idx) => (
                <StudentItem
                  key={idx}
                  data={el}
                  idx={idx}
                  currPage={currPage}
                  submitData={submitData}
                  number={resData?.totalElements - currPage * 5 - idx}
                  selectMcBoardContent={selectMcBoardContent}
                />
              ))}
            </tbody>
          </table>
        </section>
      </div>
    </div>
  );
}

function StudentItem({
  data,
  idx,
  currPage,
  submitData,
  number,
  selectMcBoardContent,
}) {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(
    data.class_info_list.map((el) => el.is_paid)
  );

  const goToDetail = () => {
    navigate("/student/detail/" + data.student_id, {
      state: { mna_manager_id: data.mna_manager_id },
    });
  };

  const paymentDateCheck = (start, end) => {
    start = new Date(start);
    end = new Date(end);
    end.setDate(end.getDate() + 1);
    return start <= new Date() && new Date() < end;
  };

  const listDataFormat = (type) => {
    switch (type) {
      case "student_phone_number": {
        return (
          <>
            <span>
              {data.student_phone_number
                ? formatPhoneNumber(data.student_phone_number)
                : formatPhoneNumber(data.parent_phone_number)}
            </span>
            {!data.student_phone_number && <i className="guardian desktop"></i>}
          </>
        );
      }
      case "class_name": {
        return data.class_info_list[0].class_name
          ? data.class_info_list.map((el, idx) => {
              return (
                <>
                  <span className="student_class_info">
                    {!el.is_opening && "[미운영] "}
                    {el.class_name}
                  </span>
                  <br key={idx} />
                </>
              );
            })
          : "-";
      }
      case "updated_at": {
        return data.class_info_list[0].class_name
          ? data.class_info_list.map((el, idx) => {
              return (
                <>
                  {idx > 0 && <br />}
                  <span className="student_class_info" key={idx}>
                    {el.is_paid ? formatDate(el.updated_at, ".") : "-"}
                  </span>
                </>
              );
            })
          : "-";
      }
      case "student_birth": {
        return formatDate(data.student_birth, ".");
      }
      case "payment_date": {
        return data.class_info_list[0].class_name
          ? data.class_info_list.map((el, idx) => {
              return (
                <>
                  {idx > 0 && <br />}
                  <span className="student_class_info">
                    {el.payment_date}일
                  </span>
                </>
              );
            })
          : "-";
      }
      case "result": {
        return data.class_info_list[0].class_name
          ? data.class_info_list.map((el, idx) => {
              return (
                <>
                  {paymentDateCheck(el.pay_start_date, el.pay_end_date) ? (
                    <ToggleButton
                      key={idx}
                      checked={isChecked[idx]}
                      index={data.student_id + "_" + idx}
                      onChange={(e) => {
                        let copy = [...isChecked];
                        copy[idx] = e.target.checked;
                        // setIsChecked(copy);
                        submitData(e, { ...data, ...el });
                      }}
                    />
                  ) : (
                    <span
                      className={
                        "student_class_info " +
                        { 미납: "red", 완료: "blue" }[el.result]
                      }
                    >
                      {el.result}
                    </span>
                  )}
                  {!paymentDateCheck(el.pay_start_date, el.pay_end_date) && (
                    <br />
                  )}
                </>
              );
            })
          : "-";
      }
      default: {
        return data[type];
      }
    }
  };

  useEffect(() => {
    setIsChecked(data.class_info_list.map((el) => el.is_paid));
  }, [data]);

  return (
    <tr>
      <td onClick={goToDetail} className="desktop">
        {number}
      </td>
      <td onClick={goToDetail}>{data.student_name}</td>
      {/* 모바일 */}
      <td className="mobile">{listDataFormat(selectMcBoardContent.code)}</td>
      {/* 모바일 */}
      <td onClick={goToDetail} className="desktop hide_text">
        {listDataFormat("student_phone_number")}
      </td>
      <td onClick={goToDetail} className="desktop">
        {listDataFormat("student_birth")}
      </td>
      <td onClick={goToDetail} className="desktop">
        {listDataFormat("student_gender", data)}
      </td>
      <td onClick={goToDetail} className="desktop">
        {listDataFormat("status", data)}
      </td>
      <td onClick={goToDetail} className="desktop">
        {listDataFormat("class_name")}
      </td>
      <td onClick={goToDetail} className="desktop">
        {listDataFormat("payment_date")}
      </td>
      <td>{listDataFormat("result")}</td>
      <td onClick={goToDetail} className="desktop">
        {listDataFormat("updated_at")}
      </td>
    </tr>
  );
}
