import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend, NativeTypes } from "react-dnd-html5-backend";
import useCategoryList from "hooks/useCategoryList";
import useValidation from "hooks/useValidation";
import useFileHandler from "hooks/useFileHandler";
import useInputHandler from "hooks/useInputHandler";
import useFindPostAddr from "hooks/useFindPostAddr";
import useRequestAPI from "hooks/useRequestAPI";
import no_image from "assets/img/profile_noimage.png";
import ic_camera from "assets/img/icon/ic_camera.png";

export default function AcademyInfoEdit() {
  const navigate = useNavigate();
  const [CategoryList, selected, setSelected] = useCategoryList("field");
  const { form, setForm, errorCheck, validAttributes } = useValidation();
  const {
    fileData,
    setFileData,
    fileInputRef,
    uploadFile,
    deleteFile,
    uploadS3,
  } = useFileHandler(["jpg", "jpeg", "png", "gif"], 1, 6);
  // 학원 정보 관련
  const [dataForm, setDataForm] = useInputHandler();
  const { DaumPost, userAddr, setUserAddr, detailAddr, openDaumPost } =
    useFindPostAddr();
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();
  const [profileImg, setProfileImg] = useState([]);

  const moveImage = (dragIndex, hoverIndex) => {
    const draggedImage = fileData[dragIndex];
    const newAcademyImg = [...fileData];
    newAcademyImg.splice(dragIndex, 1);
    newAcademyImg.splice(hoverIndex, 0, draggedImage);
    setFileData(
      newAcademyImg.map((el, idx) => {
        return { ...el, index: idx };
      })
    );
  };

  const getData = async () => {
    const body = {
      manager_id: localStorage.getItem("manager_id"),
      academy_id: localStorage.getItem("academy_id"),
    };
    const res = await requestAPI("post", "academy/information", body);
    if (!res) return;
    const data = res.data.data;
    setDataForm(data);
    setSelected(data.fields);
    setUserAddr({
      zonecode: data.postal_code,
      addr: data.address,
      detailAddr: data.address_detail,
    });
    setForm({
      ...form,
      company_fax: { val: data.fax, isValid: true },
      company_ph: { val: data.tel, isValid: true },
    });
    if (data.academy_picture)
      setFileData(data.academy_picture.sort((a, b) => a.index - b.index));
    if (data.profile_path) {
      setProfileImg([{ ...data.profile_path }]);
    }
  };

  const submitData = async () => {
    const imgData = fileData[0] ? await uploadS3("manager") : [];
    const profileImgData = profileImg[0]
      ? await uploadS3(
          "manager",
          profileImg,
          "common/s3/manager/signed-upload-public"
        )
      : [];

    const body = {
      ...dataForm,
      postal_code: userAddr.zonecode,
      address: userAddr.addr,
      address_detail: userAddr.detailAddr,
      fax: form.company_fax.val,
      tel: form.company_ph.val,
      academy_picture: imgData,
      profile_path: profileImgData,
      fields: selected,
    };
    delete body.academy_picture_url; // infomation 에서 올 필요 없는 값
    const res = await requestAPI("patch", "academy/information/update", body);
    if (!res) return;

    localStorage.setItem("academy_name", dataForm.academy_name);
    setMessage({
      text: alertMsg.edit,
      confirm: () => navigate("/academy/info"),
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="inner info_wrap">
      <Loading />
      <header className="visually_hidden">
        <h1>학원정보수정</h1>
      </header>

      {/* Basic info */}
      <section className="info_section board_wrap">
        <header>
          <h2 style={{ display: "none" }}>학원정보수정</h2>
          <h2 className="">기본 정보</h2>
        </header>

        <div className="basic_info">
          <ProfileImage
            initialImg={profileImg[0] && profileImg[0].Location}
            setProfileImg={setProfileImg}
          />

          <div className="academy_basic_info">
            <dl>
              <dt>학원명</dt>
              <dd>{dataForm?.academy_name}</dd>
            </dl>
            <dl>
              <dt>사업자등록번호</dt>
              <dd>{dataForm?.license_no}</dd>
            </dl>
            <dl>
              <dt>대표자</dt>
              <dd>{dataForm?.ceo_name}</dd>
            </dl>
            <dl>
              <dt>개업일자</dt>
              <dd>
                {dataForm?.opening_date.replace(/-/g, ".")}
                {/* <DatePickerModule type="specific" /> */}
              </dd>
            </dl>
            <dl>
              <dt>우편번호</dt>
              <dd className="flex jc_fs">
                <input
                  type="text"
                  className="half desktop"
                  value={userAddr.zonecode}
                  onClick={openDaumPost}
                  readOnly
                />

                <input
                  type="text"
                  className="mobile"
                  value={userAddr.zonecode}
                  onClick={openDaumPost}
                  readOnly
                />

                <div className="btn_group short ml_16 desktop">
                  <button
                    className="btn_default slim small green3 "
                    onClick={openDaumPost}
                  >
                    검색
                  </button>
                </div>

                <div className="btn_group short ml_8 mobile">
                  <button
                    className="btn_default slim green3 pr_16 pl_16"
                    onClick={openDaumPost}
                  >
                    검색
                  </button>
                </div>
              </dd>
            </dl>
            <dl>
              <dt>주소</dt>
              <dd>
                <input
                  type="text"
                  className="half"
                  value={userAddr.addr}
                  readOnly
                />
              </dd>
            </dl>
            <dl>
              <dt>상세주소</dt>
              <dd>
                <input
                  type="text"
                  className="half"
                  value={userAddr.detailAddr}
                  onChange={detailAddr}
                />
              </dd>
            </dl>
            <dl>
              <dt>전화번호</dt>
              <dd>
                <input
                  type="text"
                  className="half"
                  {...validAttributes("company_ph")}
                  data-format="number"
                />
                <span>
                  * 전화번호 변경 시 로그인 <br className="mobile" />
                  학원코드(전화번호 끝 4자리) <br className="mobile" />도
                  변경됩니다.
                </span>
                {errorCheck("company_ph")?.alert}
              </dd>
            </dl>
            <dl>
              <dt>팩스</dt>
              <dd>
                <input
                  type="text"
                  className="half"
                  {...validAttributes("company_fax")}
                  data-format="number"
                />
                {errorCheck("company_fax")?.alert}
              </dd>
            </dl>
            <dl>
              <dt>분야</dt>
              <dd className="subject_list">
                <CategoryList className="slim jc_fs gap_4" />
              </dd>
            </dl>
          </div>
        </div>
      </section>

      {/* Pictures */}
      <section className="info_section academy_picture_wrap board_wrap">
        <header>
          <h2 className="">
            학원 사진<span></span>
          </h2>
          <div className="file_attach_wrap">
            <div className="btn_group desktop">
              <button className="btn_default slim small">
                <label htmlFor="fileInputImg">파일등록</label>
              </button>
            </div>
            <input
              ref={fileInputRef}
              type="file"
              id="fileInputImg"
              name="academyImgFiles"
              className="file_input"
              accept=".png, .jpg, .jpeg"
              onChange={uploadFile}
              multiple
            />
          </div>
        </header>

        <DndProvider
          backend={HTML5Backend}
          options={{ enableMouseEvents: true }}
        >
          <div className="img_grid_list">
            {fileData.map((el, idx) => (
              <DraggableImage
                key={idx}
                index={idx}
                src={el.Location ?? el.Location}
                onMove={moveImage}
                onRemove={deleteFile}
              />
            ))}
            {fileData.length < 6 && (
              <div className="add_img_wrap mobile">
                <label htmlFor="fileInputImg"></label>
              </div>
            )}
          </div>
        </DndProvider>
      </section>

      <section className="info_section submit_wrap">
        <div className="btn_group">
          <button type="submit" className="btn_default" onClick={submitData}>
            수정하기
          </button>
        </div>

        <p>
          * 학원정보 변경 및 폐업 시 <a>support@butterflying.kr</a>
          로 문의 바랍니다.
          <br /> Ex) 메일 제목 : [폐업] 0000학원
        </p>
      </section>
      <DaumPost />
    </div>
  );
}

const ProfileImage = ({ initialImg, setProfileImg }) => {
  const { fileData, fileInputRef, uploadFile } = useFileHandler();

  useEffect(() => {
    if (fileData[0]) setProfileImg(fileData);
  }, [fileData]);

  return (
    <div className="profile_container ">
      <div className="profile_wrap">
        <img
          src={fileData[0]?.Location || initialImg || no_image}
          alt=""
          id="academy_profile_preview"
        />
      </div>

      <div className="file_attach_wrap">
        <label htmlFor="fileInputProfile" className="file_label">
          <img src={ic_camera} alt="파일 등록 버튼" className="file_icon" />
        </label>
        <input
          type="file"
          id="fileInputProfile"
          name="uploadedFile"
          className="hidden"
          accept=".png, .jpg, .jpeg"
          onChange={uploadFile}
          ref={fileInputRef}
        />
      </div>
    </div>
  );
};

const DraggableImage = ({ index, src, onMove, onRemove }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: NativeTypes.FILE,
    hover(item, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;
      onMove(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: NativeTypes.FILE,
    item: { index, src },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  return (
    <div ref={ref} className={`img_grid_item ${isDragging ? "dragging" : ""}`}>
      <div className="img_wrap">
        <img src={src} alt={`이미지 ${index + 1}`} />
      </div>
      <button
        type="button"
        className="close_btn"
        data-name={`이미지 ${index + 1}`}
        data-index={index}
        onClick={onRemove}
      >
        &times;
      </button>
    </div>
  );
};
