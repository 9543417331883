import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Messenger from "routes/messenger/Messenger";
import useInputHandler from "hooks/useInputHandler";
import useStudentCheckBox from "hooks/useStudentCheckBox";
import useFileHandler from "hooks/useFileHandler";
import useRequestAPI from "hooks/useRequestAPI";
import ReturnToList from "components/ReturnToList";

export default function NotificationEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [dataForm, setDataForm, attributes] = useInputHandler({});
  const { StudentList, SelectList, submitDataFormat } = useStudentCheckBox(
    state.resData
  );
  const {
    fileData,
    setFileData,
    fileInputRef,
    uploadFile,
    deleteFile,
    uploadS3,
  } = useFileHandler(["jpg", "jpeg", "png", "gif"], 1, 3);
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();
  const [isMessengerMode, setIsMessengerMode] = useState({
    state: false,
    mode: "",
  });

  const submitConditionCheck = () => {
    if (!submitDataFormat[0]) {
      setMessage("대상을 선택해주세요.");
      return false;
    }
    if (!dataForm.desc) {
      setMessage(alertMsg.null_content);
      return false;
    }
    return true;
  };

  const submitData = async () => {
    if (!submitConditionCheck()) return;
    const notifyFileData = fileData[0] ? await uploadS3("manager") : [];
    const deleteStudents = state.resData.student_list
      .filter((el) => {
        return !submitDataFormat[0]?.student_list.find(
          (el2) => el2.student_id === el.student_id
        );
      })
      .map((el) => {
        return { ...el, is_deleted: true };
      });

    const newStudents = submitDataFormat[0].student_list
      .filter((el) => {
        return !state.resData.student_list.find(
          (el2) => el2.student_id === el.student_id
        );
      })
      .map((el) => el.student_id);

    const body = {
      ...dataForm,
      id,
      class_id: state.resData.class_id,
      type: "notify",
      notification_list: [
        ...submitDataFormat[0].student_list,
        ...deleteStudents,
      ],
      new_student_list: newStudents,
      file_list: notifyFileData,
      desc: `[알림]%&${dataForm.desc.replace(/\r?\n/g, "%&")}`,
      desc_text: dataForm.desc.replace(/\r?\n/g, "%&"),
    };
    const res = await requestAPI("patch", "notify/update", body);
    if (!res) return;
    setMessage({
      text: alertMsg.edit,
      confirm: () => navigate("/notification/1"),
    });
  };

  useEffect(() => {
    setDataForm({
      desc: state.resData.desc_text,
      memo: state.resData.memo,
    });
    if (state.resData.attach_files) setFileData(state.resData.attach_files);
  }, []);

  return (
    <>
      <Loading />
      {isMessengerMode.state ? (
        <Messenger
          mode={isMessengerMode.mode}
          setIsMessengerMode={setIsMessengerMode}
          resData={{
            student_list: submitDataFormat.map((el) => el.student_list).flat(1),
            attach_files: fileData[0] ? fileData : null,
            desc: dataForm.desc,
          }}
          submitData={submitData}
        />
      ) : (
        <div id="notification" className="container background_white">
          <div className="inner">
            <header>
              <h2>알림 수정</h2>
              <div className="btn_group short mobile">
                <button
                  className="btn_default small fs_12"
                  onClick={() => {
                    setIsMessengerMode({ state: true, mode: "view" });
                  }}
                >
                  메시지형태로 보기
                </button>
              </div>
              <div className="btn_group desktop">
                <button
                  className="btn_default"
                  onClick={() => {
                    setIsMessengerMode({ state: true, mode: "view" });
                  }}
                >
                  메시지형태로 보기
                </button>
                <ReturnToList />
              </div>
            </header>
            <div className="page_content_wrap">
              <section className="input_main_wrap">
                <div className="input_select_wrap">
                  <h3>학생목록</h3>
                  <StudentList />
                </div>
                <div className="input_select_wrap">
                  <h3>대상</h3>
                  <SelectList />
                </div>
                <div className="input_select_wrap content">
                  <h3>내용</h3>
                  <div className="input_select_item">
                    <textarea
                      {...attributes("desc")}
                      placeholder="내용을 입력해주세요."
                    ></textarea>
                  </div>
                </div>
              </section>

              <section className="input_sub_wrap">
                <table className="detail">
                  <colgroup className="desktop">
                    <col width={"120px"} />
                    <col width={"auto"} />
                  </colgroup>
                  <tr>
                    <th>첨부파일</th>
                    <td>
                      <div className="cell_content_wrap">
                        <div className="btn_group">
                          <button
                            htmlFor="file"
                            className="btn_default slim green3"
                          >
                            <label htmlFor="file">파일선택</label>
                          </button>
                          <input
                            ref={fileInputRef}
                            type="file"
                            className="hidden"
                            id="file"
                            onChange={uploadFile}
                            multiple
                          />
                        </div>
                        <ul className="file_list">
                          {fileData[0] &&
                            fileData.map((el) => {
                              return (
                                <li>
                                  <span>{el.name}</span>{" "}
                                  <button onClick={deleteFile}>x</button>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>메모</th>
                    <td>
                      <div className="cell_content_wrap">
                        <input type="text" {...attributes("memo")} />
                      </div>
                    </td>
                  </tr>
                </table>
              </section>

              <div className="btn_group">
                <button></button>
                <button
                  className="btn_default half"
                  onClick={() => {
                    if (!submitConditionCheck()) return;
                    setIsMessengerMode({ state: true, mode: "submit" });
                  }}
                >
                  재발송
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
