export default function adjustTime(timeString, isAddition = "+") {
  const [hours, minutes] = timeString.split(":").map(Number);

  // 더할지 뺄지 여부에 따라 연산을 조정
  const adjustment = isAddition === "+" ? 1 : -1;

  // 변환된 시간을 계산
  let newHours = hours;
  let newMinutes = minutes + adjustment;

  // 분이 음수 또는 60 이상이면 시간을 조정
  if (newMinutes < 0) {
    newHours -= 1;
    newMinutes = 59;
  } else if (newMinutes >= 60) {
    newHours += 1;
    newMinutes = 0;
  }

  // 시간을 2자리 숫자로 포맷팅
  const formattedHours = String(newHours).padStart(2, "0");
  const formattedMinutes = String(newMinutes).padStart(2, "0");

  // 새로운 시간 문자열 반환
  return `${formattedHours}:${formattedMinutes}`;
}
