import { useEffect } from "react";
import hangjungdong from "variable/hangjungdong";
import useSelectBox from "hooks/useSelectBox";

export default function RegionSelectBox({ setRegionData }) {
  const { sido, sigugun, dong } = hangjungdong;
  const [SelectBox1, selectBoxValue1] = useSelectBox(
    sido.map((el) => {
      return { code: el.sido, name: el.codeNm };
    })
  );
  const [SelectBox2, selectBoxValue2, setSelectBoxValue2, setSelectList2] =
    useSelectBox([]);

  const [SelectBox3, selectBoxValue3, setSelectBoxValue3, setSelectList3] =
    useSelectBox([]);

  useEffect(() => {
    setSelectList2(
      sigugun
        .filter((el) => el.sido === selectBoxValue1.code)
        .map((el) => {
          return { code: el.sigugun, name: el.codeNm };
        })
    );
    setSelectBoxValue2();
    setSelectBoxValue3();
    setRegionData({ sido: selectBoxValue1.name, sigugun: "", dong: "" });
  }, [selectBoxValue1]);

  useEffect(() => {
    if (selectBoxValue2) {
      setSelectList3(
        dong
          .filter(
            (el) =>
              el.sido === selectBoxValue1.code &&
              el.sigugun === selectBoxValue2.code
          )
          .map((el) => {
            return { code: el.dong, name: el.codeNm };
          })
      );
      setSelectBoxValue3();

      setRegionData({
        sido: selectBoxValue1.name,
        sigugun: selectBoxValue2.name,
        dong: "",
      });
    }
  }, [selectBoxValue2]);

  useEffect(() => {
    if (selectBoxValue3)
      setRegionData({
        sido: selectBoxValue1.name,
        sigugun: selectBoxValue2.name,
        dong: selectBoxValue3.name,
      });
  }, [selectBoxValue3]);

  return (
    <div className="region_wrap">
      <SelectBox1 />
      <SelectBox2 />
      <SelectBox3 />
    </div>
  );
}
