import { useState } from "react";
import SubjectDetail from "components/modal/SubjectDetail";
import LicenseDetail from "components/modal/LicenseDetail";
import HistoryDetail from "components/modal/HistoryDetail";
import SelectShuttle from "components/modal/SelectShuttle";
import SelectTeacher from "components/modal/SelectTeacher";
import SelectTime from "components/modal/SelectTime";
import AddTeacher from "components/modal/AddTeacher";
import EditSalaryInfo from "components/modal/EditSalaryInfo";
import ChangeTeacherGrade from "components/modal/ChangeTeacherGrade";
import SelectAcademy from "components/modal/SelectAcademy";
import SelectClassShuttle from "components/modal/SelectClassShuttle";
import CurrentStudent from "components/modal/CurrentStudent";
import AddPayment from "components/modal/AddPayment";
import ContactUs from "components/modal/ContactUs";
import BoardingStudent from "components/modal/BoardingStudent";
import CreateAcademyList from "components/modal/CreateAcademyList";
import DupClassList from "components/modal/DupClassList";
import EventPopup from "components/modal/EventPopup";

const modalComponents = {
  subjectDetail: SubjectDetail,
  licenseDetail: LicenseDetail,
  historyDetail: HistoryDetail,
  selectShuttle: SelectShuttle,
  selectTeacher: SelectTeacher,
  selectTime: SelectTime,
  addTeacher: AddTeacher,
  editSalaryInfo: EditSalaryInfo,
  changeTeacherGrade: ChangeTeacherGrade,
  selectAcademy: SelectAcademy,
  selectClassShuttle: SelectClassShuttle,
  currentStudent: CurrentStudent,
  addPayment: AddPayment,
  contactus: ContactUs,
  boardingStudent: BoardingStudent,
  createAcademyList: CreateAcademyList,
  dupClassList: DupClassList,
  eventPopup: EventPopup,
};

export default function useModalHandler() {
  const [currentModal, setCurrentModal] = useState({
    type: "",
    mode: "",
    data: "",
  });
  const [sendModalData, setSendModalData] = useState();
  const [isOpen, setIsOpen] = useState();

  const openModal = (type, mode, data) => {
    // 모달을 오픈하는 함수. type에는 모달 이름, mode와 data에는 필요한 값을 입력한다.
    setCurrentModal({ type, mode, data });
    setIsOpen(true);
  };

  const closeModal = () => {
    // 모달 닫기 함수
    setCurrentModal({ type: "", mode: "", data: "" });
    setIsOpen(false);
  };

  const changeData = (data) => {
    // 서버로 전송하거나 화면에 반영
    setCurrentModal({ type: currentModal.type, mode: "", data: "" });
    setSendModalData(data);
    setIsOpen(false);
  };

  const ModalComponent = () => {
    const Component = modalComponents[currentModal.type];
    if (isOpen && Component) {
      return (
        <Component
          closeModal={closeModal}
          changeData={changeData}
          currentData={currentModal}
        />
      );
    }
    return null;
  };

  return { ModalComponent, openModal, sendModalData, currentModal };
}
