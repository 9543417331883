import { useEffect, useState } from "react";
import useRequestAPI from "hooks/useRequestAPI";

export default function BoardingStudent({ closeModal, currentData }) {
  const { requestAPI } = useRequestAPI();
  const [resData, setResData] = useState();
  const actionType = currentData.data.direction === 0 ? "등원" : "하원";

  const getData = async () => {
    const { bus_id, bus_stop_id, bus_driver_id } = currentData.data;
    const body = { bus_id, bus_stop_id, bus_driver_id };
    const res = await requestAPI("post", "student/boarding", body);
    if (!res) return;
    setResData(res.data.data.boarding_students);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <aside id="boardingStudent" className="modal dim select">
      <div className="modal_content_wrap">
        <header>
          <h2>{actionType}학생</h2>
          <div className="btn_group">
            <button className="close btn_default" onClick={closeModal}>
              닫기
            </button>
          </div>
        </header>

        {/* <section className="mt_52"> */}

        <section className="table_scroll_wrap">
          {resData && !resData[0] && (
            <p className="empty_data">{actionType} 학생이 없습니다.</p>
          )}
          {resData && resData[0] && (
            <table className="detail">
              <tr>
                <th>이름</th>
                <th>{actionType}여부</th>
              </tr>
              {resData.map((el) => {
                return (
                  <tr>
                    <td>{el.name}</td>
                    {/* <td className={el.is_boarding ? "" : "error"}> */}
                    <td
                      className={
                        actionType === "등원"
                          ? el.is_boarding
                            ? ""
                            : "error"
                          : el.is_boarding
                          ? "error"
                          : ""
                      }
                    >
                      {el.is_boarding ? "승차" : "하차"}
                    </td>
                  </tr>
                );
              })}
            </table>
          )}
        </section>

        {/* </section> */}
      </div>
    </aside>
  );
}
