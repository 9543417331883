import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Messenger from "routes/messenger/Messenger";
import useInputHandler from "hooks/useInputHandler";
import useStudentCheckBox from "hooks/useStudentCheckBox";
import useRequestAPI from "hooks/useRequestAPI";
import ReturnToList from "components/ReturnToList";

export default function MaterialEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const [dataForm, setDataForm, attributes] = useInputHandler({});
  const { StudentList, SelectList, ScheduleList, submitDataFormat } =
    useStudentCheckBox(state.resData);
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();
  const [isMessengerMode, setIsMessengerMode] = useState({
    state: false,
    mode: "",
  });
  const [sendType, setSendType] = useState(state.resData.send_type);

  const submitConditionCheck = () => {
    if (!submitDataFormat[0]) {
      setMessage("대상을 선택해주세요.");
      return false;
    }
    if (!submitDataFormat.every((el) => el?.class_times && el.class_times[0])) {
      setMessage("준비물을 등록할 수업일시를 선택해 주세요.");
      return false;
    }
    if (!dataForm.desc) {
      setMessage(alertMsg.null_content);
      return false;
    }
    return true;
  };

  const submitData = async () => {
    if (!submitConditionCheck()) return;
    const deleteStudents = state.resData.student_list
      .filter((el) => {
        return !submitDataFormat[0]?.student_list.find(
          (el2) => el2.student_id === el.student_id
        );
      })
      .map((el) => {
        return { ...el, is_deleted: true };
      });

    const newStudents = submitDataFormat[0].student_list
      .filter((el) => {
        return !state.resData.student_list.find(
          (el2) => el2.student_id === el.student_id
        );
      })
      .map((el) => el.student_id);

    const body = {
      ...dataForm,
      id,
      class_id: state.resData.class_id,
      mna_manager_id: localStorage.getItem("manager_id"),
      type: "materials",
      notification_list: [
        ...submitDataFormat[0].student_list,
        ...deleteStudents,
      ],
      new_student_list: newStudents,
      send_type: sendType,
      send_date: submitDataFormat[0].class_times,
      desc: `[준비물]%&일시 : ${submitDataFormat[0].class_times.join(
        ","
      )}%&준비물 : ${dataForm.desc.replace(/\r?\n/g, "%&")}`,
      desc_text: dataForm.desc.replace(/\r?\n/g, "%&"),
    };
    const res = await requestAPI("patch", "notify/update", body);
    if (!res) return;
    setMessage({ text: alertMsg.edit, confirm: () => navigate("/material/1") });
  };

  useEffect(() => {
    setDataForm({
      desc: state.resData.desc_text,
      memo: state.resData.memo,
    });
  }, []);

  return (
    <>
      <Loading />
      {isMessengerMode.state ? (
        <Messenger
          mode={isMessengerMode.mode}
          setIsMessengerMode={setIsMessengerMode}
          resData={{
            student_list: submitDataFormat.map((el) => el.student_list).flat(1),
            attach_files: null,
            desc: dataForm.desc,
            class_list: submitDataFormat,
          }}
          submitData={submitData}
        />
      ) : (
        <div id="material" className="container background_white">
          <div className="inner">
            <header>
              <h2>준비물 수정</h2>
              <div className="btn_group short mobile">
                <button
                  className="btn_default small fs_12"
                  onClick={() => {
                    setIsMessengerMode({ state: true, mode: "view" });
                  }}
                >
                  메시지형태로 보기
                </button>
              </div>
              <div className="btn_group desktop">
                <button
                  className="btn_default"
                  onClick={() => {
                    setIsMessengerMode({ state: true, mode: "view" });
                  }}
                >
                  메시지형태로 보기
                </button>
                <ReturnToList />
              </div>
            </header>
            <div className="page_content_wrap">
              <section className="input_main_wrap">
                <div className="input_select_wrap">
                  <h3>학생목록</h3>
                  <StudentList />
                </div>
                <div className="input_select_wrap">
                  <h3>대상</h3>
                  <SelectList />
                </div>
                <div className="input_select_wrap">
                  <h3>일시</h3>
                  <ScheduleList />
                </div>
                <div className="input_select_wrap content">
                  <h3>내용</h3>
                  <div className="input_select_item">
                    <textarea
                      {...attributes("desc")}
                      placeholder="내용을 입력해주세요."
                    ></textarea>
                  </div>
                </div>
              </section>

              <section className="input_sub_wrap">
                <table className="detail">
                  <tr>
                    <th>준비유형</th>
                    <td colSpan={3}>
                      <div className="cell_content_wrap">
                        <label htmlFor="one">
                          다음 수업
                          <input
                            id="one"
                            type="radio"
                            name="isOnce"
                            className="ml_8"
                            checked={!sendType}
                            onChange={(e) => {
                              setSendType(0);
                            }}
                          />
                        </label>
                        <label htmlFor="continue" className="ml_16">
                          매 수업
                          <input
                            id="continue"
                            type="radio"
                            name="isOnce"
                            className="ml_8"
                            checked={sendType}
                            onChange={(e) => {
                              setSendType(1);
                            }}
                          />
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>메모</th>
                    <td colSpan={3}>
                      <div className="cell_content_wrap">
                        <input type="text" {...attributes("memo")} />
                      </div>
                    </td>
                  </tr>
                </table>
              </section>

              <div className="btn_group">
                <button></button>
                <button
                  className="btn_default half"
                  onClick={() => {
                    if (!submitConditionCheck()) return;
                    setIsMessengerMode({ state: true, mode: "submit" });
                  }}
                >
                  재발송
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
