export default function formatCallNumber(callNumber) {
  // 숫자만 추출
  if (!callNumber) return "";
  const numbersOnly = callNumber?.replace(/\D/g, "");

  // 팩스 번호 형식에 맞게 하이픈 삽입
  let formattedCallNumber = numbersOnly;

  // 010으로 시작하는 경우 휴대폰 번호 형식으로 하이픈 삽입
  if (callNumber.startsWith("010")) {
    formattedCallNumber = formattedCallNumber.replace(
      /(\d{3})(\d{4})(\d{4})/,
      "$1-$2-$3"
    );
  } else {
    formattedCallNumber = numbersOnly?.replace(
      /(\d{2})(\d{3,4})(\d{4})/,
      "$1-$2-$3"
    );
  }

  return formattedCallNumber;
}
