export default function CreateAcademyList({
  closeModal,
  changeData,
  currentData,
}) {
  return (
    <aside id="createAcademyList" className="modal dim">
      <div className="modal_content_wrap">
        <header>
          <h2>심사중인 사업장</h2>
          <div className="btn_group">
            <button className="btn_default close" onClick={closeModal}>
              닫기
            </button>
          </div>
        </header>
        <section className="table_scroll_wrap">
          <table className="detail fixed">
            <colgroup>
              <col width={"100%"} />
              <col width={"150px"} />
            </colgroup>
            {currentData.data.map((el) => {
              return (
                <>
                  <tr>
                    <th>{el.ocr_academy_name}</th>
                    <td rowSpan={2}>
                      <div className="btn_group short">
                        <button
                          className="btn_default slim small"
                          onClick={() => {
                            changeData(el);
                          }}
                        >
                          선택
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{el.ocr_license_no}</td>
                  </tr>
                </>
              );
            })}
            {/* <tr>
              <th>학원명학원명학원명학원명학원명</th>
              <td rowSpan={2}>
                <div className="btn_group short">
                  <button className="btn_default slim small">선택</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>경기도 성남시 중원구 성남동 2264 604호</td>
            </tr> */}
          </table>
        </section>
      </div>
    </aside>
  );
}
