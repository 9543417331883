import axios from "axios";
import { url } from "variable/url";

export default async function loginCheck(callback, errorCallback) {
  try {
    await axios.get(url.server + "teacher/me");
    if (callback) callback();
    return true;
  } catch {
    if (errorCallback) errorCallback();
    return false;
  }
}
