import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { messageAtom } from "../../atoms/atoms";
import Message from "../../components/modal/Message";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}

function ErrorPage() {
  const navigate = useNavigate();
  const setMessage = useSetRecoilState(messageAtom);

  useEffect(() => {
    navigate(-1);
    setMessage({
      text: "페이지 정보를 불러오는데 실패했습니다.",
      confirm: () => {
        window.location.reload(true);
      },
    });
  }, []);

  return <Message />;
}

export default ErrorBoundary;
