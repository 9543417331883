import { useEffect, useState } from "react";
import phone_bottom from "assets/img/phone_bottom.png";
import ic_arrow_left from "assets/img/icon/ic_arrow_left.png";
import { useLocation } from "react-router-dom";
import useRequestAPI from "hooks/useRequestAPI";
import formatText from "utils/formatText";

export default function Messenger({
  setIsMessengerMode,
  resData,
  submitData,
  mode,
}) {
  const { requestAPI, Loading } = useRequestAPI();
  const { pathname } = useLocation();
  const [acadmeyData, setAcademyData] = useState();

  const targetType = {
    notification: "[알림]",
    homework: "[숙제]",
    material: "[준비물]",
  }[pathname.split("/")[1]];

  const getData = async () => {
    const res = await requestAPI("post", "academy/basic", {});
    if (!res) return;
    setAcademyData(res.data.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div id="messenger" className="container">
      <div className="inner">
        <Loading />
        <header>
          <h2>메신저 형태로 보기</h2>
          <div className="btn_group short ">
            <button
              className="btn_default desktop"
              onClick={() => {
                setIsMessengerMode(false);
              }}
            >
              기본으로 보기
            </button>
            <button
              className="btn_default small mobile fs_12"
              onClick={() => {
                setIsMessengerMode(false);
              }}
            >
              기본으로 보기
            </button>
          </div>
        </header>
        <div className="page_content_wrap ">
          <table className="detail">
            <colgroup>
              <col width={"30%"} />
            </colgroup>
            <tr>
              <th>대상</th>
              <td
                className={
                  "target_list " + (pathname.includes("/add") && "add")
                }
              >
                <div className="cell_content_wrap">
                  <ul>
                    {resData?.student_list
                      .filter((el) => !el.is_deleted)
                      .map((el) => {
                        const active = pathname.includes("homework")
                          ? el.is_completed
                          : el.is_read;
                        return <li className={active && "read"}>{el.name}</li>;
                      })}
                  </ul>
                </div>
              </td>
            </tr>
          </table>
          <div className="phone_frame_wrap">
            <section className="chat_title_wrap">
              <img src={ic_arrow_left} alt="" />
              <div className="chat_title_detail">
                <section>
                  {acadmeyData?.academy_profile && (
                    <img src={acadmeyData.academy_profile.Location} alt="" />
                  )}
                </section>
                <section>
                  <p>{acadmeyData?.academy_name}</p>
                </section>
              </div>
            </section>
            <section className="meassage_wrap pt_24 pr_24">
              <ul className="">
                {resData?.attach_files?.map((el) => {
                  return (
                    <li>
                      <img src={el.Location} alt="" />
                    </li>
                  );
                })}
              </ul>
              {(pathname.includes("material/add") ||
                pathname.includes("material/edit")) &&
                resData?.class_list.map((el, idx) => {
                  return (
                    <div key={idx} className="message_detail mt_20">
                      <section>
                        {acadmeyData?.academy_profile && (
                          <img
                            src={acadmeyData.academy_profile.Location}
                            alt=""
                          />
                        )}
                      </section>
                      <section>
                        <span>{acadmeyData?.academy_name}</span>
                        <p>
                          {targetType}
                          <br />
                          수업 : {el.class_name}
                          <br />
                          일시 : {el.class_times?.join(",")}
                          <br />
                          준비물 : {formatText(resData?.desc)}
                        </p>
                      </section>
                    </div>
                  );
                })}
              {pathname.includes("material/detail") && (
                <div className="message_detail mt_20">
                  <section>
                    {acadmeyData?.academy_profile && (
                      <img src={acadmeyData.academy_profile.Location} alt="" />
                    )}
                  </section>
                  <section>
                    <span>{acadmeyData?.academy_name}</span>
                    <p>
                      {targetType}
                      <br />
                      수업 : {resData?.class_name}
                      <br />
                      일시 : {resData?.class_datetime?.join(",")}
                      <br />
                      준비물 : {formatText(resData?.desc)}
                    </p>
                  </section>
                </div>
              )}
              {pathname.includes("homework") && (
                <div className="message_detail mt_20">
                  <section>
                    {acadmeyData?.academy_profile && (
                      <img src={acadmeyData.academy_profile.Location} alt="" />
                    )}
                  </section>
                  <section>
                    <span>{acadmeyData?.academy_name}</span>
                    <p>
                      {targetType}
                      <br />
                      {formatText(resData?.desc)}
                      <br />
                      기한 :{resData.due_date} 까지
                    </p>
                  </section>
                </div>
              )}
              {pathname.includes("notification") && (
                <div className="message_detail mt_20">
                  <section>
                    {acadmeyData?.academy_profile && (
                      <img src={acadmeyData.academy_profile.Location} alt="" />
                    )}
                  </section>
                  <section>
                    <span>{acadmeyData?.academy_name}</span>
                    <p>
                      {targetType}
                      <br />
                      {formatText(resData?.desc)}
                    </p>
                  </section>
                </div>
              )}
            </section>
            <img src={phone_bottom} alt="" className="bottom_bar" />
          </div>
          {(pathname.includes("add") || pathname.includes("edit")) &&
            mode === "submit" && (
              <div className="btn_group mt_36 mb_68">
                <button></button>
                <button className="btn_default half" onClick={submitData}>
                  발송
                </button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
