import { useEffect, useState, useRef } from "react";
import useFindPostAddr from "hooks/useFindPostAddr";
import useValidation from "hooks/useValidation";
import useRequestAPI from "hooks/useRequestAPI";
import useModalHandler from "hooks/useModalHandler";
import useFileHandler from "hooks/useFileHandler";
import useNiceCert from "hooks/useNiceCert";
import ToggleButton from "components/ToggleButton";
import formatDate from "utils/formatDate";
import isMobile from "utils/isMobile";
import no_image from "assets/img/profile_noimage.png";
import ic_camera from "assets/img/icon/ic_camera.png";
import ic_file_off from "assets/img/icon/ic_file_off.png";
import ic_edit from "assets/img/icon/ic_edit.png";
import show_pw from "assets/img/icon/show_pw.png";

export default function MyPage() {
  const { DaumPost, userAddr, detailAddr, openDaumPost, setUserAddr } =
    useFindPostAddr();
  const { form, isShowPw, setForm, validAttributes, visibilityPw, errorCheck } =
    useValidation();
  const { ModalComponent, openModal, sendModalData } = useModalHandler();
  const { openNicePopup, niceData } = useNiceCert("teacher");
  const { fileData, setFileData, uploadFile, uploadS3 } = useFileHandler([
    "jpg",
    "jpeg",
    "png",
    "gif",
  ]);
  const { requestAPI, Loading, alertMsg, setMessage } =
    useRequestAPI("마이페이지");
  const [resData, setResData] = useState();
  const [disclosure, setDisclosure] = useState({
    view_1: true,
    view_2: true,
    view_3: true,
  });
  const [myInfoEdit, setMyInfoEdit] = useState(false);
  const fileInputMc = useRef(null);

  const getData = async () => {
    const res = await requestAPI("post", "teacher/my-info", {});
    if (!res) return;
    const data = res.data.data;
    setForm({
      ...form,
      mb_email: { val: data.email, isValid: true },
    });
    setUserAddr({
      zonecode: data.post_code,
      addr: data.address,
      detailAddr: data.detail_address,
    });
    setDisclosure({
      view_1: data.is_subject_view,
      view_2: data.is_license_view,
      view_3: data.is_working_view,
    });
    setResData(data);
  };

  const submitData = async () => {
    if (form.mb_password.val || form.mb_confirm_password.val) {
    }
    const body = {
      teacher_post_code: userAddr.zonecode,
      teacher_address: userAddr.addr,
      teacher_detail_address: userAddr.detailAddr,
      teacher_email: form.mb_email.val,
    };
    if (form.mb_password.val || form.mb_confirm_password.val) {
      if (form.mb_password.isValid && form.mb_confirm_password.isValid) {
        body.teacher_password = form.mb_password.val;
        body.teacher_confirm_password = form.mb_confirm_password.val;
      } else {
        return setMessage(
          "비밀번호 입력형식 혹은 비밀번호 확인란이 잘못 입력되었습니다."
        );
      }
    }

    const domainExp = {
      naver: "com",
      gmail: "com",
      yahoo: "com",
      daum: "net",
      hanmail: "net",
    };
    const domain = form.mb_email.val.split("@")[1].split(".");
    if (
      Object.keys(domainExp).includes(domain[0]) &&
      domain[1] !== domainExp[domain[0]]
    ) {
      return setMessage("이메일 끝자리를 다시 확인해주세요.");
    }

    const res = await requestAPI("patch", "teacher/option-info/update", body);
    if (!res) return;
    await getData();
    setMessage(alertMsg.change);
    setMyInfoEdit(false);
  };

  const submitDisclosureData = async (e) => {
    const name = e.target.dataset.name;
    const body = { target: name, is_view: e.target.checked };
    setDisclosure({ ...disclosure, [name]: e.target.checked });
    requestAPI("patch", "teacher/my-info/view-status", body);
  };

  const submitNiceInfoData = async () => {
    const res = await requestAPI("patch", "teacher/basic-info/update", {
      ...niceData,
    });
    if (!res) return;
    await getData();
    setMessage(alertMsg.change);
  };

  const submitProfileImg = async () => {
    const uploader = localStorage.getItem("acadmey_id") ? "manager" : "teacher";
    const data = await uploadS3(uploader);
    if (!data) return setMessage(alertMsg.error_file);
    const res = await requestAPI("patch", "teacher/my-info/profile-picture", {
      profile_path: data,
    });
    if (!res) return;
    await getData();
    setMessage(alertMsg.change);
    setFileData([]);
  };

  const downloadFile = async (key, fileName) => {
    const res = await requestAPI("post", "common/s3/teacher/signed-download", {
      file_name: [key],
    });
    const link = document.createElement("a");
    link.href = res.data.data.signed_url;
    link.download = fileName;
    link.click();
  };

  useEffect(() => {
    if (isMobile() && fileData[0]) submitProfileImg();
  }, [fileData]);

  useEffect(() => {
    getData();
  }, [sendModalData]);

  useEffect(() => {
    if (niceData) {
      submitNiceInfoData();
    }
  }, [niceData]);

  return (
    <div id="myPage" className="container nav_none">
      <Loading />
      <div className="inner" style={{ display: "none" }}>
        <header>
          <h2>마이페이지</h2>
        </header>
      </div>
      <section className="user_info_wrap mobile mt_12">
        <div className="profile">
          <div className="img_wrap">
            <div className="img_border">
              <img
                src={
                  fileData[0]?.Location ||
                  resData?.profile_path.Location ||
                  no_image
                }
                alt=""
              />
            </div>
            <label for="inputFileMc" className="profile_input_btn">
              <img
                src={ic_camera}
                alt="프로필 사진 등록"
                className="ic_camera"
              />
            </label>
          </div>
          <input
            ref={fileInputMc}
            type="file"
            id="inputFileMc"
            className="file_input"
            onChange={uploadFile}
          />
          <strong className="mt_16 ">{resData?.name} 선생님</strong>

          <div className="table_wrap mt_32 mb_20">
            <table>
              <thead>
                <tr>
                  <th>내정보</th>
                  <td>
                    {myInfoEdit ? (
                      <>
                        <button
                          className="mr_12"
                          onClick={() => {
                            setMyInfoEdit(false);
                            getData();
                          }}
                        >
                          &times; 취소
                        </button>
                        <button onClick={submitData}>✔ 저장</button>
                      </>
                    ) : (
                      <button onClick={() => setMyInfoEdit(true)}>
                        <img src={ic_edit} alt="" />
                        수정
                      </button>
                    )}
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>성별</th>
                  <td>{resData?.gender}</td>
                </tr>
                <tr>
                  <th>생년월일</th>
                  <td>{formatDate(resData?.birth, ".")}</td>
                </tr>
                <tr>
                  <th>휴대폰번호</th>
                  <td>{resData?.mobile}</td>
                </tr>
                <tr>
                  <th>현재상태</th>
                  <td>{resData?.is_working ? "재직중" : "휴직중"}</td>
                </tr>
                <tr>
                  <th>주소</th>
                  <td>
                    <div className="addr_wrap">
                      <div className="addr_search_box">
                        <input
                          type="text"
                          className="disabled post_input"
                          placeholder="우편번호"
                          value={userAddr.zonecode}
                          readOnly
                        />
                        <div className="btn_group short ml_8">
                          <button
                            className="btn_default"
                            onClick={openDaumPost}
                            disabled={!myInfoEdit}
                          >
                            검색
                          </button>
                        </div>
                      </div>
                      <input
                        type="text"
                        className="disabled"
                        placeholder="기본주소"
                        value={userAddr.addr}
                        readOnly
                      />
                      <input
                        type="text"
                        className=""
                        placeholder="상세주소"
                        value={userAddr.detailAddr}
                        onChange={detailAddr}
                        readOnly={!myInfoEdit}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>이메일</th>
                  <td>
                    <input
                      type="text"
                      placeholder="example@example.com"
                      {...validAttributes("mb_email")}
                      readOnly={!myInfoEdit}
                    />
                  </td>
                </tr>

                {myInfoEdit && (
                  <>
                    <tr>
                      <th>비밀번호</th>
                      <td>
                        <input
                          type={isShowPw}
                          className={errorCheck("mb_password")?.state}
                          placeholder="대문자/소문자/특수기호 포함 8자 이상"
                          {...validAttributes("mb_password")}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>비밀번호 확인</th>
                      <td className="pw">
                        <img
                          src={show_pw}
                          alt=""
                          className="show_pw pointer"
                          onClick={visibilityPw}
                        />
                        <input
                          type={isShowPw}
                          className={errorCheck("mb_confirm_password")?.state}
                          {...validAttributes("mb_confirm_password")}
                          placeholder="다시 입력해주세요."
                        />
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section className="user_info_wrap desktop">
        <div className="profile">
          <div className="img_wrap">
            <div className="img_border">
              <img
                src={
                  fileData[0]?.Location ||
                  resData?.profile_path.Location ||
                  no_image
                }
                alt=""
                className="profile_img"
              />
              <label for="inputFile" className="profile_input_btn">
                <img
                  src={ic_camera}
                  alt="프로필 사진 등록"
                  className="ic_camera"
                />
              </label>
            </div>
          </div>

          <input
            type="file"
            id="inputFile"
            className="file_input"
            onChange={uploadFile}
          />

          <div className="btn_group">
            <button
              className="btn_default slim"
              onClick={submitProfileImg}
              disabled={!fileData[0]}
            >
              변경하기
            </button>
          </div>
        </div>

        <div className="user_info_lf">
          <dl>
            <div className="info_wrap">
              <dt>이름</dt>
              <dd>{resData?.name}</dd>
            </div>
            <div className="info_wrap">
              <dt>성별</dt>
              <dd>{resData?.gender}</dd>
            </div>
            <div className="info_wrap">
              <dt>생년월일</dt>
              <dd>{formatDate(resData?.birth, ".")}</dd>
            </div>
            <div className="info_wrap">
              <dt>휴대폰번호</dt>
              <dd>{resData?.mobile}</dd>
            </div>
            <div className="info_wrap">
              <dt>현재상태</dt>
              <dd>{resData?.is_working ? "재직중" : "휴직중"}</dd>
            </div>

            <p className="info_txt">
              * 인적사항은 본인인증을 통해 변경 가능합니다.
            </p>

            <div className="btn_group">
              <button className="btn_default slim" onClick={openNicePopup}>
                변경하기
              </button>
            </div>
          </dl>
        </div>

        <div className="user_info_line"></div>

        <div className="user_info_rt">
          <dl>
            <div className="info_wrap addr">
              <dt>주소</dt>
              <dd className="addr_container">
                <div className="addr_wrap">
                  <input
                    type="text"
                    className="disabled post_input"
                    placeholder="우편번호"
                    value={userAddr.zonecode}
                    readOnly
                  />
                  <div className="btn_group">
                    <button
                      type="button"
                      className="btn_default search_btn"
                      onClick={openDaumPost}
                    >
                      검색
                    </button>
                  </div>
                </div>
                <input
                  type="text"
                  className="disabled"
                  placeholder="기본주소"
                  value={userAddr.addr}
                  readOnly
                />
                <input
                  type="text"
                  className=""
                  placeholder="상세주소"
                  value={userAddr.detailAddr}
                  onChange={detailAddr}
                />
              </dd>
            </div>

            <div className="info_wrap">
              <dt>이메일</dt>
              <dd>
                <input
                  type="text"
                  placeholder="example@example.com"
                  {...validAttributes("mb_email")}
                />
              </dd>
            </div>
            <div className="info_wrap">
              <dt className="pw">
                비밀번호
                <img
                  src={show_pw}
                  alt=""
                  className="show_pw pointer ml_8"
                  onClick={visibilityPw}
                />
              </dt>
              <dd>
                <input
                  type={isShowPw}
                  className={errorCheck("mb_password")?.state}
                  placeholder="대문자/소문자/특수기호 포함 8자 이상"
                  {...validAttributes("mb_password")}
                />
              </dd>
            </div>
            <div className="info_wrap">
              <dt>비밀번호확인</dt>
              <dd>
                <input
                  type={isShowPw}
                  className={errorCheck("mb_confirm_password")?.state}
                  placeholder="대문자/소문자/특수기호 포함 8자 이상"
                  {...validAttributes("mb_confirm_password")}
                />
              </dd>
            </div>

            <div className="btn_group">
              <button className="btn_default slim" onClick={submitData}>
                변경하기
              </button>
            </div>
          </dl>
        </div>
      </section>

      <section className="user_work_wrap">
        <div className="table_wrap">
          <table className="class_list">
            <colgroup>
              <col />
              <col width={"25%"} />
              <col width={"auto"} />
            </colgroup>
            <thead>
              <tr>
                <th>담당과목</th>
                <td>
                  <div className="is_open_wrap">
                    공개유무
                    <ToggleButton
                      index={0}
                      dataName="view_1"
                      onChange={submitDisclosureData}
                      checked={disclosure.view_1}
                    />
                  </div>
                </td>
                <td className="add_btn_wrap">
                  <button
                    onClick={() => {
                      openModal("subjectDetail", "add");
                    }}
                  >
                    + 추가하기
                  </button>
                </td>
              </tr>
            </thead>
            <tbody>
              {resData?.subject_list.length === 0 && (
                <tr>
                  <td colSpan={3}>등록된 담당과목이 없습니다.</td>
                </tr>
              )}
              {resData?.subject_list.map((content, idx) => (
                <tr key={idx}>
                  <th
                    className="subject"
                    onClick={() => {
                      openModal("subjectDetail", "edit", { content, idx });
                    }}
                  >
                    {content.field_name}
                  </th>
                  <td className="desktop">{content.subject_name}</td>
                  <td className="level_list">
                    <p className="mobile mb_8">{content.subject_name}</p>
                    <ul>
                      {content.target
                        .sort((a, b) => a.split("_")[1] - b.split("_")[1])
                        .map((el, idx) => (
                          <li key={idx} className={`level_item ${el}`} id={el}>
                            {
                              {
                                target_1: "유아",
                                target_2: "초등",
                                target_3: "중등",
                                target_4: "고등",
                              }[el]
                            }
                          </li>
                        ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="table_wrap">
          <table className="certificate_wrap">
            <colgroup>
              <col />
              <col width={"25%"} className="desktop" />
              <col width={"40%"} className="mobile" />
              <col width={"20%"} className="mobile" />
            </colgroup>
            <thead>
              <tr>
                <th>자격증</th>
                <td>
                  <div className="is_open_wrap">
                    공개유무
                    <ToggleButton
                      index={1}
                      dataName="view_2"
                      onChange={submitDisclosureData}
                      checked={disclosure.view_2}
                    />
                  </div>
                </td>
                <td className="add_btn_wrap">
                  <button
                    onClick={() => {
                      openModal("licenseDetail", "add");
                    }}
                  >
                    + 추가하기
                  </button>
                </td>
              </tr>
            </thead>
            <tbody>
              {resData?.license_list.length === 0 && (
                <tr>
                  <td colSpan={3}>등록된 자격증이 없습니다.</td>
                </tr>
              )}
              {resData?.license_list.map((content, idx) => (
                <tr key={idx}>
                  <th
                    onClick={() => {
                      openModal("licenseDetail", "edit", { content, idx });
                    }}
                  >
                    {content.license_name}
                  </th>
                  <td>
                    <div className="certificate_date_wrap">
                      {formatDate(content.license_date, ".")} 취득{" "}
                      {content.license_path && (
                        <img
                          src={ic_file_off}
                          alt=""
                          className="pointer"
                          onClick={() => {
                            downloadFile(
                              content.license_path[0].Key,
                              content.license_path[0].name
                            );
                          }}
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    {content.is_certified ? (
                      <span className="bf_auth_badge"></span>
                    ) : (
                      <span className="not_match_badge"></span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="table_wrap">
          <table className="history_wrap">
            <colgroup>
              <col />
              <col width={"25%"} className="desktop" />
              <col width={"40%"} className="mobile" />
              <col width={"20%"} className="mobile" />
            </colgroup>
            <thead>
              <tr>
                <th>재직이력</th>
                <td>
                  <div className="is_open_wrap">
                    공개유무
                    <ToggleButton
                      index={2}
                      dataName="view_3"
                      onChange={submitDisclosureData}
                      checked={disclosure.view_3}
                    />
                  </div>
                </td>
                <td className="add_btn_wrap">
                  <button
                    onClick={() => {
                      openModal("historyDetail", "add");
                    }}
                  >
                    + 추가하기
                  </button>
                </td>
              </tr>
            </thead>
            <tbody>
              {resData?.work_history_list.length === 0 && (
                <tr>
                  <td colSpan={3}>등록된 재직이력이 없습니다.</td>
                </tr>
              )}
              {resData?.work_history_list.map((content, idx) => (
                <tr key={idx}>
                  <th
                    className="hide_text"
                    onClick={() => {
                      if (content.is_cert)
                        return setMessage(
                          "인증된 재직이력은 수정할 수 없습니다."
                        );
                      openModal("historyDetail", "edit", { content, idx });
                    }}
                  >
                    {content.name}
                  </th>
                  <td className="desktop">
                    {formatDate(content.start_date, ".")}~
                    {content.end_date
                      ? formatDate(content.end_date, ".")
                      : "재직중"}
                  </td>
                  <td className="mobile">
                    {formatDate(content.start_date, ".").slice(2)}~
                    {content.end_date
                      ? formatDate(content.end_date, ".").slice(2)
                      : "재직중"}
                  </td>
                  <td>
                    {content.is_cert && <span className="bf_auth_badge"></span>}
                    {content.is_owner && (
                      <span className="biz_auth_badge"></span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>

      <ModalComponent />
      <DaumPost />
    </div>
  );
}
