import { useLocation, useNavigate } from "react-router-dom";

export default function ReturnToList() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const returnPath = pathname.includes("academy")
    ? "/" + pathname.split("/")[1] + "/" + pathname.split("/")[2] + "/1"
    : "/" + pathname.split("/")[1] + "/1";

  return (
    <button
      className="btn_default return desktop"
      onClick={() => {
        navigate(returnPath);
      }}
    >
      목록으로 돌아가기
    </button>
  );
}
