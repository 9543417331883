import useInputHandler from "hooks/useInputHandler";
import useRequestAPI from "hooks/useRequestAPI";
import { useNavigate } from "react-router-dom";
import ReturnToList from "components/ReturnToList";

export default function AcademyClassroomDetail() {
  const navigate = useNavigate();
  const [dataForm, , attributes] = useInputHandler({
    room_name: "",
    capacity: "",
    location: "",
    memo: "",
  });
  const { requestAPI, Loading, alertMsg, setMessage } = useRequestAPI();

  const submitData = async () => {
    const res = await requestAPI("put", "academy/classroom/create", {
      ...dataForm,
    });
    if (!res) return;
    setMessage({
      text: alertMsg.add,
      confirm: () => navigate("/academy/classroom/1"),
    });
  };

  return (
    <div className="inner classroom_add">
      <Loading />
      <header className="desktop">
        <h2>강의실 등록</h2>
        <div className="btn_group">
          <ReturnToList />
        </div>
      </header>
      <section className="content_wrap">
        <table className="detail">
          <tr className="desktop">
            <th>명칭</th>
            <td>
              <div className="cell_content_wrap">
                <input type="text" {...attributes("room_name")} />
              </div>
            </td>
            <th>정원</th>
            <td>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  {...attributes("capacity")}
                  className="small"
                  data-format="number"
                />
                명
              </div>
            </td>
          </tr>

          <tr className="mobile">
            <th>명칭</th>
            <td>
              <div className="cell_content_wrap">
                <input type="text" {...attributes("room_name")} />
              </div>
            </td>
          </tr>
          <tr className="mobile">
            <th>정원</th>
            <td>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  className="small"
                  {...attributes("capacity")}
                  data-format="number"
                />
                명
              </div>
            </td>
          </tr>

          <tr>
            <th>위치</th>
            <td colSpan={3}>
              <div className="cell_content_wrap">
                <textarea {...attributes("location")} />
              </div>
            </td>
          </tr>
          <tr>
            <th>
              메모
              <br />
              (시설 및<br />
              특이사항)
            </th>
            <td colSpan={3}>
              <div className="cell_content_wrap">
                <textarea {...attributes("memo")} />
              </div>
            </td>
          </tr>
        </table>
        <div className="btn_group mt_48">
          <button className=""></button>
          <button className="btn_default half" onClick={submitData}>
            등록
          </button>
        </div>
      </section>
    </div>
  );
}
