import ic_arrow from "assets/img/icon/ic_arrow.png";
import ic_slide_off from "assets/img/icon/ic_slide_off.png";
import ic_view_all from "assets/img/icon/ic_view_all.png";
import ic_refresh from "assets/img/icon/ic_refresh.png";
import ic_bus from "assets/img/icon/ic_bus.png";
import ic_bus_mc from "assets/img/icon/ic_bus_mc.png";
import { useEffect, useRef, useState } from "react";
import useModalHandler from "hooks/useModalHandler";
import useRequestAPI from "hooks/useRequestAPI";
import useSelectBox from "hooks/useSelectBox";
import { useNavigate } from "react-router-dom";
import guide_home from "assets/file/guide_home.pdf";

export default function Home() {
  const formattedDateTime = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const formattedDateTime = `${year}.${month}.${day} ${hours}:${minutes}`;
    return formattedDateTime;
  };

  const navigate = useNavigate();
  const { requestAPI, Loading } = useRequestAPI();
  const { ModalComponent, openModal } = useModalHandler();
  const [visibleContent, setVisibleContent] = useState({
    class: true,
    myClass: false,
    bus: true,
    busDetail: false,
  });
  const [resClassData, setResClassData] = useState();
  const [resShuttleData, setResShuttleData] = useState();
  const [updateTime, setUpdateTime] = useState({
    class: formattedDateTime(),
    shuttle: formattedDateTime(),
  });
  const [spinAnimation, setSpinAnimation] = useState({});

  const [McBoardClass, selectMcBoardClass, , , classCodeList] = useSelectBox([
    { name: "수업명", code: "class_name" },
    { name: "수업시간", code: "class_time" },
    { name: "강의실", code: "classroom_name" },
    { name: "학생수", code: "student_cnt" },
    { name: "숙제리스트", code: "homework" },
    { name: "준비물", code: "materials" },
    { name: "중복여부", code: "dup" },
  ]);

  const classListDataFormat = (type, data, device) => {
    switch (type) {
      case "teacher_name": {
        return localStorage.getItem("manager_id") == data.manager_id
          ? "나"
          : data.teacher_name;
      }
      case "class_time": {
        return data.start_time.slice(0, -3) + "-" + data.end_time.slice(0, -3);
      }
      case "student_cnt_attr": {
        return {
          className: "pointer " + device,
          onClick: () => navigate("/academy/schedule/detail/" + data.class_id),
        };
      }
      case "homework": {
        return (
          <>
            <span className="hide_text">{data.homework?.desc || "-"}</span>
            {data.homework &&
              "(" +
                data.homework?.read + //키명이 read 지만 숙제완료여부임.
                "/" +
                data.homework?.total +
                ")"}
            <br />
            {data.homework &&
              data.homework?.total_rows > 0 &&
              "외 " + data.homework?.total_rows + "건"}
          </>
        );
      }
      case "homework_attr": {
        return {
          className: "pointer " + device,
          onClick: () =>
            navigate("/homework/1", {
              state: {
                class_id: data.class_id,
                is_dash: true,
              },
            }),
        };
      }
      case "materials": {
        return data.materials?.desc || "-";
      }
      case "materials_attr": {
        return {
          className: "hide_text pointer " + device,
          onClick: () => {
            navigate("/material/1", {
              state: {
                class_id: data.class_id,
                is_dash: true,
              },
            });
          },
        };
      }
      case "dup": {
        return data.dup ? (
          <div className="btn_group">
            <button
              className="btn_default alert slim"
              onClick={() => {
                openModal("dupClassList", "view", data.dup);
              }}
            >
              중복
            </button>
          </div>
        ) : (
          "-"
        );
      }
      default: {
        if (type.includes("_attr")) return;
        else return data[type];
      }
    }
  };

  const [McBoardShuttle, selectMcBoardShuttle, , , shuttleCodeList] =
    useSelectBox([
      { name: "차량번호", code: "bus_no" },
      { name: "출발시간", code: "departure_time" },
      { name: "도착시간", code: "arrival_time" },
      { name: "히차선생님", code: "teacher_name" },
    ]);

  const shuttleListDataFormat = (type, data, device) => {
    switch (type) {
      case "departure_time":
      case "arrival_time": {
        return data[type].slice(0, -3);
      }
      default: {
        return data[type];
      }
    }
  };

  const getClassData = async () => {
    const res = await requestAPI("post", "academy/dashboard/class-list", {});
    if (!res) return;
    const data = res.data.data;
    setResClassData(
      data.class_list.map((el) => {
        return {
          ...el,
          homework: JSON.parse(el.homework),
          materials: JSON.parse(el.materials),
        };
      })
    );
    setUpdateTime({
      ...updateTime,
      class: formattedDateTime(),
    });
  };

  const getShuttleData = async () => {
    const res = await requestAPI("post", "academy/dashboard/bus-list", {});
    if (!res) return;
    const data = res.data.data;

    for (let el of data.shuttle_bus_list) {
      const { bus_id, bus_driver_id } = el;
      let stationList = el.bus_stop_list?.map((station) => [
        { station: "" },
        station,
      ]);
      stationList = stationList.flat().map((station, idx) => ({
        ...station,
        location: idx + 1,
      }));
      stationList.unshift({ station: "출발", location: 0 });
      if (el.direction === "0") {
        ["", "도착"].forEach((station) =>
          stationList.push({ station, location: stationList.length })
        );
      }
      el.station_location_list = stationList;
      el.location = 0;
      if (!isRunning(el.departure_time, el.arrival_time)) continue;
      const res = await requestAPI("post", "academy/dashboard/bus-location", {
        bus_id,
        bus_driver_id,
      });
      if (!res) continue;
      const location = res.data.data.location;
      el.location = location;
    }
    setResShuttleData(data.shuttle_bus_list);
    setUpdateTime({
      ...updateTime,
      shuttle: formattedDateTime(),
    });
  };

  const reloadData = async (e) => {
    const type = e.target.dataset.ani;
    setSpinAnimation({ ...spinAnimation, [type]: true });
    if (type === "class") await getClassData();
    else await getShuttleData();
    setSpinAnimation({ ...spinAnimation, [type]: false });
  };

  const visibleContentHandler = (type) => {
    setVisibleContent({ ...visibleContent, [type]: !visibleContent[type] });
  };

  const isRunning = (start, end) => {
    const currentDate = new Date();
    const currentHour = String(currentDate.getHours()).padStart(2, "0");
    const currentMinute = String(currentDate.getMinutes()).padStart(2, "0");
    const currentSecond = String(currentDate.getSeconds()).padStart(2, "0");
    const formattedTime = `${currentHour}:${currentMinute}:${currentSecond}`;
    return start <= formattedTime && formattedTime <= end;
  };

  useEffect(() => {
    getClassData();
    getShuttleData();
  }, []);

  return (
    <div id="home" className="container">
      <Loading />
      <ModalComponent />
      <div className="inner">
        <div className="btn_group">
          <button></button>
          <button className="btn_default guide medium">
            <a href={guide_home} download={"에듀빌리_홈_사용가이드"}>
              사용가이드
            </a>
          </button>
        </div>
        <section className="board_wrap">
          <header>
            <h2>다가오는 수업</h2>

            <ul>
              {visibleContent.class ? (
                <li
                  onClick={() => {
                    visibleContentHandler("class");
                  }}
                >
                  <img src={ic_slide_off} alt="" />
                  목록접기
                </li>
              ) : (
                <li
                  onClick={() => {
                    visibleContentHandler("class");
                  }}
                >
                  <img src={ic_arrow} alt="" />
                  펼쳐보기
                </li>
              )}

              {visibleContent.myClass ? (
                <li
                  onClick={() => {
                    visibleContentHandler("myClass");
                  }}
                >
                  <img src={ic_view_all} alt="" />
                  전체보기
                </li>
              ) : (
                <li
                  onClick={() => {
                    visibleContentHandler("myClass");
                  }}
                >
                  <img src={ic_slide_off} alt="" />
                  나의 일정만 보기
                </li>
              )}
              <li className="refresh">
                <button className="">
                  <span className="desktop">{updateTime.class} 기준 </span>
                  <span className="mobile">
                    {updateTime.class.slice(-5)} 기준{" "}
                  </span>
                  <img
                    src={ic_refresh}
                    alt=""
                    data-ani="class"
                    className={spinAnimation?.class && "spin"}
                    onClick={reloadData}
                  />
                </button>
              </li>
            </ul>
          </header>
          {visibleContent.class && (
            <table>
              <colgroup className="desktop">
                <col width={"10%"} />
                <col width={"10%"} />
                <col width={"15%"} />
                <col width={"15%"} />
                <col width={"10%"} />
                <col width={"10%"} />
                <col width={"10%"} />
                <col width={"10%"} />
                <col width={"10%"} />
              </colgroup>
              <thead>
                <tr className="mobile">
                  <th>상태</th>
                  <th>
                    <McBoardClass />
                  </th>
                  <th>담당</th>
                </tr>
                <tr className="desktop">
                  <th>상태</th>
                  <th>담당</th>
                  <th>수업시간</th>
                  <th>수업명</th>
                  <th>강의실</th>
                  <th>학생수</th>
                  <th>숙제리스트</th>
                  <th>준비물</th>
                  <th>중복여부</th>
                </tr>
              </thead>
              <tbody>
                {(visibleContent.myClass
                  ? resClassData.filter(
                      (el) =>
                        el.manager_id == localStorage.getItem("manager_id")
                    )
                  : resClassData
                )?.map((el, idx) => {
                  return (
                    <tr
                      key={idx}
                      className={
                        localStorage.getItem("manager_id") == el.manager_id &&
                        "my_data"
                      }
                    >
                      <td>
                        <div className="btn_group">
                          <button
                            className={
                              "btn_default slim " +
                              (isRunning(el.start_time, el.end_time)
                                ? "green3"
                                : "del")
                            }
                          >
                            {isRunning(el.start_time, el.end_time)
                              ? "수업중"
                              : "대기"}
                          </button>
                        </div>
                      </td>
                      {classCodeList.map((type, idx) => (
                        <td
                          key={idx}
                          className="desktop"
                          {...classListDataFormat(
                            type + "_attr",
                            el,
                            "desktop"
                          )}
                        >
                          {classListDataFormat(type, el)}
                        </td>
                      ))}
                      {/* 모바일 */}
                      <td
                        className="mobile"
                        {...classListDataFormat(
                          selectMcBoardClass.code + "_attr",
                          el,
                          "mobile"
                        )}
                      >
                        {classListDataFormat(selectMcBoardClass.code, el)}
                      </td>
                      <td>
                        {localStorage.getItem("manager_id") == el.manager_id
                          ? "나"
                          : el.teacher_name}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </section>

        <section className="board_wrap">
          <header>
            <h2>셔틀버스 현황</h2>

            <ul>
              {visibleContent.bus ? (
                <li
                  onClick={() => {
                    visibleContentHandler("bus");
                  }}
                >
                  <img src={ic_slide_off} alt="" />
                  목록접기
                </li>
              ) : (
                <li
                  onClick={() => {
                    visibleContentHandler("bus");
                  }}
                >
                  <img src={ic_arrow} alt="" />
                  펼쳐보기
                </li>
              )}

              {visibleContent.busDetail ? (
                <li
                  onClick={() => {
                    visibleContentHandler("busDetail");
                  }}
                >
                  <img src={ic_slide_off} alt="" />
                  요약보기
                </li>
              ) : (
                <li
                  onClick={() => {
                    visibleContentHandler("busDetail");
                  }}
                >
                  <img src={ic_arrow} alt="" />
                  상세보기
                </li>
              )}
              <li className="refresh">
                <button>
                  <span className="desktop">{updateTime.shuttle} 기준 </span>
                  <span className="mobile">
                    {updateTime.shuttle.slice(-5)} 기준{" "}
                  </span>
                  <img
                    src={ic_refresh}
                    alt=""
                    className={spinAnimation?.shuttle && "spin"}
                    data-ani="shuttle"
                    onClick={reloadData}
                  />
                </button>
              </li>
            </ul>
          </header>

          {visibleContent.bus && (
            <table>
              <thead>
                <tr className="mobile">
                  <th>상태</th>
                  <th>
                    <McBoardShuttle />
                  </th>
                  <th>기사님</th>
                </tr>
                <tr className="desktop">
                  <th>상태</th>
                  <th>차량번호</th>
                  <th>출발시간</th>
                  <th>도착시간</th>
                  <th>하차선생님</th>
                  <th>기사님</th>
                </tr>
              </thead>
              <tbody>
                {resShuttleData?.map((el, idx) => {
                  return (
                    <>
                      <tr
                        key={idx}
                        className="pointer"
                        onClick={() => {
                          navigate("/academy/shuttle/detail/" + el.bus_id);
                        }}
                      >
                        <td>
                          <div className="btn_group">
                            <button
                              className={
                                "btn_default slim del " +
                                (isRunning(el.departure_time, el.arrival_time)
                                  ? "green3"
                                  : "del")
                              }
                            >
                              {isRunning(el.departure_time, el.arrival_time)
                                ? "운행중"
                                : "대기"}
                            </button>
                          </div>
                        </td>
                        {shuttleCodeList.map((type, idx) => (
                          <td key={idx} className="desktop">
                            {shuttleListDataFormat(type, el)}
                          </td>
                        ))}
                        {/* 모바일 */}
                        <td className="mobile">
                          {classListDataFormat(selectMcBoardShuttle.code, el)}
                        </td>
                        {/* 모바일 */}
                        <td>{el.driver_name}</td>
                      </tr>

                      {visibleContent.busDetail && ( //데스크탑 실시간 버스위치
                        <tr className="bus_location desktop">
                          <td colSpan={6}>
                            <div className="bus_location_wrap">
                              <ul>
                                {el.station_location_list?.map(
                                  (station, stationIdx) => (
                                    <li
                                      key={stationIdx}
                                      className={
                                        el.location - 1 >= station.location
                                          ? "pass"
                                          : ""
                                      }
                                      onClick={() => {
                                        if (!station.bus_stop_id) return;
                                        openModal("boardingStudent", "", {
                                          bus_id: el.bus_id,
                                          bus_stop_id: station.bus_stop_id,
                                          bus_driver_id: el.bus_driver_id,
                                          direction: el.direction,
                                        });
                                      }}
                                    >
                                      <p>{station.station}</p>
                                      {el.location === station.location && (
                                        <img src={ic_bus} alt="" />
                                      )}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </td>
                        </tr>
                      )}

                      {visibleContent.busDetail && ( //모바일 실시간 버스위치
                        <tr className="bus_location mobile">
                          <td colSpan={3}>
                            <MobileStationList
                              data={el}
                              openModal={openModal}
                            />
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          )}
        </section>
      </div>
    </div>
  );
}

function MobileStationList({ data, openModal }) {
  const [visibleContent, setVisibleContent] = useState({
    class: true,
    myClass: false,
    bus: true,
    busDetail: false,
  });
  const listRef = useRef();

  useEffect(() => {
    listRef.current.scrollTop =
      document.querySelector(".bus_icon")?.getBoundingClientRect().top -
      listRef.current.getBoundingClientRect().top -
      56;
  }, []);

  return (
    <div className="bus_location_wrap">
      <ul ref={listRef} className="bus_station_list">
        {data.station_location_list?.map((station, stationIdx) => (
          <li
            key={stationIdx}
            className={data.location - 1 >= station.location ? "pass" : ""}
            onClick={() => {
              if (!station.bus_stop_id) return;
              openModal("boardingStudent", "", {
                bus_id: data.bus_id,
                bus_stop_id: station.bus_stop_id,
                bus_driver_id: data.bus_driver_id,
              });
            }}
          >
            <p>{station.station}</p>
            {data.location === station.location && (
              <>
                <img src={ic_bus} alt="" className="bus_icon desktop" />
                <img src={ic_bus_mc} alt="" className="bus_icon mobile" />
              </>
            )}
            <div
              className={
                "bg " + (data.location === station.location ? "current" : "")
              }
            ></div>
          </li>
        ))}
      </ul>
    </div>
  );
}
