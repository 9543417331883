import { useEffect, useState } from "react";
import arrow from "assets/img/icon/ic_arrow.png";

export default function useSelectBox(list) {
  const [selectList, setSelectList] = useState(list);
  const [selectValue, setSelectValue] = useState(list[0]);
  const [isOnSlide, setIsOnSlide] = useState(false);
  const [componentKey] = useState(
    Math.floor(100000 + Math.random() * 9000000000)
  );

  const codeList = list.map((el) => el.code);

  const handleItemClick = (e) => {
    const clickedElement = e.target;
    const ancestorWithClass = findAncestorWithClass(
      clickedElement,
      "component_no_" + componentKey
    );

    if (ancestorWithClass) {
      setIsOnSlide(isOnSlide);
    } else {
      setIsOnSlide(false);
    }
  };

  const findAncestorWithClass = (element, className) => {
    let currentElement = element;
    while (currentElement) {
      if (currentElement.classList.contains(className)) {
        return currentElement;
      }

      currentElement = currentElement.parentElement;
    }
    return null;
  };

  useEffect(() => {
    document.removeEventListener("click", handleItemClick);
    document.addEventListener("click", handleItemClick);
    return () => {
      document.removeEventListener("click", handleItemClick);
    };
  }, [isOnSlide]);

  function SelectBox({ className, readOnly }) {
    return (
      <div
        className={
          "selectbox_wrap component_no_" + componentKey + " " + className
        }
        onClick={() => {
          setIsOnSlide(!isOnSlide);
        }}
      >
        <p
          className={
            "current_value " +
            (isOnSlide && "open ") +
            (readOnly && "read_only")
          }
        >
          {selectValue?.name}
          <img src={arrow} alt="" />
        </p>
        {!readOnly && isOnSlide && (
          <ul className="etc_value">
            {selectList.map((el, idx) => (
              <li
                key={idx}
                onClick={() => {
                  setSelectValue(el);
                }}
              >
                {el.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }

  return [SelectBox, selectValue, setSelectValue, setSelectList, codeList];
}
