import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import useSelectBox from "hooks/useSelectBox";
import useModalHandler from "hooks/useModalHandler";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import useRequestAPI from "hooks/useRequestAPI";
import formatExcelTitle from "utils/formatExcelTitle";
import permissionCheck from "utils/permissionCheck";

export default function AcademyClassroom() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useLocation();
  const { ModalComponent } = useModalHandler();
  const [SelectBox, selectValue] = useSelectBox([
    { name: "전체", code: "all" },
    { name: "강의실명", code: "room_name" },
    { name: "메모", code: "memo" },
  ]);
  const [searchKeyword, setSerchKeword] = useState("");
  const [currPage, setCurrPage] = useState(id - 1);
  const { requestAPI, Loading } = useRequestAPI();
  const [resData, setResData] = useState();
  const [currSearchData, setCurrSearchData] = useState({
    page: currPage,
    size: 5,
    search_type: "all",
    search_keyword: "",
  });
  const [McBoardContent, selectMcBoardContent] = useSelectBox([
    { name: "정원", code: "capacity" },
    { name: "메모", code: "memo" },
  ]);

  const getData = async (e) => {
    e?.preventDefault();
    let body = { ...currSearchData, page: currPage };
    if (e?.target.tagName === "BUTTON") {
      body = {
        page: currPage,
        size: 5,
        search_type: selectValue?.code,
        search_keyword: searchKeyword,
      };
      setCurrSearchData(body);
      return setCurrPage(0);
    }
    const res = await requestAPI("post", "academy/classroom", body);
    if (!res) return;
    setResData(res.data.data);
  };

  const goToDetail = (id) => {
    navigate("/academy/classroom/detail/" + id);
  };

  useEffect(() => {
    getData();
    if (state?.currPage) setCurrPage(state.currPage);
  }, [currPage, currSearchData]);

  return (
    <div className="inner">
      <Loading />
      <section className="page_handling_wrap">
        <form className="search_wrap">
          <SelectBox />
          <input
            type="text"
            onChange={(e) => {
              setSerchKeword(e.target.value);
            }}
          />
          <div className="btn_group">
            <button className="btn_default search desktop" onClick={getData}>
              검색
            </button>
          </div>
        </form>
        <Pagination
          currPage={currPage}
          setCurrPage={setCurrPage}
          lastPage={resData?.totalPages || 0}
        />
      </section>

      <section className="board_wrap">
        <header>
          <h2>강의실 조회</h2>
          <div className="btn_group">
            <ReactHTMLTableToExcel
              className="btn_default excel"
              table="table"
              filename={formatExcelTitle("강의실목록")}
              sheet="tableSheet"
              buttonText="엑셀다운로드"
            />
            {permissionCheck(1) && (
              <button
                className="btn_default add"
                onClick={() => {
                  navigate("/academy/classroom/add");
                }}
              >
                강의실 등록
              </button>
            )}
          </div>
        </header>
        <table id="table">
          <colgroup className="mobile">
            <col width={"30%"} />
            <col width={"30%"} />
            <col width={"auto"} />
          </colgroup>
          <colgroup className="desktop">
            <col width={"5%"} />
            <col width={"25%"} />
            <col width={"auto"} />
            <col width={"10%"} />
            <col width={"25%"} />
          </colgroup>
          <thead>
            <tr className="mobile">
              <th>명칭</th>
              <th>{<McBoardContent />}</th>
              <th>위치</th>
            </tr>
            <tr className="desktop">
              {["번호", "명칭", "위치", "정원", "메모"].map((item) => (
                <th className="hide_text">{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {resData && !resData.classroom_list[0] && (
              <tr>
                <td colSpan={5} className="desktop">
                  표시할 데이터가 없습니다.
                </td>
                <td colSpan={3} className="mobile">
                  표시할 데이터가 없습니다.
                </td>
              </tr>
            )}
            {resData?.classroom_list.map((el, idx) => (
              <tr
                key={idx}
                data-id={el.id}
                onClick={() => {
                  goToDetail(el.id);
                }}
              >
                <td className="desktop">
                  {resData.totalElements - currPage * 5 - idx}
                </td>
                <td className="hide_text">{el.room_name}</td>
                {/* 모바일 */}
                <td className="hide_text mobile">
                  {el[selectMcBoardContent.code]}
                </td>
                {/* 모바일 */}
                <td className="hide_text">{el.location}</td>
                <td className="desktop">{el.capacity}</td>
                <td className="desktop hide_text">{el.memo}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      <ModalComponent />
    </div>
  );
}
