import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useInputHandler from "hooks/useInputHandler";
import useRequestAPI from "hooks/useRequestAPI";
import useSelectBox from "hooks/useSelectBox";
import { url } from "variable/url";
import formatPriceKR from "utils/formatPriceKR";

export default function EditSalaryInfo({
  closeModal,
  changeData,
  currentData,
}) {
  const navigate = useNavigate();
  const [SelectBox, selectValue, setSelectValue, setSelectList] = useSelectBox(
    []
  );
  const { requestAPI, alertMsg, setMessage } = useRequestAPI();
  const [dataForm, , attributes] = useInputHandler({
    salary: currentData.data.info.mna_salary,
    bonus: currentData.data.info.mna_bonus,
  });

  const getData = async () => {
    try {
      const res = await axios.get(url.server + "manager/status");
      const data = res.data.data;
      setSelectList(data.manager_status);
      setSelectValue(
        data.manager_status.find((el) => {
          return el.name === currentData.data.info.mna_status;
        })
      );
    } catch (err) {
      setMessage(err);
    }
  };

  // console.log(dataForm);

  const submitConfirm = () => {
    if (selectValue.code === "status_3") {
      setMessage({
        type: "confirm",
        text: "퇴직 처리 시 퇴직자 게시판으로 이동되며 해당 선생님은 재직 상태로 복구가 불가능합니다.",
        next: true,
        confirm: () =>
          setMessage({
            type: "confirm",
            text: "정말 퇴직처리 하시겠습니까?",
            confirm: submitData,
          }),
      });
    } else submitData();
  };

  const submitData = async () => {
    const body = {
      mna_teacher_id: currentData.data.auth.mna_teacher_id,
      mna_manager_id: currentData.data.auth.mna_manager_id,
      code: selectValue.code,
      salary: dataForm.salary,
      bonus: dataForm.bonus,
    };
    const res = await requestAPI("post", "academy/managers/info", body);
    if (!res) return;
    setMessage({
      text: alertMsg.edit,
      confirm: () => {
        if (selectValue.code === "status_3")
          return navigate("/academy/teachers/1");
        changeData(res);
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <aside id="editSalaryInfo" className="modal dim">
      <div className="modal_content_wrap">
        <header>
          <h2>상태 및 급여정보</h2>
          <div className="btn_group">
            <button className="close btn_default" onClick={closeModal}>
              닫기
            </button>
          </div>
        </header>
        <table className="mobile">
          <tr>
            <th>재직상태</th>
          </tr>
          <tr>
            <td>
              <div className="cell_content_wrap">
                <SelectBox />
              </div>
            </td>
          </tr>
          <tr>
            <th>
              급여{" "}
              <span>
                {dataForm.salary ? formatPriceKR(dataForm.salary) : ""}
              </span>
            </th>
          </tr>
          <tr>
            <td>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  {...attributes("salary")}
                  data-format="number"
                  maxLength={11}
                />
              </div>
            </td>
          </tr>

          <tr>
            <th>
              상여금{" "}
              <span>{dataForm.bonus ? formatPriceKR(dataForm.bonus) : ""}</span>
            </th>
          </tr>
          <tr>
            <td>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  {...attributes("bonus")}
                  data-format="number"
                  maxLength={11}
                />
              </div>
            </td>
          </tr>
        </table>
        <table className="desktop">
          <colgroup></colgroup>
          <thead>
            <tr>
              <th>재직상태</th>
              <th className="unit">
                <p>
                  급여
                  <span>
                    {dataForm.salary ? formatPriceKR(dataForm.salary) : ""}
                  </span>
                </p>
              </th>
              <th className="unit">
                <p>
                  상여금
                  <span>
                    {dataForm.bonus ? formatPriceKR(dataForm.bonus) : ""}
                  </span>
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="cell_content_wrap">
                  <SelectBox />
                </div>
              </td>
              <td>
                <div className="cell_content_wrap">
                  <input
                    type="text"
                    className="medium"
                    {...attributes("salary")}
                    data-format="number"
                    maxLength={11}
                  />
                </div>
              </td>
              <td>
                <div className="cell_content_wrap">
                  <input
                    type="text"
                    className="medium"
                    {...attributes("bonus")}
                    data-format="number"
                    maxLength={11}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="btn_group mt_52 desktop">
          <button></button>
          <button className="btn_default half" onClick={submitConfirm}>
            수정하기
          </button>
        </div>

        <div className="btn_group mobile">
          <button className="btn_default" onClick={submitConfirm}>
            수정하기
          </button>
        </div>
      </div>
    </aside>
  );
}
