import { useEffect, useState } from "react";
import useRequestAPI from "hooks/useRequestAPI";
import Pagination from "components/Pagination";

export default function SelectTeacher({ closeModal, changeData, currentData }) {
  const { requestAPI } = useRequestAPI();
  const [currPage, setCurrPage] = useState(0);
  const [resData, setResData] = useState();
  const dayFormat = ["월", "화", "수", "목", "금", "토", "일"];

  const getData = async () => {
    let currData = currentData.data;
    currData.class_room_time = currData.class_room_time.map((el) => {
      let days = el.week.split(",");
      days = days.map((el) => {
        return isNaN(Number(el)) ? el : dayFormat[el];
      });
      el.week = days.join();
      return el;
    });

    if (currentData.mode === "edit") {
      //현재 선택된 선생님을 제외한 모든 선생님에 대한 중복검사 결과 리스트
      const resAll = await requestAPI("post", "academy/class/all-manager", {
        class_room_time: currData.class_room_time.filter(
          (el) => !el.single_check
        ),
        mna_manager_id: currData.mna_manager_id,
        page: currPage,
        size: 3,
        is_create_or_update: false,
      });
      if (!resAll) return;
      // setResData(resAll.data.data.class_time);

      // 현재 선택된 선생님에 대한 중복검사 결과
      const res = await requestAPI("post", "academy/class/manager", {
        class_room_time: currData.class_room_time.filter(
          (el) => !el?.existing_data
        ),
        mna_manager_id: currData.mna_manager_id,
      });
      if (!res) return;

      // 현재 선택된 선생님을 리스트 0번째 인덱스가 되게끔 데이터 취합
      const mergeResData = {
        ...resAll.data.data?.class_time,
        content: [
          { ...res.data.data.class_time, existing_data: true },
          ...resAll.data.data.class_time.content,
        ],
      };
      if (currPage === 0) {
        setResData(mergeResData);
      } else setResData(resAll.data.data.class_time);
    } else {
      const res = await requestAPI("post", "academy/class/all-manager", {
        class_room_time: currData.class_room_time,
        //  mna_manager_id: currData.mna_manager_id,
        is_create_or_update: true,
      });
      if (!res) return;
      setResData(res.data.data.class_time);
    }
  };

  useEffect(() => {
    getData();
  }, [currPage]);

  return (
    <aside id="selectTeacher" className="modal dim select">
      <div className="modal_content_wrap">
        <header className="">
          <h2>선생님 선택</h2>
          <div className="btn_group">
            <button className="close btn_default" onClick={closeModal}>
              닫기
            </button>
          </div>
        </header>
        <Pagination
          currPage={currPage}
          setCurrPage={setCurrPage}
          lastPage={resData?.totalPages || 0}
          changeURL={false}
        />
        <section className="table_scroll_wrap">
          {/* <p className="fs_20 mb_24 red">
            ※현재 선택된 선생님을 제외한 목록입니다.
          </p> */}
          <table className="detail fixed">
            <colgroup className="desktop">
              <col width={"20%"} />
              <col width={"auto"} />
              <col width={"20%"} />
            </colgroup>
            <colgroup className="mobile">
              <col width={"20%"} />
              <col width={"auto"} />
              <col width={"45%"} />
            </colgroup>
            <thead>
              <tr>
                <th>이름</th>
                <th>가능 과목</th>
                <th>선택</th>
              </tr>
            </thead>
            <tbody>
              {resData?.content.map((el, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      {el.mna_teacher_name}
                      <br />
                      {el.existing_data && "(현재 담당 선생님)"}
                    </td>
                    <td className="subjects_available hide_text">
                      {JSON.parse(el.target_list).map((el, idx) => (
                        <dl key={idx}>
                          <dt>{el.subject}</dt>
                          <dd className="level_list">
                            <ul className="desktop">
                              {el.target?.map((el, idx) => (
                                <li
                                  className={"level_item target_" + (idx + 1)}
                                >
                                  {el}
                                </li>
                              ))}
                            </ul>
                            <ul className="mobile">
                              {el.target?.map((el, idx) => (
                                <li
                                  className={"level_item target_" + (idx + 1)}
                                >
                                  {el.slice(0, 1)}
                                </li>
                              ))}
                            </ul>
                          </dd>
                        </dl>
                      ))}
                    </td>
                    <td>
                      {el.is_valid ? (
                        <>
                          <span className="invalid">시간중복</span>
                          {JSON.parse(el.is_valid)
                            .sort((a, b) => {
                              return (
                                dayFormat.indexOf(a.week) -
                                dayFormat.indexOf(b.week)
                              );
                            })
                            .map((el) => {
                              return (
                                <>
                                  <span className="invalid red">{`${el.type}:${el.week}/${el.start}~${el.end}`}</span>
                                  <br />
                                </>
                              );
                            })}
                        </>
                      ) : (
                        <div className="btn_group">
                          <button
                            className="btn_default slim small green3"
                            onClick={() => {
                              changeData({
                                id: el.mna_manager_id,
                                teacer_id: el.mna_teacher_id,
                                name: el.mna_teacher_name,
                              });
                            }}
                          >
                            선택
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      </div>
    </aside>
  );
}
