import { useEffect, useState } from "react";
import arrow from "assets/img/icon/ic_arrow.png";

export default function useTimeInput(unit = 5) {
  const [timeValue, setTimeValue] = useState({
    startHour: "06", // 오전 6시부터 시작
    startMinute: "00",
    endHour: "23",
    endMinute: "55",
    specificHour: "00",
    specificMinute: "00",
  });
  const [isOpen, setIsOpen] = useState({
    start: false,
    end: false,
    specific: false,
  });

  const getTimeValue = {
    start: timeValue.startHour + ":" + timeValue.startMinute,
    end: timeValue.endHour + ":" + timeValue.endMinute,
    specific: timeValue.specificHour + ":" + timeValue.specificMinute,
  };

  const handleItemClick = (e) => {
    const clickedElement = e.target;
    const ancestorWithClass = findAncestorWithClass(
      clickedElement,
      "time_input_wrap"
    );
    const ancestorWithDatePickerWrapper = findAncestorWithClass(
      clickedElement,
      "react-datepicker-wrapper"
    );
    const ancestorWithDatePickerTab = findAncestorWithClass(
      clickedElement,
      "react-datepicker__tab-loop"
    );
    if (ancestorWithClass) {
      setIsOpen({
        ...isOpen,
        [ancestorWithClass.classList[1]]:
          isOpen[ancestorWithClass.classList[1]],
      });
    } else if (!ancestorWithDatePickerWrapper && !ancestorWithDatePickerTab) {
      setIsOpen({
        start: false,
        end: false,
        specific: false,
      });
    }
  };

  const findAncestorWithClass = (element, className) => {
    let currentElement = element;
    while (currentElement) {
      if (currentElement.classList.contains(className)) {
        return currentElement;
      }

      currentElement = currentElement.parentElement;
    }
    return null;
  };

  useEffect(() => {
    document.removeEventListener("click", handleItemClick);
    document.addEventListener("click", handleItemClick);
    return () => {
      document.removeEventListener("click", handleItemClick);
    };
  }, [isOpen]);

  useEffect(() => {
    const startTime = timeValue.startHour + timeValue.startMinute;
    const endtTime = timeValue.endHour + timeValue.endMinute;
    if (startTime > endtTime) {
      setTimeValue({
        ...timeValue,
        startMinute: timeValue.endMinute,
      });
    }
  }, [timeValue]);

  function TimeInput({ type = "", index, className }) {
    const checkDisabledTime = (timeType, value) => {
      switch (type) {
        case "start": {
          if (
            (timeType === "Hour" && value > timeValue.endHour) ||
            (timeType === "Minute" &&
              timeValue.startHour >= timeValue.endHour &&
              value > timeValue.endMinute - 10)
          ) {
            return false;
          }
          break;
        }
        case "end": {
          if (
            (timeType === "Hour" && value < timeValue.startHour) ||
            (timeType === "Minute" &&
              timeValue.endHour <= timeValue.startHour &&
              value - 10 < timeValue.startMinute)
          ) {
            return false;
          }
          break;
        }
        default:
      }
      return true;
    };

    const selectTime = (e) => {
      const timeType = e.target.dataset.type;
      const value = e.target.innerText;
      if (checkDisabledTime(timeType, value)) {
        setTimeValue({ ...timeValue, [type + timeType]: value });
      } else return;
      if (timeType === "Minute") setIsOpen({ ...isOpen, [type]: false });
    };

    return (
      <div className={"time_input_wrap " + type}>
        <p
          className={isOpen[type] && "open"}
          onClick={() => {
            setIsOpen({ ...isOpen, [type]: !isOpen[type] });
          }}
        >
          <input
            type="text"
            className={"time_view " + className}
            value={timeValue[type + "Hour"] + ":" + timeValue[type + "Minute"]}
            readOnly
          />
          <img src={arrow} alt="" />
        </p>
        {isOpen[type] && (
          <div className="time_list_wrap">
            <ul className="hour_list_wrap">
              <li className="time_">
                <strong>시간</strong>
                <ul className="hour_list">
                  {[...new Array(24 - 6)].map((el, idx) => {
                    // 오전 6시부터 시작해야 해서 -6
                    idx = idx + 6; // 오전 6시부터 시작해야 해서 +6
                    const value = idx < 10 ? "0" + idx : "" + idx;
                    return (
                      <li
                        key={idx}
                        className={
                          "time_item " +
                          (timeValue[type + "Hour"] === value
                            ? "select "
                            : "") +
                          (!checkDisabledTime("Hour", value) ? " disabled" : "")
                        }
                        data-type="Hour"
                        onClick={selectTime}
                      >
                        {value}
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>

            <ul className="minute_list_wrap">
              <li>
                <strong>분</strong>
                <ul className="minute_list">
                  {[...new Array(60 / unit)].map((el, idx) => {
                    const value =
                      idx * unit < 10 ? "0" + idx * unit : "" + idx * unit;
                    return (
                      <li
                        key={idx}
                        className={
                          "time_item " +
                          (timeValue[type + "Minute"] === value
                            ? "select "
                            : "") +
                          (!checkDisabledTime("Minute", value)
                            ? " disabled"
                            : "")
                        }
                        data-type="Minute"
                        onClick={selectTime}
                      >
                        {value}
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  }

  return [TimeInput, getTimeValue, timeValue];
}
