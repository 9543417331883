import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Pagination({
  currPage,
  setCurrPage,
  lastPage,
  changeURL = true,
  size = 5,
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [startPage, setStartPage] = useState(0);

  const next = () => {
    if (currPage + 1 === lastPage) return;
    setCurrPage(currPage + 1);
    if ((currPage + 1) % 5 === 0) {
      setStartPage(startPage + 5);
    }
    if (changeURL) {
      const nextPage =
        pathname.split("/").slice(0, -1).join("/") + "/" + (currPage + 2);
      navigate(nextPage);
    }
  };

  const prev = () => {
    if (currPage === 0) return;
    setCurrPage(currPage - 1);
    if ((currPage + 1) % 5 === 0) {
      setStartPage(startPage - 5);
    }
    if (changeURL) {
      const prevPage =
        pathname.split("/").slice(0, -1).join("/") + "/" + currPage;
      navigate(prevPage);
    }
  };

  useEffect(() => {
    setStartPage(Math.ceil((currPage + 1) / 5) - 1);
  }, [currPage]);

  if (lastPage)
    return (
      <div id="pagination">
        <button onClick={prev}>&lt;</button>
        <span className="mobile">
          <strong>{currPage + 1} </strong>/ {lastPage}
        </span>
        {[...new Array(5)].map((el, idx) => {
          const startNum = startPage * size;
          if (startNum + (idx + 1) > lastPage) return false;
          return (
            <button
              key={idx}
              className={"desktop " + (currPage === startNum + idx && "active")}
              onClick={() => {
                setCurrPage(startNum + idx);
                if (changeURL) {
                  const targetPage =
                    pathname.split("/").slice(0, -1).join("/") +
                    "/" +
                    (startNum + idx + 1);
                  navigate(targetPage);
                }
              }}
            >
              {startNum + (idx + 1)}
            </button>
          );
        })}
        <button onClick={next}>&gt;</button>
      </div>
    );
  else {
    return <div style={{ height: "32px" }}></div>;
  }
}
