import { useEffect, useState } from "react";
import useRequestAPI from "./useRequestAPI";

export default function useStudentCheckBox(initialData) {
  const { requestAPI } = useRequestAPI();
  const [listData, setListData] = useState([]);
  const [scheduleData, setScheduleData] = useState({});
  const [submitDataFormat, setSubmitDataFormat] = useState([]);
  const [checkboxState, setCheckboxState] = useState();
  const [targetList, setTargetList] = useState([]);

  const isCheckAll = () => {
    // 목록이 전체체크 되어있는지를 확인
    if (!checkboxState) return false;
    const { all, ...etc } = checkboxState;
    if (Object.keys(etc).length === 0) return false;
    else return Object.values(etc).every((el) => el.state);
  };

  const checkAll = (e) => {
    // 목록을 전체 체크하기
    const copy = {};
    for (const key in checkboxState) {
      if (key === "all") continue;
      const target = listData.find((el) => el.class_id == key);
      copy[key] = {
        name: target.class_name,
        state: e.target.checked,
        checked: e.target.checked ? target.students : [],
      };
    }
    setCheckboxState({ ...copy, all: { state: e.target.checked } });
  };

  const checkClass = (e, classId) => {
    // 선택한 클래스 데이터를 checkboxState에 업데이트
    const target = listData.find((el) => el.class_id == classId);
    setCheckboxState({
      ...checkboxState,
      [classId]: {
        name: checkboxState[classId].name,
        state: e.target.checked,
        checked: e.target.checked ? target.students : [],
      },
    });
  };

  const checkStudent = (e, classId, student) => {
    // 선택한 학생 데이터를 checkboxState에 업데이트
    const copy = { ...checkboxState };
    const target = listData.find((el) => el.class_id == classId);

    if (e.target.checked) {
      copy[classId].checked.push(student);
    } else {
      copy[classId].checked = copy[classId].checked.filter(
        (el) => el.student_id !== student.student_id
      );
    }
    copy[classId].state =
      target.students.length === copy[classId].checked.length;
    setCheckboxState(copy);
  };

  const updateTargetList = () => {
    if (!checkboxState) return;
    let updatedTargetList = [];
    Object.keys(checkboxState).forEach((key) => {
      if (key === "all") return;
      const target = listData.find((el) => {
        // console.log(el.class_id);
        return el.class_id == key;
      });
      if (checkboxState[key].checked.length === target.students.length) {
        updatedTargetList.push({
          class_id: key,
          name: checkboxState[key].name,
          desc: `(${checkboxState[key].checked.length}명)`,
        });
      } else {
        updatedTargetList = [
          ...updatedTargetList,
          ...checkboxState[key].checked.map((el) => {
            return { class: key, ...el, desc: "" };
          }),
        ];
      }
    });
    setTargetList(updatedTargetList);

    const submitDataFormating =
      checkboxState &&
      Object.keys(checkboxState)
        .filter((el) => el !== "all")
        .map((el) => {
          return {
            class_id: el,
            class_times: scheduleData[el],
            class_name: checkboxState[el].name,
            student_list: checkboxState[el].checked.map((el2) => {
              return { ...el2, class_id: el };
            }),
          };
        })
        .filter((el) => el.student_list[0]);
    setSubmitDataFormat(submitDataFormating);

    let copy = { ...scheduleData };
    const selectClassId = submitDataFormating.map((el) => el.class_id);
    const deleteClassId = Object.keys(scheduleData).filter(
      (el) => !selectClassId.includes(el)
    );
    deleteClassId.forEach((el) => {
      delete copy[el];
    });
    setScheduleData(copy);
  };

  const checkSchedule = (e, classId, value, isAll) => {
    let copy = { ...scheduleData };
    if (isAll) {
      if (e.target.checked) {
        value = value.map((el) => el.week + " " + el.start_time.slice(0, -3));
        copy[classId] = value;
      } else {
        copy[classId] = [];
      }
    } else {
      if (e.target.checked) {
        if (copy[classId]) copy[classId].push(value);
        else copy[classId] = [value];
      } else copy[classId] = copy[classId].filter((el) => el !== value);
    }
    setScheduleData(copy);

    setSubmitDataFormat(
      submitDataFormat.map((el) => {
        if (el.class_id === classId) el.class_times = copy[classId];
        return el;
      })
    );
  };

  const deleteTarget = (target) => {
    let copy = { ...checkboxState };
    if (target.class) {
      copy[target.class].checked = copy[target.class].checked.filter(
        (el) => el.student_id !== target.student_id
      );
      copy[target.class].state = false;
    } else {
      copy[target.class_id].checked = [];
      copy[target.class_id].state = false;
    }
    setCheckboxState(copy);
  };

  const getData = async () => {
    const res = await requestAPI("post", "academy/class/basic-info", {});
    if (!res) return;
    let data = res.data.data.class_info;
    data = data.map((el) => {
      return {
        ...el,
        students: JSON.parse(el.students).filter((el) => el),
        class_times: JSON.parse(el.class_times).sort((a, b) => {
          const days = ["월", "화", "수", "목", "금", "토", "일"];
          return days.indexOf(a.week) - days.indexOf(b.week);
        }),
      };
    });
    if (initialData) {
      data = data.filter((el) => {
        return el.class_id == initialData.class_id;
      });
    }
    setListData(data);

    const checkboxType = { all: { state: false } };
    if (initialData) {
      const checkedStudents = initialData.student_list.filter((el) => {
        return !el.is_deleted;
      });
      checkboxType[initialData.class_id] = {
        name: initialData.class_name,
        state: data[0].students.length === checkedStudents.length,
        checked: checkedStudents,
      };
      setScheduleData({ [initialData.class_id]: initialData.class_datetime });
    } else {
      data.forEach((el) => {
        if (!el.students[0]) return;
        checkboxType[el.class_id] = {
          name: el.class_name,
          state: false,
          checked: [],
        };
      });
    }
    setCheckboxState(checkboxType);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    updateTargetList();
  }, [checkboxState]);

  function StudentList() {
    return (
      <ul className="input_select_item ul_1depth">
        <li>
          <label htmlFor="all">
            <input
              id="all"
              type="checkbox"
              checked={isCheckAll()}
              onChange={checkAll}
            />
            전체
          </label>
          <ul className="ul_2depth">
            {listData.map((el) => {
              if (el.students[0])
                return (
                  <li>
                    <label htmlFor={el.class_id}>
                      <input
                        id={el.class_id}
                        type="checkbox"
                        checked={
                          checkboxState && checkboxState[el.class_id].state
                        }
                        onChange={(e) => {
                          checkClass(e, el.class_id);
                        }}
                      />
                      {el.class_name}
                    </label>
                    <ul className="ul_3depth">
                      {el.students.map((el2) => {
                        return (
                          <li>
                            <label htmlFor={el2.name + el.class_id}>
                              <input
                                id={el2.name + el.class_id}
                                type="checkbox"
                                checked={
                                  checkboxState &&
                                  checkboxState[el.class_id].checked.find(
                                    (el3) => {
                                      return el3.student_id === el2.student_id;
                                    }
                                  )
                                }
                                onChange={(e) => {
                                  checkStudent(e, el.class_id, el2);
                                }}
                              />
                              {el2?.name}
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              else return false;
            })}
          </ul>
        </li>
      </ul>
    );
  }

  function SelectList() {
    return (
      <div className="input_select_item">
        {targetList.map((el) => (
          <p>
            {el.name + el.desc}{" "}
            <button
              onClick={() => {
                deleteTarget(el);
              }}
            >
              &times;
            </button>
          </p>
        ))}
      </div>
    );
  }

  function ScheduleList() {
    return (
      <ul className="input_select_item ul_1depth">
        {submitDataFormat[0] &&
          submitDataFormat.map((el) => {
            const classTimes = listData.find(
              (target) => target.class_id == el.class_id
            ).class_times;
            return (
              <li>
                <label htmlFor={"schedule_for_" + el.class_id}>
                  <input
                    id={"schedule_for_" + el.class_id}
                    type="checkbox"
                    checked={
                      scheduleData[el.class_id] &&
                      scheduleData[el.class_id].length === classTimes.length
                    }
                    onChange={(e) => {
                      checkSchedule(e, el.class_id, classTimes, true);
                    }}
                  />
                  {checkboxState[el.class_id].name}
                </label>
                <ul className="ul_2depth">
                  {classTimes.map((el2, idx) => {
                    const value = el2.week + " " + el2.start_time.slice(0, -3);
                    return (
                      <li>
                        <label
                          htmlFor={
                            "schedule_for_" +
                            checkboxState[el.class_id].name +
                            idx
                          }
                        >
                          <input
                            id={
                              "schedule_for_" +
                              checkboxState[el.class_id].name +
                              idx
                            }
                            type="checkbox"
                            checked={
                              scheduleData[el.class_id] &&
                              scheduleData[el.class_id].includes(value)
                            }
                            onChange={(e) => {
                              checkSchedule(e, el.class_id, value);
                            }}
                          />
                          {value}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
      </ul>
    );
  }

  return {
    listData,
    StudentList,
    SelectList,
    ScheduleList,
    submitDataFormat,
  };
}
