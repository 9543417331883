import { useEffect, useState } from "react";
import useRequestAPI from "hooks/useRequestAPI";

export default function useCategoryList( // 과목 리스트 or 수업대상 리스트를 보여주는 컴포넌트
  type,
  showOnlySelected,
  readOnly = false
) {
  const { requestAPI } = useRequestAPI();
  const [selected, setSelected] = useState([]);
  const [list, setList] = useState({
    field: [],
    target: [],
  });

  const getData = async () => {
    const apiName = type === "field" ? "academy/field" : "teacher/target";
    const res = await requestAPI("post", apiName, {});
    if (!res) return;
    setList({ ...list, [type]: res.data.data });
  };

  useEffect(() => {
    getData();
  }, []);

  const CategoryList = ({ className }) => {
    const key = type === "field" ? "fd_" : "";
    const select = (e) => {
      if (readOnly) return;

      if (type === "field") {
        if (selected.includes(e.target.dataset.code)) setSelected([]);
        else setSelected([e.target.dataset.code]);
      } else {
        if (selected.includes(e.target.dataset.code)) {
          setSelected(
            [...selected].filter((el) => el != e.target.dataset.code)
          );
        } else {
          setSelected([...selected, e.target.dataset.code]);
        }
      }
    };

    if (list[type][0])
      return (
        <ul className={"category_list_wrap " + className}>
          {list[type].map((el, idx) => {
            const isSelected = selected.includes(el[key + "code"]);
            if (showOnlySelected && !isSelected) return false;
            return (
              <li
                key={idx}
                className={`${type}_${idx + 1} ` + (isSelected && " active")}
                data-code={el[key + "code"]}
                onClick={select}
              >
                {el[key + "name"]}
              </li>
            );
          })}
        </ul>
      );
  };

  return [CategoryList, selected, setSelected];
}
