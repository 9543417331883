import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useRequestAPI from "hooks/useRequestAPI";
import useCategoryList from "hooks/useCategoryList";
import formatDate from "utils/formatDate";
import permissionCheck from "utils/permissionCheck";
import formatCallNumber from "utils/formatCallNumber";
import ic_system from "assets/img/icon/ic_system.png";
import ic_class from "assets/img/icon/ic_class.png";
import ic_teacher from "assets/img/icon/ic_teacher.png";
import ic_student from "assets/img/icon/ic_student.png";
import ic_room from "assets/img/icon/ic_room.png";
import academy_profile_no_img from "assets/img/academy_profile_no_img.png";
import guide_academy from "assets/file/guide_academy.pdf";

export default function AcademyInfo() {
  const navigate = useNavigate();
  const { requestAPI, Loading } = useRequestAPI();
  const [CategoryList, , setSelected] = useCategoryList("field", true, true);
  const [resData, setResData] = useState();

  const getData = async () => {
    const body = {
      manager_id: localStorage.getItem("manager_id"),
      academy_id: localStorage.getItem("academy_id"),
    };
    const res = await requestAPI("post", "academy/information", body);
    if (!res) return;
    const data = res.data.data;
    setSelected(data.fields);
    setResData(data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="inner info_wrap">
      <Loading />
      <section className="info_section board_wrap">
        <header>
          <h2 className="flex">
            기본 정보
            {permissionCheck(2) && (
              <button
                onClick={() => {
                  navigate("/academy/info/edit", { state: { resData } });
                }}
              >
                <img src={ic_system} alt="기본 정보 수정 버튼" />
              </button>
            )}
          </h2>
          <div className="btn_group flex">
            <button className="btn_default guide medium">
              <a
                href={guide_academy}
                download={"에듀빌리_학원정보관리_사용가이드"}
              >
                사용가이드
              </a>
            </button>
          </div>
        </header>

        <div className="basic_info">
          <div className="profile_container">
            <div className="profile_wrap">
              <img
                src={resData?.profile_path?.Location || academy_profile_no_img}
                alt=""
              />
            </div>
          </div>

          <div className="academy_basic_info">
            <dl>
              <dt>학원명</dt>
              <dd>{resData?.academy_name}</dd>
            </dl>
            <dl>
              <dt>사업자등록번호</dt>
              <dd>{resData?.license_no}</dd>
            </dl>
            <dl>
              <dt>대표자</dt>
              <dd>{resData?.ceo_name}</dd>
            </dl>
            <dl>
              <dt>개업일자</dt>
              <dd>{formatDate(resData?.opening_date, ".")}</dd>
            </dl>
            <dl>
              <dt>주소</dt>
              <dd>
                {resData
                  ? `[${resData?.postal_code}] ${resData?.address} ${resData?.address_detail}`
                  : ""}
                {}
              </dd>
            </dl>
            <dl>
              <dt>전화번호</dt>
              <dd>{formatCallNumber(resData?.tel)}</dd>
            </dl>
            <dl>
              <dt>팩스</dt>
              <dd>{formatCallNumber(resData?.fax)}</dd>
            </dl>
            <dl>
              <dt>분야</dt>
              <dd>
                <CategoryList className="slim jc_fs gap_4" />
              </dd>
            </dl>
          </div>
        </div>
      </section>

      {/* Pictures */}
      <section className="info_section board_wrap academy_picture_wrap">
        <header>
          <h2>학원 사진</h2>
        </header>

        <div className="img_grid_list">
          {resData?.academy_picture &&
            resData?.academy_picture
              .sort((a, b) => a.index - b.index)
              .map((el, idx) => (
                <div key={idx} className="img_grid_item">
                  <div className="img_wrap">
                    <img src={el.Location} alt="학원 사진" />
                  </div>
                </div>
              ))}
        </div>
      </section>

      {/* Configuration information */}
      <section className="info_section config_wrap board_wrap">
        <header>
          <h2>구성 정보</h2>
        </header>

        <div className="config_list">
          <div className="config_item">
            <img src={ic_teacher} alt="" />
            <div className="detail">
              <span>선생님</span>
              <strong>{resData?.teacher_cnt}명</strong>
            </div>
          </div>
          <div className="config_item">
            <img src={ic_student} alt="" />
            <div className="detail">
              <span>학생</span>
              <strong>{resData?.student_cnt}명</strong>
            </div>
          </div>
          <div className="config_item">
            <img src={ic_room} alt="" />
            <div className="detail">
              <span>강의실</span>
              <strong>{resData?.room_cnt}개</strong>
            </div>
          </div>
          <div className="config_item class">
            <img src={ic_class} alt="" />
            <div className="detail">
              <span>수업</span>
              <strong>{resData?.class_cnt}개</strong>
            </div>
            <div className="subject">
              {resData?.classes?.map((el) => {
                if (el)
                  return (
                    <span
                      title={el.name}
                      onClick={() => {
                        navigate("/academy/schedule/detail/" + el.id);
                      }}
                    >
                      {el.name}
                    </span>
                  );
                else return false;
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
