import { Link } from "react-router-dom";
import guide_join from "assets/file/guide_join.pdf";

export default function JoinStep_2() {
  return (
    <main id="joinStep2" className="container align_center nav_none">
      <section>
        <div className="btn_group">
          <button></button>
          <button className="btn_default guide medium">
            <a href={guide_join} download={"에듀빌리_회원가입_사용가이드"}>
              사용가이드
            </a>
          </button>
        </div>
        <header>
          <h1>에듀빌리의 가입을 환영합니다.</h1>
          <p>
            교육 시설을 등록/생성하고 에듀빌리를 <br className="mobile" />
            시작해 보세요.
          </p>
        </header>
        <div className="btn_group join_btn">
          <button className="btn_default half">
            <Link to="/auth/join/sign-up">생성</Link>
          </button>
          <button className="btn_default half yellow">
            <Link to="/join/invitee">등록</Link>
          </button>
        </div>
        <dl>
          <dd>생성 : 학원을 생성합니다. (사업주일 경우)</dd>
          <dd>등록 : 소속된 학원을 인증합니다. (선생님일 경우)</dd>
        </dl>
      </section>
    </main>
  );
}
