import useInputHandler from "hooks/useInputHandler";
import useRequestAPI from "hooks/useRequestAPI";

export default function AddTeacher({ closeModal }) {
  const [dataForm, , attributes] = useInputHandler({
    teacher_name: "",
    teacher_hp: "",
  });
  const { requestAPI, alertMsg, setMessage } = useRequestAPI();

  const submitData = async () => {
    const res = await requestAPI("post", "academy/invitation", {
      ...dataForm,
    });
    if (!res) return;
    setMessage({ text: alertMsg.invite, confirm: closeModal });
  };

  return (
    <aside id="addTeacher" className="modal dim">
      <div className="modal_content_wrap">
        <header>
          <h2>선생님 추가</h2>
          <div className="btn_group">
            <button className="close btn_default" onClick={closeModal}>
              닫기
            </button>
          </div>
        </header>
        <table className="mobile">
          <tr>
            <th>이름</th>
          </tr>
          <tr>
            <td>
              <div className="cell_content_wrap">
                <input type="text" {...attributes("teacher_name")} />
              </div>
            </td>
          </tr>

          <tr>
            <th>휴대폰번호</th>
          </tr>

          <tr>
            <td>
              <div className="cell_content_wrap">
                <input
                  type="text"
                  {...attributes("teacher_hp")}
                  data-format="number"
                />
              </div>
            </td>
          </tr>
        </table>
        <table className="desktop">
          <colgroup></colgroup>
          <thead>
            <tr>
              <th>이름</th>
              <th>휴대폰번호</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="cell_content_wrap">
                  <input
                    type="text"
                    className="half"
                    {...attributes("teacher_name")}
                  />
                </div>
              </td>
              <td>
                <div className="cell_content_wrap">
                  <input
                    type="text"
                    className="medium"
                    {...attributes("teacher_hp")}
                    data-format="number"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="center mt_24">
          * 초대 코드 발송 시, 24시간 동안 해당 코드가 유효합니다.{" "}
          <br className="mobile" /> 해당 코드가 유효 시, 재초대가 불가능합니다.
        </p>
        <div className="btn_group mt_32 desktop">
          <button></button>
          <button className="btn_default half" onClick={submitData}>
            초대코드 전송
          </button>
        </div>

        <div className="btn_group mt_32 mobile">
          <button className="btn_default" onClick={submitData}>
            초대코드 전송
          </button>
        </div>
        {/* )} */}
      </div>
    </aside>
  );
}
