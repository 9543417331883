const msg = {
  add: "등록되었습니다.",
  edit: "수정되었습니다.",
  change: "변경되었습니다.",
  delete: "삭제되었습니다.",
  invite: "초대되었습니다.",
  apply: "반영되었습니다.",
  end: "종료되었습니다.",
  class_time_delete: "해당 수업시간이 삭제되었습니다.",
  class_time_delete_last:
    "마지막 남은 수업시간이 삭제되어 수업 운영이 종료되었습니다.",
  find_pw_email: " 번호로 임시 비밀번호가 발송되었습니다.",
  logout: "로그아웃 되었습니다.",
  payment_request: "수업료 수납 요청 알림을 발송하였습니다.",
  null_content: "내용을 입력해주세요.",
  null_contetn_file: "내용을 입력하거나 파일을 첨부해주세요.",
  all_fields: "입력 항목을 모두 입력해 주세요.",
  create: "사업장 등록이 완료되었습니다.",
  bad_req: "잘못된 접근입니다.",
  error_file: "파일 업로드 중 오류가 발생하였습니다.",
  change_pw:
    "비밀번호 변경이 완료되었습니다. 변경된 비밀번호로 다시 로그인 해주세요.",
  error:
    "요청하신 작업을 수행하지 못했습니다.\n일시적인 장애이거나 네트워크 문제일 수 있습니다.\n잠시 후에 다시 이용해주세요.",
};

export default msg;
